import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { loginAtom } from '../../../lib/auth';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { currentPracticeAtom } from '../../../lib/practice';

import AddIcon from '@mui/icons-material/Add';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import WarningIcon from '@mui/icons-material/Warning';

import {
  Box,
  Button as Btn,
  Chip,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  DatePicker,
  IconButton,
  Select,
  Switcher,
  TimePickerNew,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { Location } from '../../../models/Location';
import { User } from '../../../models/User';
import { WeekLayout } from '../../../models/WeekLayout';

const { DateTime } = require("luxon");

type Props = {
  onSave: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  weekLayout: WeekLayout;
  setWeekLayout: (c:WeekLayout) => void;
  isLoading?: boolean;
};

export const CoachWeekLayoutEdit: FunctionComponent<Props> = ({open, setOpen, weekLayout, setWeekLayout, onSave, isLoading}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [indefinite, setIndefinite] = useState<boolean>(!weekLayout.last_day);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [slotEditOpen, setSlotEditOpen] = useState<boolean>(false);
  const [activeSlot, setActiveSlot] = useState<{
    index?: number;
      start_hour?: number;
      start_minute?: number;
      end_hour?: number;
      end_minute?: number;
      location?: {
        value?: number;
        label?: string;
      };
      day?: string;
  }>({});

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: ['id', 'name', 'colour'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });





  // useEffect(() => {
  //   console.log(activeSlot);
  // }, [activeSlot]);

  return (<>
    <Base
      name="week_layout_edit"
      title={`${t("general.titles.edit", "Wijzig")} ${t("coach.models.default_week_layouts.period")}`}
      fullWidth
      fullScreen
      maxWidth="lg"
      dc_sx={{
        backgroundColor: '#f8f8f8'
      }}
      content={<>

        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          <Grid item xs={12} md={3}>
            <Select
              fullWidth
              caption={t("coach.models.default_weeks.coach", "Coach")}
              starred
              displayEmpty
              backend
              id="coach"
              value={weekLayout.user_id || ''}
              setValue={(v) => {
                setWeekLayout({
                  ...weekLayout,
                  user_id: !!v ? parseInt(v.toString(), 10) : undefined
                });
              }}
            >
              <MenuItem value="">--- {t("coach.models.default_weeks.placeholders.coach", "Vul hier de coach in")} ---</MenuItem>
              {(coaches || []).map(coach => <MenuItem value={coach.id}>{coach.full_name}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item xs={4} md={3}>
            <Typography sx={{
              fontSize: '0.8rem'
            }}>{t("admin.models.default_week_layouts.first_day", "Eerste dag")} *</Typography>
            <DatePicker
              openTo="day"
              label=""
              format={"dd/LL/y"}
              // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
              views={["year", "month", "day"]}
              shouldDisableDate={(d) => {
                return d.weekday !== 1;
              }}
              setValue={(e) => {
                setWeekLayout({
                  ...weekLayout,
                  first_day: e
                });
              }}
              value={weekLayout.first_day || ''}
              noIcon
              id={`first_day`}
              sx={{
                box: {
                  width: 'auto',
                  display: 'inline-block',
                  marginRight: 0.5
                },
                field_box: {
                  "svg": {
                    display: 'none'
                  }
                },
                field: {
                  width: 'auto',
                  marginTop: 0,
                  "input": {
                    textAlign: 'center'
                  },
                  backgroundColor: 'white',
                  marginBottom: 1,
                  "&.error": {
                    border: "0.5px solid rgba(255, 0, 0, 0.5)",
                    marginBottom: "0px"
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <Switcher
              id="lastday"
              caption={t("coach.models.default_week_layouts.indefinite", "Heeft geen eindtijd")}
              value={!!indefinite}
              onChange={(c) => {
                setIndefinite(c);
              }}
            />
          </Grid>
          {!indefinite && <Grid item xs={4} md={3}>
            <Typography sx={{
              fontSize: '0.8rem'
            }}>{t("admin.models.default_week_layouts.last_day", "Laatste dag")} *</Typography>
            <DatePicker
              openTo="day"
              label=""
              format={"dd/LL/y"}
              // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
              views={["year", "month", "day"]}
              shouldDisableDate={(d) => {
                return d.weekday !== 7;
              }}
              setValue={(e) => {
                setWeekLayout({
                  ...weekLayout,
                  last_day: e
                });
              }}
              value={weekLayout.last_day || ''}
              noIcon
              id={`last_day`}
              sx={{
                box: {
                  width: 'auto',
                  display: 'inline-block',
                  marginRight: 0.5
                },
                field_box: {
                  "svg": {
                    display: 'none'
                  }
                },
                field: {
                  width: 'auto',
                  marginTop: 0,
                  "input": {
                    textAlign: 'center'
                  },
                  backgroundColor: 'white',
                  marginBottom: 1,
                  "&.error": {
                    border: "0.5px solid rgba(255, 0, 0, 0.5)",
                    marginBottom: "0px"
                  }
                }
              }}
            />
          </Grid>}
          <Grid item xs={12}>
            <Box>
              <Box sx={{
                marginBottom: 3,
                '& .Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }
              }}>
                <Tabs
                  value={currentTab}
                  onChange={(e, v) => {setCurrentTab(v);}}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Filters"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab icon={<LooksOneIcon />} label={`${t("coach.submenu.personal_calendar_settings.week")}1`} />
                  {(weekLayout.num_weeks || 1) >= 2 && <Tab icon={<LooksTwoIcon />} label={`${t("coach.submenu.personal_calendar_settings.week")}2`} />}
                  {(weekLayout.num_weeks || 1) >= 3 && <Tab icon={<Looks3Icon />} label={`${t("coach.submenu.personal_calendar_settings.week")}3`} />}
                  {(weekLayout.num_weeks || 1) >= 4 && <Tab icon={<Looks4Icon />} label={`${t("coach.submenu.personal_calendar_settings.week")}4`} />}
                  {(weekLayout.num_weeks || 1) >= 5 && <Tab icon={<Looks5Icon />} label={`${t("coach.submenu.personal_calendar_settings.week")}5`} />}
                  {(weekLayout.num_weeks || 1) < 5 && <Tab onClick={(e) => {
                    e.preventDefault();
                    setWeekLayout({
                      ...weekLayout,
                      num_weeks: ((weekLayout.num_weeks || 1) + 1),
                      default_weeks: [
                        ...(weekLayout.default_weeks || []),
                        {
                          week: ((weekLayout.num_weeks || 1) + 1)
                        }
                      ]
                    });
                    setCurrentTab((weekLayout.num_weeks || 1));
                  }} icon={<AddIcon />} label={t("client.general.actions.add")} />}
                </Tabs>
                <Divider sx={{borderColor: theme.palette.primary.main}} />
              </Box>


              <Grid container spacing={1}>
                {(weekLayout.num_weeks || 1) > 1 && <Grid item xs={12}>
                  <Button
                    label={t("coach.submenu.personal_calendar_settings.remove_week")}
                    id="remove"
                    secondary
                    text
                    size="small"
                    onClick={() => {
                      let newDefaultWeeks: { id?: number | undefined; week?: number | undefined; mo?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; tu?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; we?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; th?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; fr?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; sa?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; su?: { index?: number | undefined; start_hour?: number | undefined; start_minute?: number | undefined; end_hour?: number | undefined; end_minute?: number | undefined; location?: { value?: number | undefined; label?: string | undefined; } | undefined; }[] | undefined; }[] = [];
                      (weekLayout.default_weeks || []).map((x, i) => {
                        if (i !== currentTab) newDefaultWeeks.push(x);
                      });

                      newDefaultWeeks = newDefaultWeeks.map((ndw, i) => {
                        ndw.week = i + 1;
                        return ndw;
                      });

                      setWeekLayout({
                        ...weekLayout,
                        default_weeks: newDefaultWeeks,
                        num_weeks: (weekLayout.num_weeks || 1) - 1
                      });
                    }}
                    sx={{marginTop: 0}}
                  />
                </Grid>}
                <Grid item xs={12} sx={{alignContent: 'baseline'}}>
                  {['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].map(day => <Box sx={{
                    width: '14.2%',
                    border: '1px solid transparent',
                    borderColor: theme.palette.primary.main,
                    display: 'inline-block',
                    padding: 0.5,
                    height: '100%',
                    float: 'left',
                    textAlign: 'center'
                  }}>
                    <Typography>
                      {t(`coach.submenu.personal_calendar_settings.${day}`)}
                      {/* @ts-ignore */}
                      {(((weekLayout.default_weeks || [])[currentTab] || {})[day] || []).filter(fff => (((weekLayout.default_weeks || [])[currentTab] || {})[day] || []).filter(ggg => fff.index !== ggg.index).filter(ggg => (DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) >= DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) < DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute})) || (DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) > DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) <= DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute}))).length > 0).length > 0 && <RunningWithErrorsIcon sx={{color: theme.palette.warning.main, marginBottom: -0.75, marginLeft: 1}} />}
                    </Typography>
                    <Divider />
                    {/* @ts-ignore */}
                    {(((weekLayout.default_weeks || [])[currentTab] || {})[day] || []).sort((a,b) => a.index > b.index ? 1 : -1).map(slot => <Chip
                      label={[
                        DateTime.now().set({hour: slot.start_hour, minute: slot.start_minute}).toFormat("t"),
                        DateTime.now().set({hour: slot.end_hour, minute: slot.end_minute}).toFormat("t")
                      ].join(" - ")}
                      sx={{
                        margin: 0.5
                      }}
                      color="primary"
                      onClick={() => {
                        setActiveSlot({
                          ...slot,
                          day: day
                        });
                        setSlotEditOpen(true);
                      }}
                      onDelete={() => {
                        let newDefaultWeeks = (weekLayout.default_weeks || []);
                        let activeWeek = newDefaultWeeks[currentTab];
                        let activeDay = null;
                        if ((activeSlot.day || 'mo') === 'mo') activeDay = activeWeek.mo;
                        if ((activeSlot.day || 'mo') === 'tu') activeDay = activeWeek.tu;
                        if ((activeSlot.day || 'mo') === 'we') activeDay = activeWeek.we;
                        if ((activeSlot.day || 'mo') === 'th') activeDay = activeWeek.th;
                        if ((activeSlot.day || 'mo') === 'fr') activeDay = activeWeek.fr;
                        if ((activeSlot.day || 'mo') === 'sa') activeDay = activeWeek.sa;
                        if ((activeSlot.day || 'mo') === 'su') activeDay = activeWeek.su;
                        newDefaultWeeks[currentTab] = {
                          ...activeWeek,
                          [activeSlot.day || 'mo']: [
                            ...(activeDay || []).filter(fff => fff.index !== slot.index)
                          ]
                        }

                        setWeekLayout({
                          ...weekLayout,
                          default_weeks: newDefaultWeeks
                        });
                      }}
                    />)}
                    <Divider />
                    <Box sx={{width: '100%', paddingTop: 0.5}}>
                      <IconButton
                        onClick={() => {
                          setActiveSlot({
                            day: day,
                            start_hour: 9,
                            start_minute: 0,
                            end_hour: 17,
                            end_minute: 0
                          });
                          setSlotEditOpen(true);
                        }}
                        sx={{color: theme.palette.primary.main}}
                      ><AddIcon /></IconButton>
                    </Box>
                  </Box>)}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onSave}
          disabled={!weekLayout.user_id || (weekLayout.num_weeks || 1) < 1 || !weekLayout.first_day || [0, 1, 2, 3, 4].filter(
            iii => ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].filter(
              // @ts-ignore
              ddd => (((weekLayout.default_weeks || [])[iii] || {})[ddd] || []).filter(
                // @ts-ignore
                fff => (((weekLayout.default_weeks || [])[iii] || {})[ddd] || []).filter(
                  // @ts-ignore
                  ggg => fff.index !== ggg.index
                ).filter(
                  // @ts-ignore
                  ggg => (
                    DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) >= DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && 
                    DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) < DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute})
                  ) || (
                    DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) > DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && 
                    DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) <= DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute})
                  )
                ).length > 0
              ).length > 0
            ).length > 0
          ).length > 0}
          color="primary"
        >{t("client.general.buttons.submit")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />

    <Base
      name="setSlot"
      hidden={!slotEditOpen}
      title={t("coach.submenu.personal_calendar_settings.add_block")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("coach.models.default_weeks.location", "Locatie")}
            displayEmpty
            id="location"
            value={activeSlot?.location?.value || ''}
            setValue={(v) => {
              setActiveSlot({
                ...activeSlot,
                location: {
                  value: !!v ? parseInt(v.toString(), 10) : undefined,
                  label: ((locations || []).filter(fff => fff.id === (!!v ? parseInt(v.toString(), 10) : undefined))[0] || {}).name
                }
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.default_weeks.placeholders.location", "Vul hier de locatie in")} ---</MenuItem>
            {(locations || []).map(location => <MenuItem value={location.id}>{location.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.default_weeks.start_hour", "Start-uur")}</Typography>
          <TimePickerNew
            id="time_start"
            value={DateTime.now().set({hour: activeSlot.start_hour || 9, minute: activeSlot.start_minute || 0}).toFormat("t")}
            setValue={(e) => {
              let dt = DateTime.fromFormat(e, "t");
              setActiveSlot({
                ...activeSlot,
                start_hour: dt.hour,
                start_minute: dt.minute
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.default_weeks.end_hour", "Eind-uur")}</Typography>
          <TimePickerNew
            id="time_end"
            value={DateTime.now().set({hour: activeSlot.end_hour || 17, minute: activeSlot.end_minute || 0}).toFormat("t")}
            setValue={(e) => {
              let dt = DateTime.fromFormat(e, "t");
              setActiveSlot({
                ...activeSlot,
                end_hour: dt.hour,
                end_minute: dt.minute
              });
            }}
          />
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setSlotEditOpen(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn disabled={
          !activeSlot.location?.value || 
          !activeSlot.start_hour?.toString() || 
          !activeSlot.start_minute?.toString() || 
          !activeSlot.end_hour?.toString() || 
          !activeSlot.end_minute?.toString() ||
          DateTime.now().set({hour: activeSlot.start_hour || 9, minute: activeSlot.start_minute || 0}) > DateTime.now().set({hour: activeSlot.end_hour || 17, minute: activeSlot.end_minute || 0})
        } onClick={(e) => {
          let newDefaultWeeks = (weekLayout.default_weeks || []);
          let activeWeek = newDefaultWeeks[currentTab] || {week: currentTab + 1};
          let activeDay = null;
          if ((activeSlot.day || 'mo') === 'mo') activeDay = activeWeek.mo;
          if ((activeSlot.day || 'mo') === 'tu') activeDay = activeWeek.tu;
          if ((activeSlot.day || 'mo') === 'we') activeDay = activeWeek.we;
          if ((activeSlot.day || 'mo') === 'th') activeDay = activeWeek.th;
          if ((activeSlot.day || 'mo') === 'fr') activeDay = activeWeek.fr;
          if ((activeSlot.day || 'mo') === 'sa') activeDay = activeWeek.sa;
          if ((activeSlot.day || 'mo') === 'su') activeDay = activeWeek.su;
          newDefaultWeeks[currentTab] = {
            ...activeWeek,
            [activeSlot.day || 'mo']: [
              ...(activeDay || []).filter(fff => fff.index !== activeSlot.index),
              {
                ...activeSlot,
                index: (activeSlot.index || (activeDay || []).length)
              }
            ]
          }

          setWeekLayout({
            ...weekLayout,
            default_weeks: newDefaultWeeks
          });

          setSlotEditOpen(false);
          // add slot to weekday
        }}>{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={slotEditOpen}
      setOpen={setSlotEditOpen}
    />
  </>);
}
