import React, { FunctionComponent, useState, useEffect, ElementType } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@tanstack/react-query';

// import { check_subdomain } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';
import { fetch_all_with_count, fetch_one } from "../../lib/v31lib";

import Background from '../../images/banana-blueberries-bowl-916925.jpg';

import AssignmentIcon from '@mui/icons-material/Assignment';
import BackupIcon from '@mui/icons-material/Backup';
import BugReportIcon from '@mui/icons-material/BugReport';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EmailIcon from '@mui/icons-material/Email';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EventIcon from '@mui/icons-material/Event';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ForumIcon from '@mui/icons-material/Forum';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import HotelIcon from '@mui/icons-material/Hotel';
import LanguageIcon from '@mui/icons-material/Language';
import MailLockIcon from '@mui/icons-material/MailLock';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MemoryIcon from '@mui/icons-material/Memory';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import QuizIcon from '@mui/icons-material/Quiz';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SmsIcon from '@mui/icons-material/Sms';
import TranslateIcon from '@mui/icons-material/Translate';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import {
  Badge,
  Box,
  Divider,
  List,
  SwipeableDrawer
} from '@mui/material';

import {
  IconButton
} from '../../components/v2/styled';

import { MenuMenuItem } from '../../components/v2/menu/MenuItem';
import { LanguagePicker } from '../../components/v2/LanguagePicker';
import { VersionButton } from '../../components/v2/VersionButton';

import { Bug } from '../../models/Bug';
import { Practice } from '../../models/Practice';
import { PremiumType } from '../../models/PremiumType';

import HelpersEhealth from '../../actions/helpers/ehealth';

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: (b:boolean) => void;
}

export const SharedSidebar: FunctionComponent<Props> = ({drawerOpen, setDrawerOpen}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const nonmobile = useMediaQuery('(min-width:600px)');

  const matchesmd = useMediaQuery(theme.breakpoints.up('md'));
  let count = 0;

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["bugs_count", login?.id],
    queryFn: () =>
      fetch_all_with_count<Bug>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'bug',
          fields: ['id', 'title', 'created_at', 'content', 'bug_type', 'impact', 'get_item', 'item_file_name', 'practice_id', 'user_id', 'owner_id'],
          order: "id ASC",
          per_page: 20,
          page: 0,
          filter: {
            search: '',
            advanced: {
              owner_id: login?.id,
              ping_owner: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login?.id,
  });
  const {
    data: practice,
    // isLoading: objectsLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    // refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["practice_fetch", login?.practice_id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: ['id', 'custom_theme', 'get_item', 'settings', 'name', 'item_is_square', 'get_medium', 'group_move', 'group_eat', 'modules', 'get_description', 'url_path'],
          sub_fields: {
            modules: ['id', 'name']
          },
          id: login?.practice_id
        },
        login
      ),
    enabled: !!login && login.type === "User::Coach" && !!login?.practice_id,
  });








  useEffect(() => {
    if (!!practice) {
      setCurrentPractice(practice);
    }
  }, [!!practice]);

  let is_admin = login && login.type === "User::Admin";
  let is_coach = login && login.type === "User::Coach";
  let is_client = login && login.type === "User::User";
  let menu:{
    title: string,
    path: string,
    icon: ElementType,
    hide?: boolean,
    check_premium?: PremiumType,
    is_premium?: boolean,
    children?: {
      title: string,
      path: string,
      icon: ElementType,
      hide?: boolean,
      check_premium?: PremiumType,
      is_premium?: boolean
    }[]
  }[] = [];

  if (is_admin) {
    menu = [
      {title: t("admin.menu.home"), icon: HomeIcon, path: "/"},
      {title: t("admin.menu.merge_requests"), icon: CallMergeIcon, path: "/merge_requests"},
      {title: t("admin.menu.content", "Content"), icon: FolderSpecialIcon, path: '', children: [
        {title: t("admin.menu.coach_skills"), icon: ManageAccountsIcon, path: "/coach_skills"},
        {title: t("admin.menu.practice_types"), icon: MemoryIcon, path: "/practice_types"},
        {title: t("admin.menu.statics", "Statics"), icon: EditNoteIcon, path: "/statics"},
        {title: t("admin.menu.translations", "Vertalingen"), icon: LanguageIcon, path: "/translations", hide: true},
      ]},
      {title: t("admin.menu.email_templates"), icon: MailLockIcon, path: "/email_templates"},
      {title: t("admin.menu.imports"), icon: BackupIcon, path: "/imports"},
      {title: t("admin.menu.platform", "Platform"), icon: EngineeringIcon, path: '', children: [
        {title: t("admin.menu.cdn", "CDN"), icon: ContentPasteSearchIcon, path: "/cdn", hide: true},
        {title: t("admin.menu.countries", "Landen"), icon: PublicIcon, path: "/countries"},
        {title: t("admin.menu.modules", "Modules"), icon: ViewModuleIcon, path: "/modules"},
        {title: t("admin.menu.languages", "Talen"), icon: TranslateIcon, path: "/languages"},
      ]},
      {title: t("admin.menu.practices"), icon: BusinessIcon, path: "/practices"},
      {title: t("admin.menu.news_items"), icon: NewspaperIcon, path: "/news_items"},
      {title: t("admin.menu.sms"), icon: SmsIcon, path: "/sms"},
      {title: t("admin.menu.user_page"), icon: GroupsIcon, path: "/user_page"},
    ];
  }
  if (is_coach) {
    let term_workshop = t("coach.menu.workshops", "Workshops");
    let is_nutri_appointment = false;
    let is_nutri_workshop = false;
    let is_nutri_mail = false;
    let is_ehealth = false;
    let is_questionnaire = false;

    if (currentPractice) {
      // Modules
      (currentPractice.modules || []).forEach(e => {
        if (!is_nutri_appointment) is_nutri_appointment = e.id === 1;
        if (!is_nutri_workshop) is_nutri_workshop = e.id === 2;
        if (!is_nutri_mail) is_nutri_mail = e.id === 13;
        if (!is_ehealth) is_ehealth = e.id === 17;
        if (!is_questionnaire) is_questionnaire = e.id === 19;
      });

      // Terms
      if (!!currentPractice?.settings?.workshops_plural) {
        term_workshop = currentPractice.settings.workshops_plural;
      }
    }
    let can_check_ehealth = HelpersEhealth.can_check_ehealth(login);

    menu.push({title: t("coach.menu.home"), icon: HomeIcon, path: "/"});
    menu.push({title: t("coach.menu.nutri_appointment", "Afspraken"), icon: CalendarMonthIcon, path: '', children: [
      {title: t("coach.menu.appointments", "Afspraken"), icon: FormatListBulletedIcon, path: "/agenda", check_premium: 'nutri_appointment', is_premium: is_nutri_appointment},
      {title: term_workshop, icon: CoPresentIcon, path: "/workshops", check_premium: 'nutri_workshop', is_premium: is_nutri_workshop}
    ]});
    menu.push({title: t("coach.menu.clients", "Cliënten"), icon: GroupsIcon, path: '', children: [
      {title: t("coach.menu.users", "Cliënten"), icon: PeopleIcon, path: "/users"},
      {title: t("coach.menu.merge_requsts", "Dubbels"), icon: CallMergeIcon, path: "/merge_requests", hide: !login?.is_super},
      {title: t("coach.menu.logs", "Logs"), icon: AssignmentIcon, path: "/logs", hide: !!login?.is_secretary},
      {title: t("coach.menu.questionnaires", "Vragenlijsten"), icon: QuizIcon, path: "/questionnaires", hide: !!login?.is_secretary, check_premium: 'questionnaire', is_premium: is_questionnaire}
    ]});
    menu.push({title: t("coach.menu.communication"), icon: ForumIcon, path: '', children: [
      {title: t("coach.menu.nutri_mail", "NutriMail"), icon: EmailIcon, path: "/inbox", check_premium: 'nutri_mail', is_premium: is_nutri_mail},
      {title: t("coach.menu.ehealth_mail", "EhealthMail"), icon: ContactMailIcon, path: "/ehealth_inbox", hide: !can_check_ehealth, check_premium: 'ehealth', is_premium: !!is_ehealth},
      {title: t("coach.menu.news_items", "Nieuwsberichten"), icon: NewspaperIcon, path: "/news_items", hide: !login?.is_super}
    ]});
    menu.push({title: t("coach.menu.exports", "Exports"), icon: CloudDownloadIcon, path: "/exports", hide: !!login?.is_secretary});
    menu.push({title: t("coach.menu.settings_parent"), icon: SettingsIcon, path: '', children: [
      {title: t("coach.menu.personal_settings", "Instellingen"), icon: ManageAccountsIcon, path: "/settings"},
      {title: t("coach.menu.settings", "Instellingen"), icon: EngineeringIcon, path: "/general_settings", hide: !login?.is_super}
    ]});
  }
    if (is_client) {
      let term_workshop = t("client.menu.workshops", "Workshops");
      let is_practice_chosen = false;
      let is_nutri_appointment = false;
      let is_nutri_workshop = false;

      if (currentPractice) {
        is_practice_chosen = true;

        // Modules
        (currentPractice.modules || []).forEach(e => {
          if (!is_nutri_appointment) is_nutri_appointment = e.id === 1;
          if (!is_nutri_workshop) is_nutri_workshop = e.id === 2;
        });

        // Terms
        if (!!currentPractice?.settings?.workshops_plural) {
          term_workshop = currentPractice.settings.workshops_plural;
        }
      }

      menu.push({title: t("client.menu.home"), icon: HomeIcon, path: "/"});
      menu.push({title: t("client.menu.diary"), icon: MenuBookIcon, path: "/diary"});
      menu.push({title: t("client.menu.measures"), icon: DesignServicesIcon, path: '', children: [
        {title: t("client.menu.measure_wake"), icon: HotelIcon, path: "/wake"},
        {title: t("client.menu.measure_weight"), icon: MonitorWeightIcon, path: "/weight"}
      ]});
      if (!!login?.practice_id) {
        menu.push({title: t("client.menu.practice"), icon: SavedSearchIcon, path: "/practice"});
      } else {
        menu.push({title: t("client.menu.find"), icon: SearchIcon, path: "/find"});
      }
      menu.push({title: t("client.menu.agenda", "Mijn Agenda"), icon: CalendarMonthIcon, path: '', children: [
        {title: t("client.menu.appointments", "Afspraken"), icon: FormatListBulletedIcon, path: "/appointments"},
        {title: t("client.menu.appointment", "Maak afspraak"), icon: EventIcon, path: "/appointment"},
        {title: term_workshop, icon: CoPresentIcon, path: "/workshops"}
      ]});
      menu.push({title: t("client.menu.intake"), icon: ContactEmergencyIcon, path: "/intake"});
      menu.push({title: t("client.menu.questionnaires"), icon: QuizIcon, path: "/questionnaires"});
      menu.push({title: t("client.menu.nutri_mail", "NutriMail"), icon: EmailIcon, path: "/inbox"});
      menu.push({title: t("client.menu.settings", "Instellingen"), icon: SettingsIcon, path: "/settings"});
    }

  return <>
    <SwipeableDrawer
      onClose={() => {
        setDrawerOpen(false);
      }}
      onOpen={() => {
        setDrawerOpen(true);
      }}
      open={(matchesmd || drawerOpen)}
      variant={matchesmd ? "permanent" : "temporary"}
      anchor='left'
      sx={{
        height: '100%',
        paddingTop: {
          xs: '56px',
          md: '75px'
        },
        zIndex: 1000,
        position: 'fixed',
        "& .MuiDrawer-paper": {
          position: 'relative',
          whiteSpace: 'nowrap',
          width: {
            xs: '90%',
            md: 240
          },
          transition: theme ? theme.transitions.create('width', {
            easing: 'cubic-bezier(0.4, 0, 0.6, 1)',
            duration: 225
          }) : {},
          paddingTop: {
            xs: 0,
            md: 3
          },
          ...(!drawerOpen && !matchesmd ? {
            overflowX: 'hidden',
            transition: theme ? theme.transitions.create('width', {
              easing: 'cubic-bezier(0.4, 0, 0.6, 1)',
              duration: 195
            }) : {},
            width: 0,
          } : {})
        }
      }}
    >
      <Box sx={{
        backgroundPosition: "bottom left",
        backgroundSize: "cover",
        top: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${Background})`,
        position: "absolute"
      }}></Box>
      <Box sx={{
        backgroundColor: "rgba(255,255,255,0.8)",
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%"
      }}></Box>

      <Box sx={{
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
      }}>
        <Box sx={{flexGrow: 1}}>
          <List>
            {menu.map((item, i) => <MenuMenuItem
              key={i}
              path={item.path}
              title={item.title}
              Icon={item.icon}
              children={item.children}
              setDrawerOpen={setDrawerOpen}
              hide={item.hide}
              check_premium={item.check_premium}
              is_premium={item.is_premium}
            />)}
            {/* {menu.map((x, i) => (x.children ? <ListItemButtonWithChildren key={`menu_${i}`} {...this.props} {...x}/> : <ListItemButton key={`menu_${i}`} {...this.props} {...x}/>))} */}
          </List>
          <Divider />
        </Box>
        <Box sx={{position: 'relative'}}>
          <List>
            <VersionButton
              url={!login || (!!login && login.type !== "User::User") ? "http://www.nutrilink.eu/" : "http://www.nutriportal.be/"}
            />
          </List>

          {!!login && <IconButton
            title={t("general.bugs.tooltip", "Rapporteer een bug, of stel een feature voor")}
            onClick={() => {
              navigate("/bugs");
            }}
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 80,
              padding: 1
            }}
          >
            <Badge 
              badgeContent={count}
              color="primary"
            >
              <BugReportIcon color="secondary" />
            </Badge>
          </IconButton>}

          <LanguagePicker sx={{
            position: 'absolute',
            bottom: 10,
            right: 10,
            padding: 1
          }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  </>;
}

