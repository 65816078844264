import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';

import {
  Button,
  Popover
} from '@mui/material';

import { ChromePicker } from 'react-color';

import {
  Typography
} from '../styled';

// var he = require('he');

type StyledColourPickerProps = {
  sx?: object;
  label?: string;
  value?: string;
  onChange: (z:string) => void;
};

export const ColourPicker: FunctionComponent<StyledColourPickerProps> = ({onChange, value, label}) => {
  // const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [actualValue, setActualValue] = useState<{r:number, g:number,b:number,a?:number}>();
  const [rgbValue, setRgbValue] = useState<string>();

  const hex2rgba = (hex:string, alpha = 1) => {
    const [r, g, b] = (hex.match(/\w\w/g) || []).map(x => parseInt(x, 16));
    console.log(hex, r, g, b);
    return `rgba(${r},${g},${b},${alpha})`;
  };

  useEffect(() => {
    // console.log('go!');
    if (!!onChange && !!rgbValue && rgbValue !== value) onChange(rgbValue);
  }, [rgbValue]);

  useEffect(() => {
    // console.log('set AV to', actualValue);
    if (!!actualValue) setRgbValue(`rgba(${actualValue.r}, ${actualValue.g}, ${actualValue.b}, ${actualValue.a || 1})`);
  }, [actualValue]);

  useEffect(() => {
    // console.log('val1', value);
    if ((value || '').indexOf("#") > -1) value = hex2rgba(value || '');
    // console.log('val2', value);
    let r = 0;
    let g = 0;
    let b = 0;
    let a = 1;
    if (!!value) {
      let second_part = value.replace(")", "").split("(")[1];
      if (!!second_part) {
        let values = second_part.split(",").map(v => parseFloat(v.trim()));
        // console.log(values);
        // console.log(actualValue);
        r = values[0];
        g = values[1];
        b = values[2];
        a = values[3] || 1;
        if (
          !actualValue || 
          ((
            !isNaN(actualValue.r) &&
            !isNaN(actualValue.g) &&
            !isNaN(actualValue.b)
          ) &&
          (
            actualValue.r !== r || 
            actualValue.g !== g || 
            actualValue.b !== b || 
            actualValue.a !== a
          ))
        ) setActualValue({r: r, g: g, b: b, a: a});
      }
    }
    // setActualValue();
  }, [value]);

  return (<>
    {!!label && <Typography sx={{fontSize: '0.75rem'}}>{label}</Typography>}
    <Button aria-describedby="color_picker" variant="contained" sx={{backgroundColor: rgbValue}} onClick={(e) => {
      setAnchorEl(e.currentTarget);
    }}>{rgbValue}</Button>
    <Popover
      id="color_picker"
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => {setAnchorEl(null);}}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <ChromePicker
        color={actualValue}
        onChangeComplete={(c, e) => {
          setActualValue(c.rgb);
        }}
      />
    </Popover>
  </>);
}