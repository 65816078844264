import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EuroIcon from '@mui/icons-material/Euro';
import EventIcon from '@mui/icons-material/Event';
import TextsmsIcon from '@mui/icons-material/Textsms';

import {
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Tooltip
} from '@mui/material';

import {
  Dt,
  ImageList,
  Page,
  Typography
} from '../../../components/v2/styled';
import { CardAppointment } from '../../../components/v2/cards/Appointment';
import { ClientCancelAppointment } from '../../../components/v2/dialogs/ClientCancelAppointment';
import { ClientTooLateAppointment } from '../../../components/v2/dialogs/ClientTooLateAppointment';
import { ClientEditAppointment } from '../../../components/v2/dialogs/ClientEditAppointment';
import { SnackAppointmentMade } from '../../../components/v2/snacks/AppointmentMade';

import { Appointment } from '../../../models/Appointment';

const { DateTime } = require("luxon");

type Props = {}

export const ClientAppointmentList: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();

  const [showAppointmentSuccess, setShowAppointmentSuccess] = useState<boolean>(!!state && !!state.show_appointment_success);

  let count = 0;
  let soonCount = 0;
  let futureCount = 0;
  const [page, setPage] = useState<number>(0);
  const [cancelAppointment, setCancelAppointment] = useState<Appointment>();
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [cancelNote, setCancelNote] = useState<string>('');
  const [tooLateOpen, setTooLateOpen] = useState<boolean>(false);
  const [editAppointment, setEditAppointment] = useState<Appointment>();
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const {
    data: appointmentsSoon,
    isLoading: appointmentsSoonLoading,
    isError: appointmentsSoonError,
    isSuccess: appointmentsSoonSuccess,
    refetch: appointmentsSoonRefetch,
  } = useQuery({
    queryKey: ["appointments_soon", login?.id],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow',
            'has_consent', 'can_meeting', 'comment', 'is_notify_sms', 'is_notify_mail',
            'coach', 'location', 'practice', 'payment', 'appointment_type',
            'comment_cancel', 'order|id/paid'
          ],
          sub_fields: {
            'coach': ['id', 'get_medium', 'coach_skills', 'full_name', 'enabled'],
            'location': ['id', 'get_medium', 'name', 'is_online', 'enabled', 'settings', 'street', 'number', 'zip', 'city'],
            'practice': ['id', 'name', 'settings'],
            'payment': ['id', 'paid', 'token'],
            'appointment_type': ['id', 'colour', 'cost', 'duration_nice', 'public_duration_nice', 'name']
          },
          page: 0,
          per_page: 20,
          order: "start_time ASC",
          filter: {
            search: '',
            advanced: {
              user_id: login?.id,
              soon: 1
            }
          }
        },
        login
      ),
      select: (d) => {
        soonCount = d.count;
        return d.result;
      },
    enabled: !!login,
  });

  const {
    data: appointmentsFuture,
    isLoading: appointmentsFutureLoading,
    isError: appointmentsFutureError,
    isSuccess: appointmentsFutureSuccess,
    refetch: appointmentsFutureRefetch,
  } = useQuery({
    queryKey: ["appointments_future", login?.id],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow',
            'has_consent', 'can_meeting', 'comment', 'is_notify_sms', 'is_notify_mail',
            'coach', 'location', 'practice', 'payment', 'appointment_type',
            'comment_cancel', 'order|id/paid'
          ],
          sub_fields: {
            'coach': ['id', 'get_medium', 'coach_skills', 'full_name', 'enabled'],
            'location': ['id', 'get_medium', 'name', 'is_online', 'enabled', 'settings', 'street', 'number', 'zip', 'city'],
            'practice': ['id', 'name', 'settings'],
            'payment': ['id', 'paid', 'token'],
            'appointment_type': ['id', 'colour', 'cost', 'duration_nice', 'public_duration_nice', 'name']
          },
          page: 0,
          per_page: 20,
          order: "start_time ASC",
          filter: {
            search: '',
            advanced: {
              user_id: login?.id,
              future: 1
            }
          }
        },
        login
      ),
      select: (d) => {
        futureCount = d.count;
        return d.result;
      },
    enabled: !!login,
  });

  const {
    data: appointmentsPast,
    isLoading: appointmentsPastLoading,
    isError: appointmentsPastError,
    isSuccess: appointmentsPastSuccess,
    refetch: appointmentsPastRefetch,
  } = useQuery({
    queryKey: ["appointments_past", login?.id, page],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow', 'location|id/get_item/name/is_online/street_line/city_line', 'coach|id/get_item/coach_skills/full_name', 'practice|id/name', 'comment_cancel', 'order|id/paid',
            'appointment_type|id/colour/cost/duration_nice/public_duration_nice/name', 'comment'
          ],
          page: page,
          per_page: 10,
          order: "start_time DESC",
          filter: {
            search: '',
            advanced: {
              user_id: login?.id,
              past: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });







  const mutationDeleteAppointment = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setCancelOpen(false);
      setCancelAppointment(undefined);
      setCancelNote('');
      appointmentsSoonRefetch();
      appointmentsFutureRefetch();
      appointmentsPastRefetch();
    },
  });







  
  const [open, setOpen] = useState<number>();
  
  // useEffect(() => {
  //   if (!!diaryDay?.weight) setCurrentWeight(diaryDay?.weight);
  // }, [diaryDay]);

  // let inputWeightDisabled = false;
  // if (!!diaryDaySuccess && !!diaryDay.weight) inputWeightDisabled = true;

  return <Page
    title={t("client.menu.appointments")}
  >
    <Tooltip title={t("client.appointments.calendar_stream", "Kalenderstream")}>
      <a href={login?.calendar_url} target="_blank" style={{position: 'absolute', top: 15, right: 20}}>
        <IconButton>
          <EventIcon />
        </IconButton>
      </a>
    </Tooltip>

    <Grid container spacing={2}>
      {(appointmentsSoon || []).length < 1 && (appointmentsFuture || []).length < 1 && (appointmentsPast || []).length < 1 && <Grid item xs={12}>
        <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
      </Grid>}
      {!!appointmentsSoonLoading && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.soon")}</Typography>
          <Paper sx={{
            padding: 2
          }}>
            <CircularProgress />
          </Paper>
        </Box>
      </Grid>}
      {!!appointmentsSoonSuccess && appointmentsSoon?.length > 0 && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.soon")} ({soonCount})</Typography>
          <ImageList cardWidth={400}>
            {(appointmentsSoon || []).map(appointment => <ImageListItem key={appointment.id} sx={{
              height: "auto !important"
            }}>
              <CardAppointment
                appointment={appointment}
                extensive
                actions
                onEdit={(app) => {
                  if (DateTime.now() >= DateTime.fromISO(app.start_time).minus({hours: app.practice?.settings?.cancel_late_time || 24})) {
                    setTooLateOpen(true);
                  } else {
                    setEditOpen(true);
                    setEditAppointment(app);
                  }
                }}
                onDelete={(app) => {
                  setCancelAppointment(app);
                  setCancelOpen(true);
                }}
              />
            </ImageListItem>)}
          </ImageList>
        </Box>
      </Grid>}

      {!!appointmentsFutureLoading && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.future")}</Typography>
          <Paper sx={{
            padding: 2
          }}>
            <CircularProgress />
          </Paper>
        </Box>
      </Grid>}
      {!!appointmentsFutureSuccess && appointmentsFuture?.length > 0 && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.future")} ({futureCount})</Typography>
          <ImageList cardWidth={400}>
            {(appointmentsFuture || []).map(appointment => <ImageListItem key={appointment.id} sx={{
              height: "auto !important"
            }}>
              <CardAppointment
                appointment={appointment}
                extensive
                actions
                onEdit={(app) => {
                  if (DateTime.now() >= DateTime.fromISO(app.start_time).minus({hours: app.practice?.settings?.cancel_late_time || 24})) {
                    setTooLateOpen(true);
                  } else {
                    setEditOpen(true);
                    setEditAppointment(app);
                  }
                }}
                onDelete={(app) => {
                  setCancelAppointment(app);
                  setCancelOpen(true);
                }}
              />
            </ImageListItem>)}
          </ImageList>
        </Box>
      </Grid>}

      {!!appointmentsPastLoading && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.past")}</Typography>
          <Paper sx={{
            padding: 2
          }}>
            <CircularProgress />
          </Paper>
        </Box>
      </Grid>}
      {!!appointmentsPastSuccess && appointmentsPast?.length > 0 && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.past")} ({count})</Typography>
          <Paper sx={{
            padding: 2
          }}>
            <List>
              {appointmentsPast.map(appointment => (<>
                <ListItem divider={appointmentsPast.indexOf(appointment) < appointmentsPast.length - 1 && open !== appointment.id} sx={{
                  ...(appointment.is_cancelled ? (appointment.is_noshow ? {
                    backgroundColor: 'rgba(255, 0, 0, 0.65);'
                  } : {
                    backgroundColor: 'rgba(255, 0, 0, 0.2);'
                  }) : {})
                }}>
                  <ListItemAvatar>
                    <Avatar src={(appointment.location_get_item ? appointment.location_get_item : (appointment.coach_get_item ? appointment.coach_get_item : ""))}></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Dt d={appointment.start_time} f="cccc DDD - t" />}
                    secondary={appointment.practice_name}
                  />
                  <ListItemSecondaryAction>

                    {!!appointment.comment_cancel && open !== appointment.id && <Tooltip title={appointment.comment_cancel}>
                      <IconButton sx={{marginLeft: 'auto'}}>
                        <TextsmsIcon />
                      </IconButton>
                    </Tooltip>}

                    <IconButton onClick={(e) => {
                      e.preventDefault();
                      setOpen(open === appointment.id ? undefined : appointment.id);
                    }} sx={{marginLeft: 'auto'}}>
                      {open === appointment.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>

                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={open === appointment.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {appointment.order_id && appointment.order_paid && <ListItem divider={false} sx={{
                      paddingLeft: 8,
                    }}>
                      <ListItemAvatar>
                        <Avatar sx={{backgroundColor: theme.palette.primary.main}}><EuroIcon /></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={t("client.payments.appointment.paid", "Deze afspraak werd reeds betaald")}
                      />
                    </ListItem>}

                    {appointment.order_id && !appointment.order_paid && <ListItem divider={false} sx={{
                      paddingLeft: 8,
                    }}>
                      <ListItemAvatar>
                        <Avatar sx={{backgroundColor: 'red'}}><EuroIcon /></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={t("client.payments.appointment.unpaid", "Deze afspraak werd nog niet betaald")}
                      />
                      {!!appointment.order_token && <ListItemSecondaryAction>
                        <IconButton onClick={(e) => {window.open(`https://nutripay.eu/${appointment.order_token}`, '_blank');}} sx={{marginLeft: 'auto'}}>
                          <EuroIcon />
                        </IconButton>
                      </ListItemSecondaryAction>}
                    </ListItem>}

                    {appointment.is_cancelled && <ListItem divider={false} sx={{
                      paddingLeft: 8,
                      color: theme.palette.secondary.main
                    }}>
                      <ListItemAvatar />
                      <ListItemText
                        primary={appointment.is_cancelled_by_coach ? t("client.appointments.cancelled_coach") : t("client.appointments.cancelled_client")}
                        secondary={[appointment.is_noshow ? t("client.appointments.cancelled_noshow") : null, appointment.is_cancelled_late ? t("client.appointments.cancelled_late") : null].filter(f => !!f).join(" | ")}
                      />
                    </ListItem>}

                    {appointment.is_cancelled && appointment.comment_cancel && <ListItem divider={false} sx={{
                      paddingLeft: 8
                    }}>
                      <ListItemAvatar />
                      <ListItemText
                        sx={{fontStyle: 'italic'}}
                        primary={appointment.comment_cancel}
                      />
                    </ListItem>}

                    <ListItem divider={false} sx={{
                      paddingLeft: 8
                    }}>
                      <ListItemAvatar>
                        <Avatar sx={{backgroundColor: appointment.appointment_type_colour}}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={appointment.appointment_type_name}
                        secondary={`${t("client.appointment.type_duration", "Duurtijd")}: ${appointment.appointment_type_public_duration_nice || appointment.appointment_type_duration_nice} | ${t("client.appointment.type_cost", "Kost")}: ${appointment.appointment_type_cost} €`}
                      />
                    </ListItem>

                    <ListItem divider={false} sx={{
                      paddingLeft: 8
                    }}>
                      <ListItemAvatar>
                        <Avatar src={appointment.location_get_item ? appointment.location_get_item : ''}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={appointment.location_name}
                        secondary={(appointment.location_is_online ? t("client.appointment.online_location", "Online consultatie") : `${appointment.location_street_line}, ${appointment.location_city_line}`)}
                      />
                    </ListItem>

                    <ListItem divider={true} sx={{
                      paddingLeft: 8
                    }}>
                      <ListItemAvatar>
                        <Avatar src={appointment.coach_get_item ? appointment.coach_get_item : ''}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={appointment.coach_full_name}
                        secondary={(appointment.coach_coach_skills || []).map(s => s.name).join(', ')}
                      />
                    </ListItem>

                  </List>
                </Collapse>
              </>))}
            </List>
            <Stack spacing={2} alignItems="center">
              <Pagination count={Math.ceil(count/10)} page={page + 1} onChange={(e, v) => {setPage(v - 1);}} boundaryCount={2} siblingCount={2} />
            </Stack>
          </Paper>
        </Box>
      </Grid>}
    </Grid>

    {!!cancelAppointment && cancelAppointment.practice && <ClientCancelAppointment
      appointment={cancelAppointment}
      practice={cancelAppointment.practice}
      open={cancelOpen}
      setOpen={setCancelOpen}
      note={cancelNote}
      setNote={setCancelNote}
      onDelete={() => {
        mutationDeleteAppointment.mutate({
          formData: {
            object: 'appointment',
            class_action: 'custom_api_cancel_by_client',
            id: cancelAppointment.id,
            note: cancelNote
          }
        });
      }}
    />}

    {!!tooLateOpen && <ClientTooLateAppointment
      open={tooLateOpen}
      setOpen={setTooLateOpen}
    />}

    {!!editOpen && !!editAppointment && <ClientEditAppointment
      open={editOpen}
      setOpen={setEditOpen}
      appointment={editAppointment}
      refreshAppointments={() => {
        appointmentsSoonRefetch();
        appointmentsFutureRefetch();
        appointmentsPastRefetch();
      }}
    />}

    <SnackAppointmentMade open={showAppointmentSuccess} setOpen={setShowAppointmentSuccess} />
  </Page>;
}

