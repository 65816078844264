import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupsIcon from '@mui/icons-material/Groups';

// import {
//   Avatar,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Page,
  Typography
} from '../../../components/v2/styled';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';

import { Questionnaire } from '../../../models/Questionnaire';

import HelpersPractice from '../../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachQuestionnaires: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const is_questionnaire = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'questionnaire'});

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "questionnaires", (login?.practice_id || currentPractice?.id), {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire',
          fields: ['id', 'name', 'questionnaire_results|completed|count', 'questionnaire_results|count', 'extra_data'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });






  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t("coach.models.questionnaires.name", "Naam"), width: 200 },
    { field: 'question_count', headerName: t("coach.models.questionnaires.questions", "# vragen"), width: 150, renderCell: params => <>
      <Typography sx={{lineHeight: '52px', fontSize: '0.9rem'}}>{(params.row.extra_data?.questions || []).filter((fff:any) => !fff.section).length}</Typography>
    </> },
    { field: 'questionnaire_results_completed_count', headerName: t("coach.models.questionnaires.completed", "# beantwoord"), width: 150 },
    { field: 'questionnaire_results_count', headerName: t("coach.models.questionnaires.results", "# resultaten"), width: 150 },
  ];

  return <Page
    title={t("coach.menu.questionnaires", "Vragenlijsten")}
    premiumGatekeeping={!!is_questionnaire}
    premiumMode='questionnaire'
  >
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
    />
    <DataGrid
      action_count={3}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" path={`/questionnaire/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={params.row.questionnaire_results_count > 0} title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: 'questionnaire',
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton>
          <IconButton title="Results" color="default" path={`/questionnaire/${params.row.id}/subs`}>
            <GroupsIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />

    <Fab
      onClick={() => {
        navigate("/questionnaire/new", {});
      }}
    />
  </Page>;
}
