import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  Box,
  Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Page
} from '../../../components/v2/styled';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';

// CONFIG 1/6
import { CoachSkill } from '../../../models/CoachSkill';
import { Country } from '../../../models/Country';
import { Static } from '../../../models/Static';

import HelpersLanguage from '../../../actions/helpers/language';

// const { DateTime } = require("luxon");

type DaModule = {
  id?: number;
  name?: string;
  enabled?: boolean;
}
type Language = {
  id?: number;
  name?: string;
  translated_name?: string;
  iso?: string;
}
type PracticeType = {
  id?: number;
  name?: string;
}

// CONFIG 2/6
type Props = {
  object_type: 'static' | 'coach_skill' | 'practice_type' | 'country' | 'da_module' | 'language';
}

export const AdminContentIndex: FunctionComponent<Props> = ({object_type}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>(['coach_skill', 'practice_type', 'country', 'da_module', 'language'].indexOf(object_type) > -1 ? "name ASC" : "id DESC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  // CONFIG 3/6
  const fields = {
    'coach_skill': ['id', 'name'],
    'country': ['id', 'name'],
    'da_module': ['id', 'name', 'enabled'],
    'language': ['id', 'name'],
    'practice_type': ['id', 'name'],
    'static': ['id', 'static_key', 'full_static_content'],
  };
  // CONFIG 4/6
  const gridDefs = {
    'coach_skill': [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: "Name", width: 350 },
    ],
    'country': [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: "Name", width: 350 },
    ],
    'da_module': [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: "Name", width: 350 },
    ],
    'language': [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: "Name", width: 350 },
    ],
    'practice_type': [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'name', headerName: "Name", width: 350 },
    ],
    'static': [
      { field: 'id', headerName: 'ID', width: 80 },
      { field: 'static_key', headerName: "Key", width: 350 },
      { field: 'tr_state', headerName: "Tr. State", width: 400, renderCell: (params:any) => <>
        {HelpersLanguage.available_locales().map((locale, i) => {
          return (<Box
            id={`locale_tab_${locale}`}
            sx={{
              borderRadius: 8,
              color: theme.palette.primary.contrastText,
              backgroundColor: (!!params.row.full_static_content[HelpersLanguage.mapping_to_id(locale)] ? theme.palette.primary.main : theme.palette.secondary.main),
              height: '32px',
              width: '32px',
              display: 'inline-block',
              lineHeight: '32px',
              textAlign: 'center',
              fontSize: '0.8rem',
              marginRight: 1
            }}
          >
            {locale.toUpperCase()}
          </Box>)
        })}
      </> },
    ],
  };

  // CONFIG 5/6
  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "list", object_type, {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<Static|CoachSkill|PracticeType|Country|DaModule|Language>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: object_type,
          fields: fields[object_type],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              is_admin: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });







  // CONFIG 6/6
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Static|CoachSkill|PracticeType|Country|DaModule|Language>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });








  // @ts-ignore
  const columns: GridColDef[] = gridDefs[object_type];

  return <Page title={`Index (${object_type})`}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
    />
    <DataGrid
      action_count={2}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" path={`/${object_type}/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: object_type,
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />

    <Fab
      onClick={() => {
        navigate(`/${object_type}/new`, {});
      }}
    />
  </Page>;
}
