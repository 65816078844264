import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  ColourPicker,
  DateAndTimePicker,
  Editor,
  ImagePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Location } from '../../../models/Location';
import { Workshop } from '../../../models/Workshop';
import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachWorkshopEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [isLocation, setIsLocation] = useState<boolean>(true);
  const [editAll, setEditAll] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Workshop>({
    subject: '',
    description: '',
    start_time: DateTime.now().toFormat("dd/LL/y t"),
    end_time: DateTime.now().toFormat("dd/LL/y t"),
    background_colour: 'rgb(211, 211, 211)'
  });
  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [itemCleared, setItemCleared] = useState<boolean>(false);
  const [bgItem, setBgItem] = useState<File>();
  const [bgItemUrl, setBgItemUrl] = useState<string>();
  const [bgItemCleared, setBgItemCleared] = useState<boolean>(false);

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});
  
  const is_nutri_workshop = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_workshop'});
  const is_payments = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'payments'});

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: ['id', 'name', 'colour'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["workshop", (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<Workshop>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'workshop',
          id: objectId,
          fields: [
            'id', 'get_item', 'get_medium', 'subject', 'start_time', 'end_time', 'max_subscribers', 'max_backups',
            'coach_id', 'background_colour', 'is_background_colour', 'get_bg_medium', 'teaser', 'description', 'location_id', 'location_name',
            'location_street', 'location_number', 'location_zip', 'location_city', 'location_country', 'extra_data', 'is_frontpage',
            'recurrence_id'
          ]
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        start_time: DateTime.fromISO(d.start_time).toFormat("dd/LL/y t"),
        end_time: DateTime.fromISO(d.end_time).toFormat("dd/LL/y t"),
        background_colour: d.background_colour || 'rgb(211, 211, 211)'
      };
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Workshop>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "workshops", (login?.practice_id || currentPractice?.id)] });
      if (!variables.keepOpen) navigate(`/agenda_override/new`, {state: {time: DateTime.fromFormat(mainObject.start_time, "dd/LL/y t").toFormat("dd/LL/y"), user_id: mainObject?.coach_id}});
      if (!!variables.keepOpen && objectId === 'new') navigate(`/workshop/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const saveObject = (keep_open: boolean) => {
    let formData = new FormData();
    formData.append('object', 'workshop');
    formData.append('handler_id', (login?.id || "").toString());
    formData.append('fields[]', 'id');
    if (!!objectId && objectId !== 'new') formData.append('ob[id]', objectId.toString());
    if (!!objectId && objectId !== 'new') formData.append('id', objectId.toString());
    // mainObject
    formData.append('ob[subject]', mainObject?.subject || '');
    formData.append('ob[start_time]', mainObject?.start_time || '');
    formData.append('ob[end_time]', mainObject?.end_time || '');
    formData.append('ob[practice_id]', currentPractice?.id?.toString() || login?.practice_id?.toString() || '');
    formData.append('ob[coach_id]', mainObject?.coach_id?.toString() || '');
    if (!!item) formData.append(`ob[item]`, item);
    if (!!itemUrl) formData.append(`ob[set_item_url]`, itemUrl);
    if (!!itemCleared) formData.append(`ob[clear_item]`, '1');
    formData.append('ob[is_background_colour]', !!mainObject?.is_background_colour ? '1' : '0');
    if (!!bgItem) formData.append(`ob[background_image]`, bgItem);
    if (!!bgItemUrl) formData.append(`ob[set_background_image_url]`, bgItemUrl);
    if (!!bgItemCleared) formData.append(`ob[clear_background_image]`, '1');
    formData.append('ob[background_colour]', mainObject?.background_colour || '');
    formData.append('ob[teaser]', mainObject?.teaser || '');
    formData.append('ob[description]', mainObject?.description || '');
    formData.append('ob[location_id]', mainObject?.location_id?.toString() || '');
    formData.append('ob[location_name]', mainObject?.location_name || '');
    formData.append('ob[location_street]', mainObject?.location_street || '');
    formData.append('ob[location_number]', mainObject?.location_number || '');
    formData.append('ob[location_zip]', mainObject?.location_zip || '');
    formData.append('ob[location_city]', mainObject?.location_city || '');
    formData.append('ob[location_country]', mainObject?.location_country || '');
    formData.append('ob[max_subscribers]', mainObject?.max_subscribers?.toString() || '');
    formData.append('ob[max_backups]', mainObject?.max_backups?.toString() || '');
    formData.append('ob[extra_data][mollie_settings][enabled]', !!mainObject?.extra_data?.mollie_settings?.enabled ? '1' : '0');
    formData.append('ob[extra_data][mollie_settings][cost]', mainObject?.extra_data?.mollie_settings?.cost?.toString() || '');
    formData.append('ob[is_frontpage]', !!mainObject?.is_frontpage ? '1' : '0');
    formData.append('ob[is_notify_mail]', !!mainObject?.is_notify_mail ? '1' : '0');
    formData.append('edit_all', !!editAll ? '1' : '0');

    mutationSave.mutate({
      formData: formData,
      keepOpen: keep_open
    });
  };

  let term = t('coach.models.workshops.table_title', "Overzicht workshops");
  let termSingle = t("coach.models.workshops.singular", "Workshop");
  if (!!currentPractice?.settings?.workshops_plural) {
    term = currentPractice.settings.workshops_plural;
  }
  if (!!currentPractice?.settings?.workshops_singular) {
    termSingle = currentPractice.settings.workshops_singular;
  }

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = t("general.titles.add", "Nieuwe") + " " + termSingle;
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).subject;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.subject) err.subject = true;
    if (!mainObject.coach_id) err.coach_id = true;
    if (!mainObject.start_time) err.start_time = true;
    if (!mainObject.end_time) err.end_time = true;
    if (!!mainObject.start_time && !!mainObject.end_time && DateTime.fromFormat(mainObject.start_time, "dd/LL/y t") > DateTime.fromFormat(mainObject.end_time, "dd/LL/y t")) err.start_time = true;
    if ((mainObject.max_subscribers || 0) < 1) err.max_subscribers = true;

    setErrors(err);
  }, [mainObject]);

  return <Page
    title={page_title}
    premiumGatekeeping={!!is_nutri_workshop}
    premiumMode='nutri_workshop'
    titleCrumbs={[
      {
        title: term,
        path: "/workshops"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.workshops.settings.general_title")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="subject"
            caption={t("coach.models.workshops.subject", "Onderwerp")}
            starred
            placeholder={t("coach.models.workshops.placeholders.subject", "Vul hier het onderwerp in")}
            value={mainObject.subject}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                subject: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{
            fontSize: '0.7rem'
          }}>{t("coach.models.workshops.start_time")} *</Typography>
          <DateAndTimePicker
            disableFuture={false}
            disablePast
            id="start_time"
            value={mainObject.start_time}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                start_time: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{
            fontSize: '0.7rem'
          }}>{t("coach.models.workshops.end_time")} *</Typography>
          <DateAndTimePicker
            disableFuture={false}
            disablePast
            id="end_time"
            value={mainObject.end_time}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                end_time: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            caption={t("coach.models.workshops.coach", "Coach")}
            starred
            displayEmpty
            id="coach"
            value={mainObject.coach_id || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                coach_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.workshops.placeholders.coach", "Wie organiseert")} ---</MenuItem>
            {(coaches || []).map(coach => <MenuItem value={coach.id}>{coach.full_name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.workshops.item", "Afbeelding")}</Typography>
          <ImagePicker
            existing={mainObject?.get_medium}
            item={item}
            itemUrl={itemUrl}
            deleteable
            onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setItem(_item);
              setItemUrl(_itemUrl);
              if (!_item && !_itemUrl) {
                setItemCleared(true);
              } else {
                setItemCleared(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Switcher
            id="is_bg_colour"
            caption={t("coach.models.workshops.is_background_colour", "Gebruik achtergrondkleur ipv achtergrondafbeelding")}
            value={!!mainObject?.is_background_colour}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                is_background_colour: c
              });
            }}
          />
        </Grid>
        {!!mainObject?.is_background_colour && <Grid item xs={12} md={6}>
          <ColourPicker
            label={t("coach.models.workshops.background_colour", "Achtergrondkleurtje")}
            value={mainObject?.background_colour}
            onChange={(v) => {
              setMainObject({
                ...mainObject,
                background_colour: v
              });
            }}
          />
        </Grid>}
        {!mainObject?.is_background_colour && <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.workshops.background_image", "Achtergrondafbeelding")}</Typography>
          <ImagePicker
            existing={mainObject?.get_bg_medium}
            deleteable
            item={bgItem}
            itemUrl={bgItemUrl}
            onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setBgItem(_item);
              setBgItemUrl(_itemUrl);
              if (!_item && !_itemUrl) {
                setBgItemCleared(true);
              } else {
                setBgItemCleared(false);
              }
            }}
          />
        </Grid>}
        <Grid item xs={12}>
          <TextInput
            id="teaser"
            caption={t("coach.models.workshops.teaser", "Teaser")}
            placeholder={t("coach.models.workshops.placeholders.teaser", "Vul hier de teaser in")}
            multiline
            value={mainObject.teaser || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                teaser: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            advanced
            label={t("coach.models.workshops.description", "Omschrijving")}
            value={mainObject?.description || ""}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                description: e
              });
            }}
          />
        </Grid>


        



        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("coach.workshops.settings.location_title")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <Switcher
            id="is_location"
            caption={t("coach.models.workshops.location", "Locatie")}
            value={!!isLocation || !!mainObject.location_id}
            onChange={(c) => {
              setIsLocation(c);
              if (!!c) {
                setMainObject({
                  ...mainObject,
                  location_id: undefined,
                  location_name: '',
                  location_street: '',
                  location_number: '',
                  location_zip: '',
                  location_city: '',
                  location_country: ''
                });
              } else {
                setMainObject({
                  ...mainObject,
                  location_id: undefined,
                  location_name: '',
                  location_street: '',
                  location_number: '',
                  location_zip: '',
                  location_city: '',
                  location_country: ''
                });
              }
            }}
          />
        </Grid>
        {isLocation && <Grid item xs={8}>
          <Select
            fullWidth
            caption={t("coach.models.workshops.location", "Locatie")}
            displayEmpty
            id="location"
            value={mainObject.location_id || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                location_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.workshops.placeholders.location", "Waar")} ---</MenuItem>
            {(locations || []).map(location => <MenuItem value={location.id}>{location.name}</MenuItem>)}
          </Select>
        </Grid>}
        {!isLocation && <Grid item xs={8}>
          <TextInput
            id="location_name"
            caption={t("coach.models.workshops.location_name", "Naam van de locatie")}
            placeholder={t("coach.models.workshops.placeholders.location_name", "Vul hier de locatie naam in")}
            value={mainObject.location_name || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                location_name: e
              });
            }}
          />
        </Grid>}
        {!isLocation && <Grid item xs={8}>
          <TextInput
            id="location_street"
            caption={t("coach.models.workshops.location_street", "-")}
            placeholder={t("coach.models.workshops.placeholders.location_street", "Vul hier de straat in")}
            value={mainObject.location_street || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                location_street: e
              });
            }}
          />
        </Grid>}
        {!isLocation && <Grid item xs={4}>
          <TextInput
            id="location_number"
            caption={t("coach.models.workshops.location_number", "-")}
            placeholder={t("coach.models.workshops.placeholders.location_number", "Vul hier de nummer in")}
            value={mainObject.location_number || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                location_number: e
              });
            }}
          />
        </Grid>}
        {!isLocation && <Grid item xs={4}>
          <TextInput
            id="location_zip"
            caption={t("coach.models.workshops.location_zip", "-")}
            placeholder={t("coach.models.workshops.placeholders.location_zip", "Vul hier de postcode in")}
            value={mainObject.location_zip || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                location_zip: e
              });
            }}
          />
        </Grid>}
        {!isLocation && <Grid item xs={8}>
          <TextInput
            id="location_city"
            caption={t("coach.models.workshops.location_city", "-")}
            placeholder={t("coach.models.workshops.placeholders.location_city", "Vul hier de plaatsnaam in")}
            value={mainObject.location_city || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                location_city: e
              });
            }}
          />
        </Grid>}
        {!isLocation && <Grid item xs={8}>
          <TextInput
            id="location_country"
            caption={t("coach.models.workshops.location_country", "-")}
            placeholder={t("coach.models.workshops.placeholders.location_country", "Vul hier het land in")}
            value={mainObject.location_country || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                location_country: e
              });
            }}
          />
        </Grid>}


        



        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("coach.workshops.settings.subscription_title")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="max_subscribers"
            caption={t("coach.models.workshops.max_subscribers", "Maximum aanwezigen")}
            starred
            placeholder={t("coach.models.workshops.placeholders.max_subscribers", "Vul hier het maximum aantal aanwezigen in")}
            value={mainObject.max_subscribers?.toString() || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                max_subscribers: parseInt(e.toString(), 10) || 0
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="max_backups"
            caption={t("coach.models.workshops.max_backups", "Maximum backups")}
            placeholder={t("coach.models.workshops.placeholders.max_backups", "Vul hier het maximum aantal backups in")}
            value={mainObject.max_backups?.toString() || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                max_backups: parseInt(e.toString(), 10) || 0
              });
            }}
          />
        </Grid>


        



        {is_payments && !!currentPractice?.settings?.mollie_api_key && <>
          <Grid item xs={12} sx={{marginTop: 5}}>
            <Typography variant="h5">{t("coach.workshops.settings.payments_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Switcher
              id="mollie_enabled"
              caption={t("coach.models.workshops.mollie_enabled", "Betaling mogelijk")}
              value={!!mainObject.extra_data?.mollie_settings?.enabled}
              onChange={(c) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject.extra_data || {}),
                    mollie_settings: {
                      ...(mainObject.extra_data?.mollie_settings || {}),
                      enabled: c
                    }
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="cost"
              disabled={!mainObject.extra_data?.mollie_settings?.enabled}
              caption={t("coach.models.workshops.cost", "Kost")}
              placeholder={t("coach.models.workshops.placeholders.cost", "99.99")}
              value={mainObject.extra_data?.mollie_settings?.cost || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject.extra_data || {}),
                    mollie_settings: {
                      ...(mainObject.extra_data?.mollie_settings || {}),
                      cost: e
                    }
                  }
                });
              }}
            />
          </Grid>
        </>}


        



        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("coach.workshops.settings.extra_title")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Switcher
            id="is_frontpage"
            caption={t("coach.models.workshops.is_frontpage", "Zichtbaar op de hoofdpagina?")}
            value={!!mainObject.is_frontpage}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                is_frontpage: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Switcher
            id="is_notify_mail"
            caption={t("coach.models.workshops.is_notify_mail", "Emails ontvangen bij in- en uitschrijvingen?")}
            value={!!mainObject.is_notify_mail}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                is_notify_mail: c
              });
            }}
          />
        </Grid>


        



        {!!mainObject.recurrence_id && <>
          <Grid item xs={12} sx={{marginTop: 5}}>
            <Typography variant="h5">{t("coach.workshops.settings.recurrence_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Switcher
              id="recurring_edit_all"
              caption={t("coach.models.workshops.recurring_edit_all")}
              value={!!editAll}
              onChange={(c) => {
                setEditAll(c);
              }}
            />
          </Grid>
        </>}

        <Grid item xs={12}>
          {!!mainObject.id && <Typography sx={{
            marginTop: 2,
            marginLeft: 1,
            marginRight: 1,
            fontSize: '0.7rem',
            color: theme.palette.secondary.main,
            fontStyle: 'italic'
          }}>{t("coach.models.workshops.edit_message", "Vergeet de daginstellingen niet te veranderen indien de datum en/of het uur gewijzigd werd!")}</Typography>}
          {/* {!mainObject.id && <Typography
            sx={{
              marginTop: 2,
              marginLeft: 1,
              marginRight: 1,
              fontSize: '0.7rem',
              color: theme.palette.secondary.main,
              fontStyle: 'italic'
            }}
          >{t("coach.models.workshops.new_message", "Na het aanmaken van de workshop zal je naar een nieuwe daginstelling verwezen worden, waar je je agenda kan blokkeren indien gewenst.")}</Typography>} */}
        </Grid>




        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/workshops");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
