import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { loginAtom } from '../../lib/auth';
import { backdropAtom } from '../../lib/backdrop';
// import { currentPracticeAtom } from '../../lib/practice';

import {
  Backdrop,
  Box
} from '@mui/material';

// import CloseIcon from '@mui/icons-material/Close';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import MenuIcon from '@mui/icons-material/Menu';

// import {
//   Typography
// } from '../../components/v2/styled';
import { SharedHeader } from './Header';
import { SharedSidebar } from './Sidebar';

import { Practice } from '../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {
  sx?: object;
  children?: React.ReactNode;
  version?: string;
  loginId?: number;
  practice?: Practice;
}

export const SharedLayout: FunctionComponent<Props> = ({sx, children, version, practice}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const [currentBackdrop, setCurrentBackdrop] = useAtom(backdropAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const nonmobile = useMediaQuery('(min-width:600px)');

  const [locale, setLocale] = useState<string>(i18n.resolvedLanguage || 'nl');

  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale]);

  return <Box sx={{
    // display: 'flex',
    // flexWrap: 'wrap',
    height: '100%',
    // alignContent: 'baseline',
    // backgroundColor: 'white'
  }}>


    <SharedHeader drawerOpen={menuVisible} setDrawerOpen={setMenuVisible} />
    <SharedSidebar drawerOpen={menuVisible} setDrawerOpen={setMenuVisible} />
    {!menuVisible && <Box sx={{
      flexDirection: "column",
      display: "flex",
      flexGrow: 1,
      height: "100%",
      overflowY: "scroll",
      overflowX: "none",
      width: "100%",
      "-webkit-overflow-scrolling": "touch",
      position: 'absolute',
      zIndex: 999,
      top: 0,
      left: 0,
      paddingTop: {
        xs: '56px',
        md: '75px'
      },
      paddingLeft: {
        xs: 0,
        md: '240px'
      },
      // padding: theme.spacing(3)
      "&>*": {
        minWidth: 300,
        overflowX: "hidden"
      }
    }} id="scroller">
      {children}
    </Box>}

    <Backdrop
      sx={{ color: '#fff', zIndex: 1500 }}
      open={!!currentBackdrop}
      onClick={() => {
        setCurrentBackdrop(undefined);
      }}
    >
      <img style={{maxWidth: '80%', width: '80%'}} src={currentBackdrop} />
    </Backdrop>

    
  </Box>;
}

