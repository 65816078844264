import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import TagIcon from '@mui/icons-material/Tag';

import {
  Avatar,
  Tooltip,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Page
} from '../../../components/v2/styled';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';

import { EmailTemplate } from '../../../models/EmailTemplate';

// const { DateTime } = require("luxon");

type Props = {}

export const AdminEmailTemplates: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("name ASC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "email_templates", {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<EmailTemplate>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'email_template',
          fields: ['id', 'name', 'type', 'children|count'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              no_practice: 1
            }
          },
          skip_api_restrictions: 1
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<EmailTemplate>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t('admin.models.email_templates.name', "Naam"), width: 350 },
    { field: 'type', headerName: t("admin.models.email_templates.type", "Type"), width: 400 },
    { field: 'children_count', headerName: t("admin.models.email_templates.children", "Customs"), width: 200 },
  ];

  return <Page title={t("admin.menu.email_templates")}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
    />
    <DataGrid
      action_count={1}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" path={`/email_template/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          {/* <IconButton title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: 'email_template',
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton> */}
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />

    <Fab
      onClick={() => {
        navigate("/email_template/new", {});
      }}
    />
  </Page>;
}
