import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from "@tanstack/react-query";
import { useAtom } from 'jotai';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';

import {
  Box,
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';

import {
  Button,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { QuestionnaireResult } from '../../../models/QuestionnaireResult';

type Props = {
  object: QuestionnaireResult;
  open: boolean;
  setOpen: (b:boolean) => void;
  setSavedOpen: (b:boolean) => void;
};

export const CoachShowQuestionnaire: FunctionComponent<Props> = ({open, setOpen, object, setSavedOpen}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  // const [open, setOpen] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isFrontend, setIsFrontend] = useState<boolean>(false);






  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      setOpen(false);
    },
  });








  // useEffect(() => {
  //   setOpen(!hidden);
  // }, [hidden]);
  
  useEffect(() => {
    if (!!object) {
     setComment(object.comment || '');
     setIsLocked(!!object.is_locked);
     setIsFrontend(!!object.is_frontend);
    }
   }, [object]);

  return (<>
    <Base
      name="repeat"
      fullWidth
      hidden={!open}
      content={<Grid container spacing={1}>
        {!!object && <>
          <Grid item xs={12} sx={{position: 'relative'}}>
            <Typography variant="h1">{object?.questionnaire?.name}</Typography>
            <Typography variant="h5">{object?.practice?.name}</Typography>
            <Divider />
          </Grid>
          {!!((object?.questionnaire?.extra_data?.intro || {})[i18n.resolvedLanguage || 'nl'] || (object?.questionnaire?.extra_data?.intro || {})['nl']) && <Grid item xs={12}>
            <Typography>{(object?.questionnaire?.extra_data?.intro || {})[i18n.resolvedLanguage || 'nl'] || (object?.questionnaire?.extra_data?.intro || {})['nl']}</Typography>
          </Grid>}
          {(object?.questionnaire?.extra_data?.questions || []).map((question, i) => <>
            {!!question.section && <>
              <Grid item xs={12} sx={{
                marginTop: 3
              }}>
                <Box sx={{
                  marginTop: 2,
                  padding: 2,
                  border: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  backgroundImage: `linear-gradient(to right, transparent, ${theme.palette.primary.main})`,
                  borderRadius: 2
                }}>
                  {!!((question.section_title || {})[i18n.resolvedLanguage || 'nl'] || (question.section_title || {})['nl']) && <Typography variant="h2">{(question.section_title || {})[i18n.resolvedLanguage || 'nl'] || (question.section_title || {})['nl']}</Typography>}
                  {!!((question.section_content || {})[i18n.resolvedLanguage || 'nl'] || (question.section_content || {})['nl']) && <Typography variant="caption">{(question.section_content || {})[i18n.resolvedLanguage || 'nl'] || (question.section_content || {})['nl']}</Typography>}
                  <Divider />
                </Box>
              </Grid>
            </>}
            {!question.section && <>
              <Grid item xs={12} sx={{
                marginTop: 3,
                paddingLeft: "32px !important"
              }}>
                <Typography variant="h4">{(question.question || {})[i18n.resolvedLanguage || 'nl'] || (question.question || {})['nl']} {!!question.required ? "*" : ""}</Typography>
                {!!((question.help || {})[i18n.resolvedLanguage || 'nl'] || (question.help || {})['nl']) && <Typography variant="caption">{(question.help || {})[i18n.resolvedLanguage || 'nl'] || (question.help || {})['nl']}</Typography>}
                <Divider />
              </Grid>
              {question.type === 'existing' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                <Typography sx={{marginTop: 1, paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(object?.extra_data?.answers || [])[i] || ''}</Typography>  
              </Grid>}
              {question.type === 'open' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                <Typography sx={{marginTop: 1, paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(object?.extra_data?.answers || [])[i] || ''}</Typography>  
              </Grid>}
              {question.type === 'open_long' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                <Typography sx={{marginTop: 1, paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(object?.extra_data?.answers || [])[i] || ''}</Typography>  
              </Grid>}
              {question.type === 'single_radio' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option => <Typography sx={{opacity: ((object?.extra_data?.answers || [])[i] === option ? '1' : '0.3')}}>{option}</Typography>)}
              </Grid>}
              {question.type === 'single_select' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                <Typography sx={{marginTop: 1, paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(object?.extra_data?.answers || [])[i] || ''}</Typography>  
              </Grid>}
              {question.type === 'multi' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option => <Typography sx={{opacity: (((object?.extra_data?.answers || [])[i] || []).indexOf(option) > -1 ? '1' : '0.3')}}>{option}</Typography>)}
              </Grid>}
              {question.type === 'nps' && <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                <Typography sx={{marginTop: 1, paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(object?.extra_data?.answers || [])[i] || ''}/{question.max || 10}</Typography>  
              </Grid>}
            </>}
          </>)}
            <Grid item xs={12} sx={{marginTop: 6}}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="comment"
                caption={t("client.models.questionnaire_results.comment")}
                multiline
                value={comment}
                onChange={(e) => {
                  setComment(e);
                }}
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <Switcher
                id="is_locked"
                caption={t("client.models.questionnaire_results.is_locked")}
                value={!!isLocked}
                onChange={(c) => {
                  setIsLocked(!!c);
                }}
              />
            </Grid>
            {object?.questionnaire?.content_type === 'review' && <Grid item xs={6} md={12}>
              <Switcher
                id="is_frontend"
                caption={t("client.models.questionnaire_results.is_frontend")}
                value={!!isFrontend}
                onChange={(c) => {
                  setIsFrontend(!!c);
                }}
              />
            </Grid>}
            <Grid item xs={12}>
              {!mutationSave.isPending && <Button
                label={t("shared.buttons.save")}
                id='save'
                contained
                onClick={(v) => {
                  mutationSave.mutate({
                    formData: {
                      object: 'questionnaire_result',
                      id: object?.id,
                      ob: {
                        id: object?.id,
                        comment: comment,
                        is_locked: isLocked,
                        is_frontend: isFrontend
                      }
                    }
                  });
                }}
                sx={{marginBottom: 1, marginTop: 1}}
              />}
            </Grid>
        </>}
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setOpen(false);}}>{t("coach.general.actions.cancel")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}