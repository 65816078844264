import React, { FunctionComponent, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Chip,
  InputAdornment,
  MenuItem,
  Select as Sel
} from '@mui/material';

import {
  Typography
} from '../styled';

type Props = {
  fullWidth?: boolean;
  displayEmpty?: boolean;
  backend?: boolean;
  noCaption?: boolean;
  chips?: boolean;
  value: number[] | string[];
  options?: {id: number|string, name: string}[];
  caption?: string;
  placeholder?: string;
  error?: string;
  helperText?: string;
  setValue: (s:string[]|number[]) => void;
  sx?: any;
  id: string;
  children?: any;
  icon?: ReactElement;
};

export const MultiSelect: FunctionComponent<Props> = ({options, icon, id, children, sx, fullWidth, displayEmpty, chips, value, setValue, backend, noCaption, caption, placeholder, error, helperText}) => {
  const theme = useTheme();

  // const [actualType, setActualType] = useState<string>(type || 'text');
 
  return (<>
    <label>
      {!!backend && !noCaption && (<Typography variant="caption" sx={{
        marginTop: 1,
        marginRight: 1,
      }}>{!!caption ? caption : placeholder}</Typography>)}
      {!backend && caption && (<Typography variant="caption">{caption}</Typography>)}
      <Sel
        fullWidth={fullWidth}
        displayEmpty={displayEmpty}
        value={value}
        multiple
        placeholder={placeholder}
        onChange={(e) => {
          setValue(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
        }}
        id={id}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                minWidth: '300px !important'
              },
            },
            MenuListProps: {
              sx: {
                "& > .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: theme.palette.primary.light
                },
                "& > .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: theme.palette.primary.light
                },
              }
            }
          }
        }}
        sx={{
          "> div": {
            paddingTop: 1,
            paddingBottom: 1
          },
          "> svg": {
            zIndex: 1000
          },
          backgroundColor: "rgb(244,248,247)",
          width: "100%",
          borderRadius: 18,
          marginTop: 3,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)"
          },
          ...(backend ? {
            backgroundColor: 'white',
            marginTop: 0,
            marginBottom: 1
          } : {}),
          ...sx
        }}
        endAdornment={ !!icon ? <InputAdornment position="end">{icon}</InputAdornment> : null}
        children={children || (options || []).map(opt => <MenuItem value={opt.id}>{opt.name}</MenuItem>)}
        renderValue={(selected) => (
          !!chips ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={((options || []).filter(ff => ff.id === value)[0] || {}).name} />
            ))}
          </Box> : <>{selected}</>
        )}
        // {..._.omit(this.props, "icon", "sx", "error", "helperText", "is_backend_input", "caption", "theme")}
        // menuPortalTarget={document.body}
        // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        // isClearable={true}
        // isSearchable={searchable}
      />
    </label>
    {!!error && (<Typography variant="caption" sx={{
      color: theme.palette.secondary.main,
      marginTop: 1,
      marginRight: 1,
    }}>{error}</Typography>)}
    {!!helperText && (<Typography variant="caption" sx={{
      marginTop: 1,
      marginRight: 1,
    }}>{helperText}</Typography>)}
  </>);
}