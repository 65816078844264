import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';
import { setCookie } from 'react-use-cookie';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

import {
  AppBar,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  ColourPicker,
  Editor,
  FileUpload,
  ImagePicker,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { PracticeLogoAvatar } from '../../../components/v2/PracticeLogoAvatar';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Practice } from '../../../models/Practice';

import HelpersLanguage from '../../../actions/helpers/language';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachGeneralSettingsPractice: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [extendedPractice, setExtendedPractice] = useState<Practice>();
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);

  const [activeId, setActiveId] = useState<number>(login?.id || 0);

  const fields = [
    'id', 'get_item', 'get_medium', 'item_is_square', 'group_move', 'group_eat', 'name', 'extra_data', 'support_email', 'language_id', 'full_intro', 'full_my_practice', 'full_description', 'url_path'
  ];

  const {
    data: extendedPracticeTemp,
    isLoading: extendedPracticeLoading,
    isError: extendedPracticeError,
    isSuccess: extendedPracticeSuccess,
    refetch: aextendedPracticeRefetch,
  } = useQuery({
    queryKey: ["general_settings", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: fields,
          id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!currentPractice?.id,
  });








  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedPracticeRefetch();
      setSavedOpen(true);
    },
  });








  const saveUser = () => {
    if (!!extendedPractice) {
      let data = new FormData();
      data.append('object', 'practice');
      data.append('id', extendedPractice.id?.toString() || '');
      data.append('ob[id]', extendedPractice.id?.toString() || '');
      data.append('ob[group_eat]', extendedPractice.group_eat || '');
      data.append('ob[group_move]', extendedPractice.group_move || '');
      if (!!extendedPractice.item) {
        data.append(`ob[item]`, extendedPractice.item);
      }
      data.append('ob[extra_data][is_square_logo]', !!extendedPractice.extra_data?.is_square_logo ? '1' : '0');
      data.append('ob[support_email]', extendedPractice.support_email || '');
      data.append('ob[language_id]', extendedPractice.language_id?.toString() || '');
      data.append('ob[intro]', (extendedPractice.full_intro || {})[1]?.toString() || '');
      data.append('ob[my_practice]', (extendedPractice.full_my_practice || {})[1]?.toString() || '');
      data.append('ob[description]', (extendedPractice.full_description || {})[1]?.toString() || '');

      Object.keys(extendedPractice.full_description || {}).map((key) => {
        if (parseInt(key, 10) !== 1) {
          data.append(`translations[description][${key}]`, (extendedPractice.full_description || {})[parseInt(key, 10)]?.toString() || '');
        }
      });
      Object.keys(extendedPractice.full_my_practice || {}).map((key) => {
        if (parseInt(key, 10) !== 1) {
          data.append(`translations[my_practice][${key}]`, (extendedPractice.full_my_practice || {})[parseInt(key, 10)]?.toString() || '');
        }
      });
      Object.keys(extendedPractice.full_intro || {}).map((key) => {
        if (parseInt(key, 10) !== 1) {
          data.append(`translations[intro][${key}]`, (extendedPractice.full_intro || {})[parseInt(key, 10)]?.toString() || '');
        }
      });
      
      // fields.forEach((word, index) => {
      //   data.append(`fields[]`, word);
      // });
      
      mutationSave.mutate({
        formData: data
      });
    }
  }

  useEffect(() => {
    if (!!extendedPracticeTemp) {
      setExtendedPractice(extendedPracticeTemp);
    }
  }, [extendedPracticeTemp]);

  return <Grid container spacing={2}>
    {!!extendedPracticeLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedPractice && <>
      
      <Grid item xs={12} md={4} container spacing={2} sx={{alignContent: 'baseline'}}>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <PracticeLogoAvatar
              practice={extendedPractice}
              showName
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.submenu.practice.groups", "Standaard clientprofielen")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  displayEmpty
                  caption={t("coach.models.clients.group_eat", "Eetdagboek groep")}
                  id="group_eat"
                  value={extendedPractice.group_eat || ''}
                  setValue={(v) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      group_eat: v.toString()
                    });
                  }}
                >
                  <MenuItem value="">{t("eat_diary.groups.none")}</MenuItem>
                  <MenuItem value="full">{t("eat_diary.groups.full")}</MenuItem>
                  <MenuItem value="mindfull">{t("eat_diary.groups.mindfull")}</MenuItem>
                  <MenuItem value="complaints">{t("eat_diary.groups.complaints")}</MenuItem>
                  <MenuItem value="move">{t("eat_diary.groups.move")}</MenuItem>
                  <MenuItem value="directions">{t("eat_diary.groups.directions")}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  displayEmpty
                  caption={t("coach.models.clients.group_move", "Beweegdagboek groep")}
                  id="group_move"
                  value={extendedPractice.group_move || ''}
                  setValue={(v) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      group_move: v.toString()
                    });
                  }}
                >
                  <MenuItem value="">{t("move_diary.groups.none")}</MenuItem>
                  <MenuItem value="full">{t("move_diary.groups.full")}</MenuItem>
                  <MenuItem value="limited">{t("eat_diary.groups.limited")}</MenuItem>
                  <MenuItem value="complaints">{t("move_diary.groups.complaints")}</MenuItem>
                </Select>
              </Grid>
            </Grid>
              
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                disabled={!extendedPractice.name}
                contained
                onClick={(e) => {
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Paper>
        </Grid>

      </Grid>

      <Grid container item xs={12} md={8} sx={{alignContent: 'baseline'}}>
        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.practice.title")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="name"
                  starred
                  caption={t("coach.models.practices.name", "Praktijknaam")}
                  placeholder={t("coach.models.practices.placeholders.name", "Vul hier de naam in")}
                  value={extendedPractice.name || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      name: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  caption={t("client.models.practices.item", "Afbeelding")}
                  fileInputName='item'
                  selected={extendedPractice.item}
                  onChange={(f) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      item: f as File
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Switcher
                  id="is_square"
                  caption={t("coach.models.practices.is_square")}
                  value={!extendedPractice?.extra_data?.is_square_logo}
                  onChange={(c) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice?.extra_data || {}),
                        is_square_logo: !c
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="support_email"
                  caption={t("coach.models.practices.support_email", "Praktijknaam")}
                  placeholder={t("coach.models.practices.placeholders.support_email", "Vul hier het support emailadres in")}
                  value={extendedPractice.support_email || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      support_email: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  displayEmpty
                  caption={t("coach.models.clients.language_id", "Vul hier de taalkeuze in")}
                  id="language_id"
                  value={extendedPractice.language_id || ''}
                  setValue={(v) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      language_id: !!v ? parseInt(v.toString(), 10) : undefined
                    });
                  }}
                >
                  {HelpersLanguage.available_locales().map(locale => <MenuItem value={HelpersLanguage.mapping_to_id(locale)}>{locale.toUpperCase()}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                <AppBar position="static" sx={{backgroundColor: theme.palette.primary.contrastText}}>
                  <Tabs value={currentLanguageId} onChange={(ev, i) => {
                    setCurrentLanguageId(i);
                    setFlickerEditor(true);
                    setTimeout(() => {setFlickerEditor(false);}, 200);
                  }}>
                    {HelpersLanguage.available_locales().map((locale, i) => {
                      return (<Tab value={HelpersLanguage.mapping_to_id(locale)} label={locale.toUpperCase()} id={`locale_tab_${locale}`} />)
                    })}
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                {!flickerEditor && <Editor
                  advanced
                  label={t("coach.models.practices.my_practice", "Tekst voor 'Mijn Praktijk'")}
                  value={(extendedPractice?.full_my_practice || {})[currentLanguageId] || ""}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      full_my_practice: {
                        ...(extendedPractice.full_my_practice || {}),
                        [currentLanguageId]: e
                      }
                    });
                  }}
                />}
              </Grid>
              <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                {!flickerEditor && <Editor
                  advanced
                  label={t("coach.models.practices.intro", "Tekst voor op de hoofdpagina, pre-login")}
                  value={(extendedPractice?.full_intro || {})[currentLanguageId] || ""}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      full_intro: {
                        ...(extendedPractice.full_intro || {}),
                        [currentLanguageId]: e
                      }
                    });
                  }}
                />}
              </Grid>
              <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                {!flickerEditor && <Editor
                  advanced
                  label={t("coach.models.practices.privacy_statement", "Privacy Statement")}
                  value={(extendedPractice?.full_description || {})[currentLanguageId] || ""}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      full_description: {
                        ...(extendedPractice.full_description || {}),
                        [currentLanguageId]: e
                      }
                    });
                  }}
                />}
              </Grid>
              
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>
    </>}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}

