import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import {
  Box,
  Button as Btn,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tooltip,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Dt,
  // Switcher,
  Typography
} from '../../../../components/v2/styled';

import { AppointmentType } from '../../../../models/AppointmentType';
import { Location } from '../../../../models/Location';
import { User } from '../../../../models/User';

const { DateTime } = require("luxon");

type Props = {
  noCoach: boolean;
  noLocation: boolean;
  selectedCoach?: User;
  selectedLocation?: Location;
  selectedType: AppointmentType;
  selectedSlot?: [string, Location, User];
  setSelectedSlot: (s: [string, Location, User]|undefined) => void;
}

export const ClientAppointmentNewStepSlot: FunctionComponent<Props> = ({selectedType, selectedCoach, selectedLocation, noCoach, noLocation, selectedSlot, setSelectedSlot}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  // const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  const [currentDay, setCurrentDay] = useState<string>(DateTime.now().toFormat("dd/LL/y"));
  const {
    data: slots,
    isLoading: slotsLoading,
    // isError: slotsError,
    isSuccess: slotsSuccess,
    // refetch: slotsRefetch,
  } = useQuery({
    queryKey: ["slots", currentPractice?.id, currentDay],
    queryFn: () =>
      fetch_one<{custom_result: {possibilities: any[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        {
          object: 'appointment',
          class_action: 'custom_api_find_slot',
          practice_id: currentPractice?.id,
          appointment_type_id: selectedType.id,
          coach_id: selectedCoach?.id,
          no_coach: (noCoach ? 1 : 0),
          location_id: selectedLocation?.id,
          no_location: (noLocation ? 1 : 0),
          start_date: currentDay
        },
        login
      ),
    select: (d) => {
      // hasVideo = d.filter(ff => ff.is_meeting).length > 0;
      // hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
      // return d;
      // console.log(d);
      return d.custom_result.possibilities;
    },
    gcTime: 0,
    enabled: !!currentPractice?.id,
  });






  
  return <Paper sx={{
    padding: 2
  }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={{fontWeight: 'bold'}}>{t("client.appointment.pick_slot")}:</Typography>
      </Grid>
      {/* {this.state.diff_tz &&
        <Grid item sm={12}>
          <Typography variant="p" color="secondary">{t("general_timezone_warning", "Let op, de getoonde tijdstippen ({tz_location}) zijn verschillend van uw tijdszone ({tz_user})").replace("{tz_location}", "Europe/Brussels").replace("{tz_user}", this.state.tz)}</Typography>
        </Grid>
      } */}
      <Grid item sm={3} xs={12}>
        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.appointment.options")}</Typography>

        <Typography variant="caption">{t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}</Typography>
        <DatePicker
          disableFuture={false}
          openTo="day"
          label=""
          format={"dd/LL/y"}
          // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
          views={["year", "month", "day"]}
          setValue={(e) => {
            setCurrentDay(e);
          }}
          value={currentDay}
          id="option_date"
          sx={{
            box: {
              width: 'auto',
              display: 'inline-block'
            },
            field_box: {
              "svg": {
                display: 'none'
              }
            },
            field: {
              width: 'auto',
              marginTop: 0,
              "input": {
                textAlign: 'center'
              }
            }
          }}
        />

        {/* <TextInput
          id="option_hour"
          caption={t("client.appointment.option_start_hour", "Welk uur draagt je voorkeur?")}
          placeholder="HH:MM"
          name="option_hour"
          autoComplete="option_hour"
          autoFocus={true}
          is_backend_input={false}
          value={this.state.options.start_hour || ""}
          multiline={false}
          onChange={(e) => {let s = this.state.options; s.start_hour = e.target.value; this.setState({options: s}, () => {this.get_next();});}}
          sx={{marginBottom: 10}}
        /> */}

        {slotsSuccess && (slots || []).length > 0 && <Box>
          {DateTime.fromFormat(currentDay, "dd/LL/y") > DateTime.now().plus({day: 2}) && <Tooltip title={t("client.general.actions.back_days", "Terug")}>
            <Button
              id="back_btn"
              sx={{
                '& svg': {
                  color: "rgba(0, 0, 0, 0.54)"
                },
                marginRight: 0.5
              }}
              onClick={(e) => {
                if (DateTime.fromFormat(currentDay, "dd/LL/y") <= DateTime.fromFormat(slots[0].date, "dd/LL/y").minus({day: 7})) {
                  setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").minus({day: 7}).toFormat("dd/LL/y"));
                } else {
                  setCurrentDay(DateTime.fromFormat(slots[0].date, "dd/LL/y").minus({day: 7}).toFormat("dd/LL/y"));
                }
              }}
              label={t("client.general.actions.back_days", "Terug")}
              startIcon={<SkipPreviousIcon />}
            />
          </Tooltip>}

          <Tooltip title={t("client.general.actions.next_days", "Volgende")}>
            <Button
              id="next_btn"
              sx={{
                '& svg': {
                  color: "rgba(0, 0, 0, 0.54)"
                }
              }}
              onClick={(e) => {
                setCurrentDay(DateTime.fromFormat(slots[slots.length - 1].date, "dd/LL/y").plus({day: 1}).toFormat("dd/LL/y"));
              }}
              label={t("client.general.actions.next_days", "Volgende")}
              endIcon={<SkipNextIcon />}
            />
          </Tooltip>
        </Box>}


      </Grid>
      {slotsLoading && <Grid item sm={9} xs={8} sx={{textAlign: 'center'}}>
          <CircularProgress color="primary" />
        </Grid>}

      {slotsSuccess && (slots || []).length === 0 && <Grid item sm={9} xs={8}>
        <Typography>{t("client.appointment.no_times", "Er zijn geen tijdstippen gevonden. Ga terug en gebruik andere zoektermen. Bijvoorbeeld door geen voorkeur op coach aan te duiden.")}</Typography>
      </Grid>}

      {slotsSuccess && (slots || []).map((slot, i) => {
        return (<Grid item sm={3} xs={4}>
          <Typography variant="subtitle1"><Dt o={slot.date} f="cccc" /></Typography>
          <Typography variant="subtitle1"><Dt o={slot.date} f="DDD" /></Typography>
          <Divider />
          <Grid item xs={12}>
            <Btn
              id={["submit", slot.date, i].join("_")}
              fullWidth
              variant="contained"
              sx={{
                marginTop: 1,
                marginBottom: 1,
                flexDirection: 'column',
                ...(!!selectedSlot ? (selectedSlot[0] === slot.next_slot[0] ? {
                  opacity: 1
                } : {
                  opacity: 0.2
                }) : {
                  opacity: 1
                })
              }}
              onClick={(e) => {
                e.preventDefault();
                if (!!selectedSlot && (selectedSlot[0] === slot.next_slot[0])) {
                  setSelectedSlot(undefined);
                } else {
                  setSelectedSlot(slot.next_slot);
                }
              }}
            >
              <Typography>{DateTime.fromFormat(slot.next_slot[0], "dd/LL/y t").toFormat("t")}</Typography>
              {noLocation && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{slot.next_slot[1].name}</Typography>}
              {noCoach && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{slot.next_slot[2].name}</Typography>}
            </Btn>
          </Grid>
          {/* @ts-ignore */}
          {!!slot.extra_slots && slot.extra_slots.map((s, ii) => {
            return (<Grid item xs={12}>
              <Btn
                id={["submit", slot.date, i, 'extra', ii].join("_")}
                fullWidth
                variant="contained"
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                  flexDirection: 'column',
                  ...(!!selectedSlot ? (selectedSlot[0] === s[0] ? {
                    opacity: 1
                  } : {
                    opacity: 0.2
                  }) : {
                    opacity: 1
                  })
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!!selectedSlot && (selectedSlot[0] === s[0])) {
                    setSelectedSlot(undefined);
                  } else {
                    setSelectedSlot(s);
                  }
                }}
              >
                <Typography>{DateTime.fromFormat(s[0], "dd/LL/y t").toFormat("t")}</Typography>
                {noLocation && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{s[1].name}</Typography>}
                {noCoach && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{s[2].name}</Typography>}
              </Btn>
            </Grid>);
          })}
        </Grid>);
      })}
    </Grid>
  </Paper>;
}

