import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';

import {
  Button,
  Page
} from '../../../components/v2/styled';
import { ClientAppointmentNewStepCoach } from './new_steps/Coach';
import { ClientAppointmentNewStepConfirm } from './new_steps/Confirm';
import { ClientAppointmentNewStepLocation } from './new_steps/Location';
import { ClientAppointmentNewStepSlot } from './new_steps/Slot';
import { ClientAppointmentNewStepType } from './new_steps/Type';
import { SnackError } from '../../../components/v2/snacks/Error';

import { AppointmentType } from '../../../models/AppointmentType';
import { Location } from '../../../models/Location';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientAppointmentNew: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  const [currentType, setCurrentType] = useState<AppointmentType>();
  const [currentCoach, setCurrentCoach] = useState<User>();
  const [noCoach, setNoCoach] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<Location>();
  const [showSnackError, setShowSnackError] = useState<boolean>(false);
  const [showSnackBlockedError, setShowSnackBlockedError] = useState<boolean>(false);
  const [noLocation, setNoLocation] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<[string, Location, User]>();
  const [appointment, setAppointment] = useState<{
    note: string,
    reminder_mail: boolean,
    reminder_sms: boolean
  }>({
    note: '',
    reminder_mail: true,
    reminder_sms: false
  });
  const [validatedUser, setValidatedUser] = useState<User>();
  const [validUser, setValidUser] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<User>();


  const {
    data: coaches,
    // isLoading: coachesLoading,
    // isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coach", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'coach',
          fields: [
            'id', 'full_name', 'coach_skills', 'get_medium', 'enabled', 'practice_id'
          ],
          page: 0,
          per_page: 1000,
          order: "first_name ASC, last_name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id,
              enabled: 1,
              is_secretary: 0,
              with_roster: 1
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   hasVideo = d.filter(ff => ff.is_meeting).length > 0;
    //   hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
    //   return d;
    // },
    enabled: !!searchParams.get("coach_id")
  });

  const {
    data: locations,
    // isLoading: locationsLoading,
    // isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'location',
          fields: [
            'id', 'name', 'get_medium', 'street', 'number', 'zip', 'city'
          ],
          page: 0,
          per_page: 1000,
          order: "name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id,
              enabled: 1,
              is_hidden: 0
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   hasVideo = d.filter(ff => ff.is_meeting).length > 0;
    //   hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
    //   return d;
    // },
    enabled: !!searchParams.get("location_id") && !!currentCoach
  });
  

  // const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  // const [statDay, setStatDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  // let count = 0;
  // const [page, setPage] = useState<number>(0);

  // const {
  //   data: appointmentsSoon,
  //   isLoading: appointmentsSoonLoading,
  //   isError: appointmentsSoonError,
  //   isSuccess: appointmentsSoonSuccess,
  //   refetch: appointmentsSoonRefetch,
  // } = useQuery({
  //   queryKey: ["appointments_soon", login?.id, currentDay],
  //   queryFn: () =>
  //     fetch_all<Appointment>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'appointment',
  //         fields: [
  //           'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow', 'location|id/get_item/name/is_online/street_line/city_line', 'coach|id/get_item/coach_skills/full_name', 'practice|id/name', 'cancel_note', 'order|id/paid',
  //           'appointment_type|id/colour/cost/duration_nice/name'
  //         ],
  //         page: 0,
  //         per_page: 20,
  //         filter: {
  //           search: '',
  //           advanced: {
  //             user_id: login?.id,
  //             soon: 1
  //           }
  //         }
  //       },
  //       login
  //     ),
  //   // select: (d) => {
  //   //   return d.custom_result.data;
  //   // },
  //   enabled: !!login,
  // });







  const mutationBookSlot = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      // diaryDayRefetch();
      // lastWeightRefetch();
      // queryClient.invalidateQueries({ queryKey: ["diary_day", login?.id, currentDay] });
      // queryClient.invalidateQueries({ queryKey: ["weight_stats", login?.id, currentDay, statRange] });
      // snack?
      console.log(data);
      if (!!data.custom_result.success) {
        if (!!login) {
          navigate('/appointments', {state: {show_appointment_success: true}});
        } else {
          navigate('/login', {state: {show_appointment_success: true}});
        }
      } else {
        if (data.custom_result.error === 'blocked') {
          setShowSnackBlockedError(true);
        } else {
          setShowSnackError(true);
        }
      }
    },
  });







  
  // const [open, setOpen] = useState<number>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  
  // useEffect(() => {
  //   if (!!diaryDay?.weight) setCurrentWeight(diaryDay?.weight);
  // }, [diaryDay]);
  // useEffect(() => {
  //   if (!!lastWeight?.weight) setCurrentWeight(lastWeight?.weight || currentWeight || 0);
  // }, [lastWeight]);

  // let inputWeightDisabled = false;
  // if (!!diaryDaySuccess && !!diaryDay.weight) inputWeightDisabled = true;

  useEffect(() => {
    if (!!coachesSuccess) {
      let tmpCoach = coaches.filter(fff => (fff.id || 0) === parseInt((searchParams.get("coach_id") || '-1'), 10))[0];
      if (!!tmpCoach) {
        setCurrentCoach(tmpCoach);
      }
    }
  }, [searchParams.get("coach_id"), coachesSuccess]);
  useEffect(() => {
    if (!!locationsSuccess) {
      let tmpLocation = locations.filter(fff => (fff.id || 0) === parseInt((searchParams.get("location_id") || '-1'), 10))[0];
      if (!!tmpLocation) {
        setCurrentLocation(tmpLocation);
      }
    }
  }, [searchParams.get("location_id"), locationsSuccess]);

  return <Page
    title={t("client.menu.appointment")}
  >
     <Stepper activeStep={currentStep} orientation={matchesmd ? "horizontal" : "vertical"} sx={{marginTop: 3}}>
      {[
        t("client.appointment.step.type", "Type"),
        t("client.appointment.step.coach", "Coach"),
        t("client.appointment.step.location", "Locatie"),
        t("client.appointment.step.date", "Datum"),
        t("client.appointment.step.confirm", "Bevestiging")
      ].map((label, index) => {
        return (<Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>);
      })}
    </Stepper>

    <Box>
      <Box sx={{
        marginTop: 3,
        marginBottom: 3
      }}>
        {/* <Pane>{this.getStep(currentStep)}</Pane> */}
        {currentStep === 0 && <ClientAppointmentNewStepType
          activeCoach={currentCoach}
          activeLocation={currentLocation}
          selectedType={currentType}
          setSelectedType={(type) => {
            setCurrentType(type);
            if (!!searchParams.get("coach_id") && !!searchParams.get("location_id")) {
              // reset step 4
              setSelectedSlot(undefined);
              // reset step 5
              setAppointment({
                note: '',
                reminder_mail: true,
                reminder_sms: false
              });
              setValidatedUser(undefined);
              setValidUser(false);
              setNewUser(undefined);
            } else {
              // reset step 2
              setCurrentCoach(undefined);
              setNoCoach(false);
              // reset step 3
              setCurrentLocation(undefined);
              setNoLocation(false);
              // reset step 4
              setSelectedSlot(undefined);
              // reset step 5
              setAppointment({
                note: '',
                reminder_mail: true,
                reminder_sms: false
              });
              setValidatedUser(undefined);
              setValidUser(false);
              setNewUser(undefined);
            }
          }}
        />}
        {currentStep === 1 && !!currentType && <ClientAppointmentNewStepCoach
          noCoach={noCoach}
          selectedType={currentType}
          selectedCoach={currentCoach}
          setNoCoach={(bool) => {
            setCurrentCoach(undefined);
            setNoCoach(bool);
            // reset step 3
            setCurrentLocation(undefined);
            setNoLocation(false);
            // reset step 4
            setSelectedSlot(undefined);
            // reset step 5
            setAppointment({
              note: '',
              reminder_mail: true,
              reminder_sms: false
            });
            setValidatedUser(undefined);
            setValidUser(false);
            setNewUser(undefined);
          }}
          setSelectedCoach={(coach) => {
            setCurrentCoach(coach);
            setNoCoach(false);
            // reset step 3
            setCurrentLocation(undefined);
            setNoLocation(false);
            // reset step 4
            setSelectedSlot(undefined);
            // reset step 5
            setAppointment({
              note: '',
              reminder_mail: true,
              reminder_sms: false
            });
            setValidatedUser(undefined);
            setValidUser(false);
            setNewUser(undefined);
          }}
        />}
        {currentStep === 2 && !!currentType && (!!currentCoach || noCoach) && <ClientAppointmentNewStepLocation
          noCoach={noCoach}
          noLocation={noLocation}
          selectedType={currentType}
          selectedCoach={currentCoach}
          selectedLocation={currentLocation}
          setNoLocation={(bool) => {
            setCurrentLocation(undefined);
            setNoLocation(bool);
            // reset step 4
            setSelectedSlot(undefined);
            // reset step 5
            setAppointment({
              note: '',
              reminder_mail: true,
              reminder_sms: false
            });
            setValidatedUser(undefined);
            setValidUser(false);
            setNewUser(undefined);
          }}
          setSelectedLocation={(coach) => {
            setCurrentLocation(coach);
            setNoLocation(false);
            // reset step 4
            setSelectedSlot(undefined);
            // reset step 5
            setAppointment({
              note: '',
              reminder_mail: true,
              reminder_sms: false
            });
            setValidatedUser(undefined);
            setValidUser(false);
            setNewUser(undefined);
          }}
        />}
        {currentStep === 3 && !!currentType && (!!currentCoach || noCoach) && (!!currentLocation || noLocation) && <ClientAppointmentNewStepSlot
          noCoach={noCoach}
          noLocation={noLocation}
          selectedType={currentType}
          selectedCoach={currentCoach}
          selectedLocation={currentLocation}
          selectedSlot={selectedSlot}
          setSelectedSlot={(slot) => {
            setSelectedSlot(slot);
            // reset step 5
            setAppointment({
              note: '',
              reminder_mail: true,
              reminder_sms: false
            });
            setValidatedUser(undefined);
            setValidUser(false);
            setNewUser(undefined);
          }}
        />}
        {currentStep === 4 && !!currentType && (!!currentCoach || noCoach) && (!!currentLocation || noLocation) && !!selectedSlot && <ClientAppointmentNewStepConfirm
          noCoach={noCoach}
          noLocation={noLocation}
          selectedType={currentType}
          selectedCoach={currentCoach}
          selectedLocation={currentLocation}
          selectedSlot={selectedSlot}
          appointment={appointment}
          validatedUser={validatedUser}
          validUser={validUser}
          setAppointment={(appointment) => {
            setAppointment(appointment);
          }}
          setValidatedUser={(user) => {
            setValidatedUser(user);
          }}
          setValidUser={(bool) => {
            setValidUser(bool);
          }}
          setNewUser={(user) => {
            setNewUser(user);
          }}
        />}
      </Box>
      <Box>
        <Button
          disabled={currentStep === 0}
          id="back"
          onClick={() => {
            if (!!searchParams.get("coach_id") && !!searchParams.get("location_id") && currentStep === 3) {
              setCurrentStep(0);
            } else {
              setCurrentStep(currentStep - 1);
            }
            document.getElementById("scroll_anchor")?.scrollIntoView();
          }}
          sx={{
            marginRight: 1
          }}
          label={t("button_back", "Terug")}
        />
        {<>
          <Button
            contained
            disabled={!(
              currentStep > 4 || (
                currentStep === 2 && (currentLocation || noLocation)
              ) || (
                currentStep === 1 && (!!currentCoach || noCoach)
              ) || (
                currentStep === 0 && !!currentType
              ) || (
                currentStep === 3 && !!selectedSlot
              ) || (
                currentStep === 4 && (!!login || (!login && !!validatedUser) || (!login && !!validUser)) && (!currentType?.note_required || (!!currentType?.note_required && (appointment.note || '').length > 5))
              )
            )}
            onClick={() => {
              if (currentStep === 4) {
                mutationBookSlot.mutate({
                  formData: {
                    object: 'appointment',
                    class_action: 'custom_api_book_slot',
                    ...(!!login ? {user_id: login?.id} : {}),
                    ...(!!validatedUser ? {user_id: validatedUser?.id} : {}),
                    ...(!!validUser ? {new_user: newUser} : {}),
                    appointment_type: currentType,
                    slot: selectedSlot,
                    practice_id: currentPractice?.id,
                    appointment_attrs: appointment
                  }
                });
              } else {
                if (!!searchParams.get("coach_id") && !!searchParams.get("location_id") && currentStep === 0) {
                  setCurrentStep(3);
                } else {
                  setCurrentStep(currentStep + 1);
                }
                document.getElementById("scroll_anchor")?.scrollIntoView();
              }
            }}
            sx={{
              marginRight: 1
            }}
            id="next"
            label={currentStep === 4 ? t("button_complete", "Bevestig") : t("button_next", "Volgende")}
          />
        </>}
      </Box>
    </Box>

    <SnackError open={showSnackError} setOpen={setShowSnackError} error={t("appointment.book_slot_fail")} />
    <SnackError open={showSnackBlockedError} setOpen={setShowSnackBlockedError} error={t("appointment.book_slot_fail_blocked")} />
  </Page>;
}

