import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EuroIcon from '@mui/icons-material/Euro';
import LaunchIcon from '@mui/icons-material/Launch';

import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Tooltip,
} from '@mui/material';

import {
  Button,
  Page,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { ClientPicker } from '../../../components/v2/ClientPicker';
import { Confirm } from '../../../components/v2/dialogs/Confirm';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Workshop } from '../../../models/Workshop';
import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachWorkshopSubscribers: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  // const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const [addUserId, setAddUserId] = useState<number>();
  const [removeUserId, setRemoveUserId] = useState<number>();
  const [clientSearch, setClientSearch] = useState<string>('');
  
  const is_nutri_workshop = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_workshop'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["workshop_short", (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<Workshop>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'workshop',
          id: objectId,
          fields: [
            'id', 'subject', 'max_subscribers', 'max_backups', 'list_subscribers', 'list_backups', 'get_subscriber_ids', 'get_backup_ids', 'paid_ids'
          ],
          sub_fields: {
            'list_subscribers': ['id', 'full_name', 'get_medium'],
            'list_backups': ['id', 'full_name', 'get_medium']
          }
        },
        login
      ),
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });

  const {
    data: clients,
    isLoading: clientsLoading,
    isError: clientsError,
    isSuccess: clientsSuccess,
    refetch: clientsRefetch,
  } = useQuery({
    queryKey: ["clients", currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'last_login_date', 'language_id', 'email'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: clientSearch,
            advanced: {
              for_practice: (login?.practice_id || currentPractice?.id),
              // enabled: '1'
            }
          }
        },
        login
      ),
    select: (d) => {
      return d.filter(fff => (object?.get_subscriber_ids || []).indexOf(fff.id || 0) < 0 && (object?.get_backup_ids || []).indexOf(fff.id || 0) < 0);
    },
    enabled: !!currentPractice?.id && !!object?.id
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any
    }) => {
      return fetch_one<Workshop>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setAddUserId(undefined);
      setRemoveUserId(undefined);
      setSavedOpen(true);
      setConfirmOpen(false);
      objectRefetch();
    },
  });





  let term = t('coach.models.workshops.table_title', "Overzicht workshops");
  if (!!currentPractice?.settings?.workshops_plural) {
    term = currentPractice.settings.workshops_plural;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    clientsRefetch();
  }, [object]);
  
  return <Page
    title={`${t("coach.submenu.workshops.subscribers", "Inschrijvingen")} (${object?.subject})`}
    premiumGatekeeping={!!is_nutri_workshop}
    premiumMode='nutri_workshop'
    titleCrumbs={[
      {
        title: term,
        path: "/workshops"
      }
    ]}
  >
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.workshops.add_clients")}</Typography>
              <Divider />
            </Grid>


            <ClientPicker
              clientId={addUserId}
              setClientId={(n) => {
                setAddUserId(n);
              }}
              ignoreIds={[
                ...(object?.get_subscriber_ids || []),
                ...(object?.get_backup_ids || [])
              ]}
            />

            {/* <Grid item xs={12} md={6}>
              <Typography sx={{
                textDecoration: 'underline'
              }}>{t("coach.agenda.appointment.client_exists")}</Typography>
              <TextInput
                id="client_search"
                caption={t("coach.models.appointments.client_search", "Zoek client")}
                value={clientSearch}
                onChange={(e) => {
                  setClientSearch(e);
                }}
              />
              <Typography sx={{fontSize: '0.7rem', fontStyle: 'italic', marginBottom: 1}}>{(clients || []).length} {t("general.results_found")}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{
                // textDecoration: 'underline'
              }}>&nbsp;</Typography>
              <Select
                fullWidth
                caption={t("coach.models.appointments.client", "Client")}
                starred
                displayEmpty
                id="user_id"
                value={addUserId?.toString() || ''}
                setValue={(v) => {
                  setAddUserId(!!v ? parseInt(v.toString(), 10) : undefined);
                }}
              >
                <MenuItem value="">------</MenuItem>
                {(clients || []).map(client => <MenuItem value={client.id}>{client.full_name}</MenuItem>)}
              </Select>
            </Grid> */}


            <Grid item xs={12} sx={{
              alignContent: 'baseline',
              textAlign: 'center',
              position: 'sticky',
              bottom: '-24px',
              backgroundColor: 'white',
              zIndex: 1201
            }}>
              <Divider sx={{marginTop: 1, marginBottom: 0}} />

              {!!mutationCustomAction.isPending && <CircularProgress />}
              {!mutationCustomAction.isPending && !addUserId && <Button
                label={t("coach.general.actions.create_new", "Maak nieuwe client")}
                id='create_new'
                contained
                onClick={(v) => {
                  navigate("/user/new");
                }}
                sx={{marginBottom: 1, marginTop: 1}}
              />}
              {!mutationCustomAction.isPending && <Button
                label={t("coach.general.actions.subscribe")}
                id='save'
                disabled={!addUserId}
                contained
                onClick={(v) => {
                  mutationCustomAction.mutate({formData: {
                    object: 'workshop',
                    class_action: 'custom_api_change_subscription',
                    id: object?.id,
                    user_id: addUserId,
                    sub: 1
                  }});
                }}
                sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
              />}
              {!mutationCustomAction.isPending && <Button
                label={t("shared.buttons.cancel")}
                id='cancel'
                text
                // contained
                onClick={(v) => {
                  navigate("/workshops");
                }}
                sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
              />}
            </Grid>

          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>

            <Grid item xs={12} sx={{position: 'relative'}}>
              <Typography variant="h5">{t("coach.submenu.workshops.list_subscribers")}</Typography>
              <Divider />
              <Typography sx={{
                position: 'absolute',
                right: 36,
                top: 8,
                fontSize: '0.9rem'
              }}>{(object?.list_subscribers || []).length}/{object?.max_subscribers || 0}</Typography>
              <CircularProgress variant="determinate" size={20} value={100} sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 1000,
                color: 'rgba(0, 0, 0, 0.05)'
              }} />
              <CircularProgress variant="determinate" size={20} value={(object?.max_subscribers || 0) === 0 ? 0 : ((object?.list_subscribers || []).length / (object?.max_subscribers || 0) * 100)} sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 1001
              }} />
            </Grid>

            <Grid item xs={12}>
              <List>
                {(object?.list_subscribers || []).map(user => <ListItem divider={(object?.list_subscribers || []).indexOf(user) < (object?.list_subscribers || []).length - 1}>
                  <ListItemAvatar>
                    <Avatar src={user.get_medium}></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.full_name} />
                  <ListItemSecondaryAction>

                  {(object?.paid_ids || []).indexOf(user.id || 0) > -1 && <Tooltip title={t("coach.general.status.paid", "Betaald")}>
                    <IconButton aria-label="paid">
                      <EuroIcon sx={{
                        color: theme.palette.primary.main
                      }} />
                    </IconButton>
                  </Tooltip>}

                    <Tooltip title={t("coach.general.actions.goto", "Ga naar")}>
                      <IconButton aria-label="goto" onClick={(e) => {
                        window.open(`/user/${user.id}/detail`, "_BLANK");
                      }}>
                        <LaunchIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t("coach.general.actions.destroy", "Verwijder")}>
                      <IconButton aria-label="delete" onClick={(e) => {
                        setRemoveUserId(user.id);
                        setConfirmOpen(true);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                  </ListItemSecondaryAction>
                </ListItem>)}
              </List>
            </Grid>

          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6} sx={{opacity: (object?.max_backups || 0) === 0 ? 0.3 : 1}}>
        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>

            <Grid item xs={12} sx={{position: 'relative'}}>
              <Typography variant="h5">{t("coach.submenu.workshops.list_backups")}</Typography>
              <Divider />
              <Typography sx={{
                position: 'absolute',
                right: 36,
                top: 8,
                fontSize: '0.9rem'
              }}>{(object?.list_backups || []).length}/{object?.max_backups || 0}</Typography>
              <CircularProgress variant="determinate" size={20} value={100} sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 1000,
                color: 'rgba(0, 0, 0, 0.05)'
              }} />
              <CircularProgress variant="determinate" size={20} value={(object?.max_backups || 0) === 0 ? 0 : ((object?.list_backups || []).length / (object?.max_backups || 0) * 100)} sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 1001
              }} />
            </Grid>

            <Grid item xs={12}>
              <List>
                {(object?.list_backups || []).map(user => <ListItem divider={(object?.list_backups || []).indexOf(user) < (object?.list_backups || []).length - 1}>
                  <ListItemAvatar>
                    <Avatar src={user.get_medium}></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.full_name} />
                  <ListItemSecondaryAction>

                    <Tooltip title={t("coach.general.actions.goto", "Ga naar")}>
                      <IconButton aria-label="goto" onClick={(e) => {
                        window.open(`/user/${user.id}/detail`, "_BLANK");
                      }}>
                        <LaunchIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t("coach.general.actions.destroy", "Verwijder")}>
                      <IconButton aria-label="delete" onClick={(e) => {
                        setRemoveUserId(user.id);
                        setConfirmOpen(true);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                  </ListItemSecondaryAction>
                </ListItem>)}
              </List>
            </Grid>

          </Grid>
        </Paper>
      </Grid>
    </Grid>

    <Confirm
      open={confirmOpen}
      setOpen={setConfirmOpen}
      title={t("client.general.actions.workshop_unsubscribe")}
      content={t("general.confirm.workshop_unsubscribe_coach")}
      execute={() => {
        mutationCustomAction.mutate({formData: {
          object: 'workshop',
          class_action: 'custom_api_change_subscription',
          id: object?.id,
          user_id: removeUserId,
          sub: 0
        }});
      }}
    />

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
