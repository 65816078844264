import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all_with_count, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  Grid,
  Paper,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Fab,
  IconButton,
} from '../../../components/v2/styled';
import { CoachLocationEdit } from '../../../components/v2/dialogs/CoachLocationEdit';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Location } from '../../../models/Location';
import { Practice } from '../../../models/Practice';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachGeneralSettingsLocations: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("enabled DESC, id DESC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [activeLocation, setActiveLocation] = useState<Location>();
  const [itemCleared, setItemCleared] = useState<boolean>(false);
  const [bgItemCleared, setBgItemCleared] = useState<boolean>(false);

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "locations", (login?.practice_id || currentPractice?.id), {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: [
            'id', 'name', 'extra_data', 'street', 'number', 'zip', 'city', 'country_id', 'coaches', 'enabled', 'get_medium', 'lat', 'lng',
            'is_online', 'is_hidden', 'invisible_coaches', 'background_colour', 'get_bg_item', 'is_background_colour'
          ],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });
  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", 'with_sec', currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name', 'is_secretary'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });
  const mutationSaveAppType = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      setEditOpen(false);
      setSavedOpen(true);
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t('coach.models.locations.name'), width: 250, sortable: true },
    { field: 'address', headerName: t('coach.models.locations.address'), width: 400, sortable: false },
  ];

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Paper sx={{
        padding: 2
      }}>
        <DataGrid
          action_count={1}
          hideCount
          actions={(params) => {
            return (<>
              <IconButton title="Edit" color="default" onClick={() => {
                setActiveLocation(params.row);
                setEditOpen(true);
              }}>
                <EditIcon />
              </IconButton>
              {/* <IconButton disabled title="Remove" destroyBtn color="default" onClick={() => {
                mutationDestroy.mutate({
                  formData: {
                    object: 'location',
                    id: params.row.id,
                    ob: {
                      id: params.row.id,
                    }
                  }
                });
              }}>
                <DeleteIcon />
              </IconButton> */}
            </>);
          }}
          objectCount={count}
          getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
          objects={(objects || []).map(u => ({
            ...u,
            address: [[u.street, u.number].join(' '), [u.zip, u.city].join(' ')].join(", ")
          }))}
          columns={columns}
          loader={objectsLoading}
          onSortModelChange={(a, b) => {
            let a_0_field:string = a[0].field;
            if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
            setOrder([a_0_field, a[0].sort].join(' '));
          }}
          page={page}
          setPage={(p) => {setPage(p);}}
        />
      </Paper>
    </Grid>

    {!!activeLocation && <CoachLocationEdit
      setBgItemCleared={setBgItemCleared}
      setItemCleared={setItemCleared}
      onSave={() => {
        let data = new FormData();
        data.append('object', 'location');
        data.append('fields[]', 'id');
        if (!!activeLocation.id) data.append('id', activeLocation.id?.toString() || '');
        if (!!activeLocation.id) data.append('ob[id]', activeLocation.id?.toString() || '');
        if (!!activeLocation.item) {
          data.append(`ob[item]`, activeLocation.item);
        }
        data.append(`ob[item_url]`, activeLocation.itemUrl || '');
        if (!!itemCleared) data.append(`ob[clear_item]`, '1');
        if (!!activeLocation.bg_item) {
          data.append(`ob[background_image]`, activeLocation.bg_item);
        }
        data.append(`ob[background_image_url]`, activeLocation.bgItemUrl || '');
        if (!!bgItemCleared) data.append(`ob[clear_background_image]`, '1');

        data.append('ob[name]', activeLocation.name || '');
        data.append('ob[is_online]', !!activeLocation.is_online ? '1' : '0');
        data.append('ob[street]', activeLocation.street || '');
        data.append('ob[number]', activeLocation.number || '');
        data.append('ob[zip]', activeLocation.zip || '');
        data.append('ob[city]', activeLocation.city || '');
        data.append('ob[country_id]', activeLocation.country_id?.toString() || '');
        data.append('ob[lat]', activeLocation.lat?.toString() || '');
        data.append('ob[lng]', activeLocation.lng?.toString() || '');
        (activeLocation.extra_data?.practice_types || []).forEach((word, index) => {
          data.append(`ob[extra_data][practice_types][]`, word.toString());
        });
        data.append('ob[is_background_colour]', !!activeLocation.is_background_colour ? '1' : '0');
        data.append('ob[background_colour]', activeLocation.background_colour || '');
        data.append('ob[practice_id]', (login?.practice_id || currentPractice?.id || '').toString());
        data.append('ob[enabled]', !!activeLocation.enabled ? '1' : '0');
        data.append('ob[is_hidden]', !!activeLocation.is_hidden ? '1' : '0');
        data.append(`ob[extra_data][colour]`, activeLocation.extra_data?.colour || '');
        (activeLocation.coaches || []).forEach((word, index) => {
          data.append(`ob[coaches][]`, word.toString());
        });
        (activeLocation.invisible_coaches || []).forEach((word, index) => {
          data.append(`ob[invisible_coaches][]`, word.toString());
        });

        mutationSaveAppType.mutate({
          formData: data
        });
      }}
      open={editOpen}
      setOpen={(b) => {
        setActiveLocation(undefined);
        setEditOpen(b);
      }}
      location={activeLocation}
      setLocation={setActiveLocation}
      isLoading={mutationSaveAppType.isPending}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Fab
      onClick={() => {
        setActiveLocation({
          name: '',
          street: '',
          number: '',
          zip: '',
          city: '',
          practice_types: [],
          coaches: (coaches || []).filter(fff => fff.is_secretary).map(coach => (coach.id || 0)),
          invisible_coaches: (coaches || []).filter(fff => fff.is_secretary).map(coach => (coach.id || 0))
        });
        setEditOpen(true);
      }}
    />
  </Grid>;
}

