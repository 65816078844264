import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  Editor,
  Page,
  Switcher,
  TextInput,
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

// CONFIG 1/6
import { CoachSkill } from '../../../models/CoachSkill';
import { Country } from '../../../models/Country';
import { Static } from '../../../models/Static';

import HelpersLanguage from '../../../actions/helpers/language';

type DaModule = {
  id?: number;
  name?: string;
  enabled?: boolean;
  cost?: number;
  cost_basic?: number;
  cost_plus?: number;
  cost_premium?: number;
  cost_cloud?: number;
}
type Language = {
  id?: number;
  name?: string;
  translated_name?: string;
  iso?: string;
}
type PracticeType = {
  id?: number;
  name?: string;
}
// CONFIG 2/6
type Props = {
  object_type: 'static' | 'coach_skill' | 'practice_type' | 'country' | 'da_module' | 'language';
  return_path: string;
};

export const AdminContentEdit: FunctionComponent<Props> = ({object_type, return_path}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  const objectId: string | undefined = params.id;

  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);
  // CONFIG 2.5/6
  const [mainObject, setMainObject] = useState<Static|CoachSkill|PracticeType|Country|DaModule|Language>({});
  const [item, setItem] = useState<File>();
  const [items, setItems] = useState<{[z:string]: File}>({});
  const [formDetails, setFormDetails] = useState<any[]>([]);

// CONFIG 3/6
  const fields = {
    'coach_skill': ['id', 'name'],
    'country': ['id', 'name', 'iso', 'call_code', 'currency', 'is_currency_before_amount'],
    'da_module': ['id', 'name', 'enabled', 'cost', 'cost_basic', 'cost_plus', 'cost_premium', 'cost_cloud'],
    'language': ['id', 'name', 'iso', 'translated_name'],
    'practice_type': ['id', 'name'],
    'static': ['id', 'static_key', 'full_static_content'],
  };

// CONFIG 4/6
  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["admin", "ob", object_type, objectId],
    queryFn: () =>
      fetch_one<Static|CoachSkill|PracticeType|Country|DaModule|Language>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: object_type,
          id: objectId,
          fields: fields[object_type]
        },
        login
      ),
    enabled: !!login && parseInt(objectId || '', 10) > 0,
  });







// CONFIG 5/6
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Static|CoachSkill|PracticeType|Country|DaModule|Language>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      // queryClient.invalidateQueries({ queryKey: ["admin", "email_templates"] });
      if (!variables.keepOpen) navigate(return_path);
      // if (!!variables.keepOpen && objectId === 'new') navigate(`/email_template/${data.id}`);
      // if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });







  

  const buildFormData = (formData:FormData, data:object|any, parentKey:string|undefined) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      if (Array.isArray(data)) {
        Object.values(data).forEach(value => {
          if (!!value) formData.append(`${parentKey}[]`, value.toString());
        });
      }  else {
        Object.keys(data).forEach(key => {
          if (key.indexOf('get_item') < 0) buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      }
    } else {
      const value = data == null ? '' : data;
  
      if (!!parentKey) formData.append(parentKey, value);
    }
  }








  const saveObject = (keep_open: boolean) => {
    let trans:{[s:string]: {[n:number]: string}} = {};

    if (object_type === 'static') {
      // @ts-ignore
      mainObject.static_content = ((mainObject.full_static_content || {})[1] || '')
      trans['static_content'] = {};
      // @ts-ignore
      Object.keys(mainObject.full_static_content || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
      // @ts-ignore
        trans['static_content'][parseInt(mm, 10)] = (mainObject.full_static_content || {})[parseInt(mm, 10)];
      });
    }
    if (object_type === 'da_module') {
      // @ts-ignore
      mainObject.cost = !!mainObject.cost ? mainObject.cost : 1;
      // @ts-ignore
      mainObject.cost_basic = !!mainObject.cost_basic ? mainObject.cost_basic : 1;
      // @ts-ignore
      mainObject.cost_plus = !!mainObject.cost_plus ? mainObject.cost_plus : 1;
      // @ts-ignore
      mainObject.cost_premium = !!mainObject.cost_premium ? mainObject.cost_premium : 1;
      // @ts-ignore
      mainObject.cost_cloud = !!mainObject.cost_cloud ? mainObject.cost_cloud : 1;
    }

    let pars = {
      object: object_type,
      fields: fields[object_type],
      ob: mainObject,
      translations: trans
    };

    const formData = new FormData();
    buildFormData(formData, pars, undefined);
    if (!!item) formData.append('files[item]', item);
    if (Object.keys(items).length > 0) {
      Object.keys(items).forEach(item_key => {
        formData.append(`files[${item_key}]`, items[item_key]);
      })
    }

    mutationSave.mutate({
      formData: formData
    });
  };

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
// CONFIG 6/6
    let fd: any[] = [];
    // [gridsize (unused when translatable), type, translatable, label, field, title]
    if (object_type === 'coach_skill') {
      fd.push([12, 'string', false, 'Name', 'name']);
    }
    if (object_type === 'country') {
      fd.push([8, 'string', false, 'Name', 'name']);
      fd.push([4, 'string', false, 'ISO code', 'iso']);
      fd.push([4, 'string', false, 'Call code', 'call_code']);
      fd.push([4, 'string', false, 'Currency', 'currency']);
      fd.push([4, 'switch', false, 'CurrencySign before amount', 'is_currency_before_amount']);
    }
    if (object_type === 'da_module') {
      fd.push([12, 'string', false, 'Name', 'name']);
      fd.push([4, 'switch', false, 'Enabled', 'enabled']);
    }
    if (object_type === 'language') {
      fd.push([12, 'string', false, 'Name', 'name']);
      fd.push([4, 'string', false, 'ISO', 'iso']);
      fd.push([8, 'string', false, 'English name', 'translated_name']);
    }
    if (object_type === 'practice_type') {
      fd.push([12, 'string', false, 'Name', 'name']);
    }
    if (object_type === 'static') {
      fd.push([12, 'string', false, 'Identifier', 'static_key']);
      fd.push([12, 'string', true, 'Body', 'full_static_content', undefined, true]);
    }
    setFormDetails(fd);
  }, []);
  // useEffect(() => {
  //   console.log(formDetails);
  // }, [formDetails]);

  // let is_multi = false;
  // formDetails.map(formDetail => {
  //   if (!is_multi) is_multi = !!formDetail[2];
  // })
// console.log(editableObject);
// console.log("aaa");
  return (
    <Page title={`Edit ${object_type}`}>
      <Paper sx={{padding: 2}}>
        <Grid container spacing={1}>

          {formDetails.length > 0 && formDetails.filter(fff => !!fff[2]).length > 0 && <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
            <AppBar position="static" sx={{backgroundColor: 'white'}}>
              <Tabs value={currentLanguageId} onChange={(ev, i) => {
                setCurrentLanguageId(i);
                setFlickerEditor(true);
                setTimeout(() => {setFlickerEditor(false);}, 200);
              }}>
                {HelpersLanguage.available_locales().map((locale, i) => {
                  return (<Tab
                    value={HelpersLanguage.mapping_to_id(locale)}
                    // @ts-ignore
                    label={<>{locale.toUpperCase()}{formDetails.filter(fff => !!fff[2] && !!fff[6] && (((mainObject || {})[fff[4].toString()] || {})[HelpersLanguage.mapping_to_id(locale)] || "").length < 1).length > 0 && <HighlightOffIcon
                      color="secondary"
                      sx={{
                        position: 'absolute',
                        right: 4,
                        bottom: 4,
                        height: '16px',
                        width: '16px'
                      }}
                    // @ts-ignore
                    />}{formDetails.filter(fff => !!fff[2] && !!fff[6] && (((mainObject || {})[fff[4].toString()] || {})[HelpersLanguage.mapping_to_id(locale)] || "").length > 0).length > 0 && <CheckCircleOutlineIcon
                      color="primary"
                      sx={{
                        position: 'absolute',
                        right: 4,
                        bottom: 4,
                        height: '16px',
                        width: '16px'
                      }}
                    />}</>}
                    id={`locale_tab_${locale}`}
                  />)
                })}
              </Tabs>
            </AppBar>
          </Grid>}

          {formDetails.length > 0 && formDetails.map(formData => <>

            {formData[1] === 'string' && <>
              {!!formData[2] &&  <Grid item xs={formData[0]} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                <TextInput
                  id={formData[4].toString()}
                  caption={formData[3].toString()}
                  multiline={!!formData[5]}
                  // @ts-ignore
                  value={((mainObject || {})[formData[4].toString()] || {})[currentLanguageId] || ""}
                  // backend
                  onChange={(e) => {
                    setMainObject({
                      ...mainObject,
                      [formData[4].toString()]: {
                        // @ts-ignore
                        ...((mainObject || {})[formData[4].toString()] || {}),
                        [currentLanguageId]: e
                      }
                    });
                  }}
                />
              </Grid>}
              {!formData[2] &&  <Grid item xs={formData[0]}>
                <TextInput
                  id={formData[4].toString()}
                  caption={formData[3].toString()}
                  multiline={!!formData[5]}
                  // @ts-ignore
                  value={(mainObject || {})[formData[4].toString()] || ""}
                  // backend
                  onChange={(e) => {
                    setMainObject({
                      ...mainObject,
                      [formData[4].toString()]: e
                    });
                  }}
                />
              </Grid>}
            </>}
            {formData[1] === 'editor' && <>
              {!!formData[2] &&  <Grid item xs={formData[0]} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                {!flickerEditor && <Editor
                  advanced
                  label={formData[3].toString()}
                  // @ts-ignore
                  value={((mainObject || {})[formData[4].toString()] || {})[currentLanguageId] || ""}
                  onChange={(e) => {
                    setMainObject({
                      ...mainObject,
                      [formData[4].toString()]: {
                        // @ts-ignore
                        ...((mainObject || {})[formData[4].toString()] || {}),
                        [currentLanguageId]: e
                      }
                    });
                  }}
                />}
              </Grid>}
              {!formData[2] &&  <Grid item xs={formData[0]}>
                {!flickerEditor && <Editor
                  advanced
                  label={formData[3].toString()}
                  // @ts-ignore
                  value={(mainObject || {})[formData[4].toString()] || ""}
                  onChange={(e) => {
                    setMainObject({
                      ...mainObject,
                      [formData[4].toString()]: e
                    });
                  }}
                />}
              </Grid>}
            </>}

            {formData[1] === 'switch' && <Grid item xs={formData[0]}>
              <Switcher
                id={formData[4].toString()}
                caption={formData[3].toString()}
                // @ts-ignore
                value={!!(mainObject || {})[formData[4].toString()]}
                onChange={(c) => {
                  setMainObject({
                    ...mainObject,
                    [formData[4].toString()]: !!c
                  });
                }}
              />
            </Grid>}

            {/* {formData[1] === 'date' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <StyledDateField
                label={formData[3].toString()}
                value={editableObject[formData[4].toString()] || ''}
                id={formData[4].toString()}
                onChange={(v) => {if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: v});
                }}
                fullWidth
              />
            </Grid>} */}

            {formData[1] === 'divider' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <Divider sx={{marginTop: 2, marginBottom: 2}} />
            </Grid>}

            {/* {formData[1] === 'locale_select' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <StyledSelect
                label="Language"
                value={editableObject[formData[4].toString()] || 'nl'}
                id={formData[4].toString()}
                onChange={(v) => {if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: v
                });}}
                list={[
                  {id: 'nl', name: "Dutch"},
                  {id: 'fr', name: "French"}
                ]}
              />
            </Grid>} */}

            {/* {formData[1] === 'upload' && formData[4] === 'item' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <Typography sx={{fontSize: '0.75rem'}}>Item</Typography>
              <StyledDropzone
                acceptedFiles={{}}
                onDrop={acceptedFiles => {
                  if (item !== acceptedFiles[0]) setItem(acceptedFiles[0]);
                }}
                multiple={false}
                maxFiles={1}
                maxSize={3 * 1000 * 1000}
                items={!!item ? [item] : []}
                dropText="Click here, or drop some files."
                dropHint="Allowed: 1 item < 3mb"
              />
              {!!editableObject?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableObject?.item_filename} ({(parseInt(editableObject?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            </Grid>} */}

            {/* {formData[1] === 'upload' && formData[4] !== 'item' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <Typography sx={{fontSize: '0.75rem'}}>{formData[3].toString()}</Typography>
              <StyledDropzone
                acceptedFiles={{}}
                onDrop={acceptedFiles => {
                  if (!!items && items[formData[4]] !== acceptedFiles[0]) setItems({
                    ...items,
                    [formData[4]]: acceptedFiles[0]
                  });
                }}
                multiple={false}
                maxFiles={1}
                maxSize={3 * 1000 * 1000}
                items={!!items && items[formData[4]] ? [items[formData[4]]] : []}
                dropText="Click here, or drop some files."
                dropHint="Allowed: 1 item < 3mb"
              />
              {!!editableObject && !!editableObject[`${formData[4]}_filename`] && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableObject[`${formData[4]}_filename`]} ({(parseInt(editableObject[`${formData[4]}_byte_size`] || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              {!!formData[5] && <Typography sx={{fontSize: '0.75rem', fontStyle: 'italic', color: 'lightgrey', marginBottom: 2}}>{formData[5]}</Typography>}
            </Grid>} */}

          </>)}
          







          
          <Grid item xs={12} sx={{
            alignContent: 'baseline',
            textAlign: 'center',
            position: 'sticky',
            bottom: '-24px',
            backgroundColor: 'white',
            zIndex: 1201
          }}>
            <Divider sx={{marginTop: 1, marginBottom: 0}} />

            {!!mutationSave.isPending && <CircularProgress />}
            {!mutationSave.isPending && <Button
              label={t("shared.buttons.save")}
              id='save'
              contained
              onClick={(v) => {
                saveObject(false);
              }}
              sx={{marginBottom: 1, marginTop: 1}}
            />}
            {!mutationSave.isPending && <Button
              label={t("shared.buttons.cancel")}
              id='cancel'
              text
              // contained
              onClick={(v) => {
                navigate(return_path);
              }}
              sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
            />}
          </Grid>
        </Grid>
      </Paper>
    </Page>
  );
}
