import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Editor,
  ImagePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { NewsItem } from '../../../models/NewsItem';
import { User } from '../../../models/User';

// import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachNewsItemEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<NewsItem>({
    title: ''
  });
  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [clImageItem, setClImageItem] = useState<File>();
  const [clImageItemUrl, setClImageItemUrl] = useState<string>();

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["news_item", (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<NewsItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'news_item',
          id: objectId,
          fields: [
            'id', 'get_item', 'title', 'published_at', 'is_coach_only', 'content_layout_key', 'content_layout', 'teaser', 'author', 'tags', 'enabled'
          ]
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        published_at: DateTime.fromISO(d.published_at).toFormat("dd/LL/y"),
        // end_time: DateTime.fromISO(d.end_time).toFormat("dd/LL/y t"),
        // background_colour: d.background_colour || 'rgb(211, 211, 211)'
      };
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });

  const {
    data: tags,
    isLoading: tagsLoading,
    isError: tagsError,
    isSuccess: tagsSuccess,
    refetch: tagsRefetch,
  } = useQuery({
    queryKey: ["news_item_tags", (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_one<{custom_result: {tags: string[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'news_item',
          class_action: 'custom_api_get_tags',
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      return d.custom_result.tags;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<NewsItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "news_items", (login?.practice_id || currentPractice?.id)] });
      if (!variables.keepOpen) navigate("/news_items");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/news_item/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const toDataUrl = (url: string, callback: (s:string | ArrayBuffer | null) => void) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
  const saveObject = (keep_open: boolean) => {
    let formData = new FormData();
    formData.append('object', 'news_item');
    formData.append('handler_id', (login?.id || "").toString());
    formData.append('fields[]', 'id');
    if (!!objectId && objectId !== 'new') formData.append('ob[id]', objectId.toString());
    if (!!objectId && objectId !== 'new') formData.append('id', objectId.toString());
    // mainObject
    formData.append('ob[title]', mainObject?.title || '');
    formData.append('ob[teaser]', mainObject?.teaser || '');
    formData.append('ob[published_at]', mainObject?.published_at || '');
    formData.append('ob[author]', mainObject?.author || '');
    formData.append('ob[tags]', mainObject?.tags || '');
    formData.append('ob[content_layout_key]', mainObject?.content_layout_key || '');
    formData.append('ob[content_layout][image]', mainObject?.content_layout?.image || '');
    formData.append('ob[content_layout][text]', mainObject?.content_layout?.text || '');
    formData.append('ob[is_coach_only]', !!mainObject?.is_coach_only ? '1' : '0');
    formData.append('ob[enabled]', !!mainObject?.enabled ? '1' : '0');
    formData.append('ob[practice_id]', currentPractice?.id?.toString() || login?.practice_id?.toString() || '');
    if (!!item) formData.append(`ob[item]`, item);
    if (!!itemUrl) formData.append(`ob[set_item_url]`, itemUrl);

    mutationSave.mutate({
      formData: formData,
      keepOpen: keep_open
    });
  };

  let term = t('coach.models.news_items.table_title');
  let termSingle = t("coach.models.news_items.singular", "Nieuwsbericht");

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = (mainObject || {}).title || termSingle.toLowerCase();
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).title;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.title) err.title = true;
    if (!mainObject.content_layout_key) err.content_layout_key = true;
    if (!mainObject.author) err.author = true;
    if (!mainObject.teaser) err.teaser = true;
    if (!!mainObject.enabled && !mainObject.published_at) err.published_at = true;
    // if (!mainObject.coach_id) err.coach_id = true;
    // if (!mainObject.start_time) err.start_time = true;
    // if (!mainObject.end_time) err.end_time = true;
    // if (!!mainObject.start_time && !!mainObject.end_time && DateTime.fromFormat(mainObject.start_time, "dd/LL/y t") > DateTime.fromFormat(mainObject.end_time, "dd/LL/y t")) err.start_time = true;
    // if ((mainObject.max_subscribers || 0) < 1) err.max_subscribers = true;
    
    setErrors(err);
  }, [mainObject]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: term,
        path: "/news_items"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("news_items.headings.general")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="subject"
            caption={t("coach.models.news_items.title", "Titel")}
            starred
            placeholder={t("coach.models.news_items.placeholders.title", "Vul hier de titel in")}
            value={mainObject.title || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                title: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Switcher
            id="enabled"
            caption={t("admin.models.news_items.enabled")}
            value={!!mainObject?.enabled}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                enabled: c
              });
            }}
          />
        </Grid>
        {!mainObject?.enabled && <Grid item xs={12} md={3}></Grid>}
        {!!mainObject?.enabled && <Grid item xs={12} md={3}>
          <Typography sx={{
            fontSize: '0.7rem'
          }}>{t("admin.models.news_items.published_at")} *</Typography>
          <DatePicker
            openTo="day"
            label=""
            format={"dd/LL/y"}
            // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
            views={["year", "month", "day"]}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                published_at: e
              });
            }}
            value={mainObject.published_at || ''}
            noIcon
            id={`published_at1`}
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                }
              }
            }}
          />
        </Grid>}
        <Grid item xs={12} md={3}>
          <Switcher
            id="is_coach_only"
            caption={t("admin.models.news_items.is_coach_only")}
            value={!!mainObject?.is_coach_only}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                is_coach_only: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            id="author"
            caption={t("coach.models.news_items.author", "Auteur")}
            starred
            placeholder={t("coach.models.news_items.placeholders.author", "Vul hier de auteur in")}
            value={mainObject.author || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                author: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="subject"
            caption={t("coach.models.news_items.tags", "Tags, gescheiden met een spatie")}
            placeholder={t("coach.models.news_items.placeholders.tags", "Vul hier de tags in, gescheiden met een spatie")}
            value={mainObject.tags || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                tags: e
              });
            }}
          />
          {(tags || []).map(tag => <Button
            label={tag}
            id={tag}
            onClick={(e) => {
              setMainObject({
                ...mainObject,
                tags: `${mainObject.tags} ${tag}`.replace("  ", " ")
              });
            }}
            sx={{marginRight: 0.5, marginTop: 0.5}}
            size="small"
          />)}
        </Grid>





        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("news_items.headings.tile")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.news_items.item", "Afbeelding")}</Typography>
          <ImagePicker
            existing={mainObject?.get_item}
            item={item}
            itemUrl={itemUrl}
            onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setItem(_item);
              setItemUrl(_itemUrl);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            id="teaser"
            caption={t("coach.models.news_items.teaser", "Teaser")}
            placeholder={t("coach.models.news_items.placeholders.teaser", "Vul hier de teaser in")}
            multiline
            starred
            value={mainObject.teaser || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                teaser: e
              });
            }}
          />
        </Grid>





        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("news_items.headings.content")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("admin.models.news_items.content_layout_key", "Hoe wordt de inhoud getoond?")}
            starred
            displayEmpty
            id="content_layout_key"
            value={mainObject.content_layout_key || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                content_layout_key: v.toString()
              });
            }}
          >
            <MenuItem value="">--- {t("admin.models.news_items.placeholders.content_layout_key", "ContentLayout type")} ---</MenuItem>
            <MenuItem value="Text">{t("content_layout.single_text.name", "SingleText")}</MenuItem>
            <MenuItem value="Image">{t("content_layout.single_image.name", "SingleImage")}</MenuItem>
            <MenuItem value="ImageText">{t("content_layout.image_text.name", "Image | Text")}</MenuItem>
            <MenuItem value="TextImage">{t("content_layout.text_image.name", "Text | Image")}</MenuItem>
            <MenuItem value="ImageRowText">{t("content_layout.image_row_text.name", "Image on top of Text")}</MenuItem>
          </Select>
        </Grid>
        {(mainObject.content_layout_key === "Image" || mainObject.content_layout_key === "ImageText" || mainObject.content_layout_key === "TextImage" || mainObject.content_layout_key === "ImageRowText") && <Grid item xs={12}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("content_layout.single_image.image.caption", "Afbeelding")}</Typography>
          <ImagePicker
            existing={mainObject.content_layout?.image}
            item={clImageItem}
            itemUrl={clImageItemUrl}
            onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              if (!!_itemUrl) {
                setClImageItemUrl(_itemUrl);
                toDataUrl(_itemUrl, (s) => {
                  setMainObject({
                    ...mainObject,
                    content_layout: {
                      ...(mainObject.content_layout || {}),
                      image: s
                    }
                  });
                });
              }

              if (!!_item) {
                setClImageItem(_item);
                let reader = new FileReader();
                reader.onloadend = function() {
                  setMainObject({
                    ...mainObject,
                    content_layout: {
                      ...(mainObject.content_layout || {}),
                      image: reader.result
                    }
                  });
                }
                reader.readAsDataURL(_item);
              }
            }}
          />
        </Grid>}
        {(mainObject.content_layout_key === "Text" || mainObject.content_layout_key === "ImageText" || mainObject.content_layout_key === "TextImage" || mainObject.content_layout_key === "ImageRowText") && <Grid item xs={12}>
          <Editor
            advanced
            label={t("content_layout.single_text.text.caption", "Inhoud")}
            value={mainObject?.content_layout?.text || ""}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                content_layout: {
                  ...(mainObject.content_layout || {}),
                  text: e
                }
              });
            }}
          />
        </Grid>}


        







        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/news_items");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
