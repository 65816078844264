import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';

import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';

import {
  Avatar,
  Box,
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Slider,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
} from '@mui/material';

import {
  Button,
  FileUpload,
  TextInput,
  TimePickerNew,
  Typography
} from '../../../components/v2/styled';
import {
  FaceUnhappy,
  FaceHappy,
  FaceVeryUnhappy,
  FaceVeryHappy
} from '../../../components/v2/faces';
import { Base } from './Base';

import { DiaryDay } from '../../../models/DiaryDay';
import { EatMoment as EatMmnt } from '../../../models/EatMoment';
import { FoodEntry } from '../../../models/FoodEntry';

const { DateTime } = require("luxon");

type Props = {
  onClick: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  newEatMoment: EatMmnt;
  setNewEatMoment: (n:EatMmnt) => void;
  diaryDay?: DiaryDay
};

export const ClientEatMomentEdit: FunctionComponent<Props> = ({open, setOpen, onClick, diaryDay, newEatMoment, setNewEatMoment}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [currentTab, setCurrentTab] = useState<string>((Object.values(login?.full_eat_groups || {}).filter(fff => fff.practices.length > 0).length > 0 ? 'eat_diary.groups.short' : 'eat_diary.groups.everything'));
  const [errors, setErrors] = useState<{[z:string]: string}>({});
  const [editing, setEditing] = useState<number>();
  const [newFoodEntry, setNewFoodEntry] = useState<FoodEntry>({entry: '', comment: ''});
// console.log(login?.full_eat_groups);
// console.log(DateTime.now().toFormat("t"));

  const checkFieldValidity = (fieldName:string) => {
    if (currentTab === "eat_diary.groups.everything") return true;

    return Object.values(login?.full_eat_groups || {}).filter(ff => ff.practices.length > 0 && ff.profile.questions.indexOf(fieldName) > -1).length > 0
  }
  const checkHelperIcon = (fieldName:string) => {
    if (currentTab === "eat_diary.groups.everything") return '';

    let practices:string[] = [];
    Object.values(login?.full_eat_groups || {}).map((ff, i) => {
      if (ff.profile.questions.indexOf(fieldName) > -1) practices = [...practices, ...(ff.practices.map(qq => qq[1]))];
    });

    let str = `${t("eat_diary.groups.asked_by")}<ul>`;
    let pr = practices.map((i) => {
      str += `<li>${i}</li>`
    });
    str += `</ul>`;
    return <Typography html sx={{padding: 1}}>{str}</Typography>;
  }

  return (<>
    <Base
      name="eat_moment_edit"
      title={`${t("client.dashboard.eat_diary.new_entry_title", "Nieuwe ingave")}${newEatMoment.eat_type ? ` (${t(`client.diary.${newEatMoment.eat_type}`)})` : ''}`}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="lg"
      content={<>
      
        <Tabs
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={(e, value) => {setCurrentTab(value);}} indicatorColor="primary" textColor="primary" centered={false}>
          <Tab label={t("eat_diary.groups.everything")} value={"eat_diary.groups.everything"} />
          {Object.values(login?.full_eat_groups || {}).filter(fff => fff.practices.length > 0).length > 0 && <Tab label={t("eat_diary.groups.short")} value={"eat_diary.groups.short"} />}
        </Tabs>

        <Divider />

        <Box>
          <Grid container sx={{
            marginTop: 1
          }} spacing={2}>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('time')}>
              <Typography sx={{marginTop: 1, fontSize: '0.7rem'}}>{t("client.models.eat_moments.entry_time", "Tijdstip")}</Typography>
              {!!errors.entry_time && <React.Fragment>
                <Typography variant={"caption"} sx={{marginTop: 1, color: theme.palette.secondary.main}}>{t("client.models.eat_moments.errors.entry_time", "Dit is een verplicht veld")}</Typography>
              </React.Fragment>}

              <TimePickerNew
                id="entry_time"
                value={newEatMoment.given_entry || DateTime.now().toFormat("t")}
                setValue={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    entry_time: e || undefined,
                    given_entry: e || undefined
                  });
                }}
                greyBg
              />
              {/* <TimePicker
                value={newEatMoment.given_entry || DateTime.now().toFormat("t")}
                setValue={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    entry_time: e || undefined,
                    given_entry: e || undefined
                  });
                }}
                ampm={false}
                openTo="hours"
                label=""
                id="entry_time"
                sx={{
                  field: {
                    marginTop: 0
                  }
                }}
                // cancelLabel="Annuleer"
                greyBg
              /> */}
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('location')}>
              <TextInput
                id="location"
                helperIcon={checkHelperIcon('location')}
                placeholder={t("client.models.eat_moments.placeholders.location", "Thuis, in het park, ...")}
                caption={t("client.models.eat_moments.location", "Locatie")}
                autoComplete="location"
                autoFocus={false}
                backend
                greyBg
                value={newEatMoment.location || ''}
                onChange={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    location: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('participants')}>
              <TextInput
                id="participants"
                helperIcon={checkHelperIcon('participants')}
                placeholder={t("client.models.eat_moments.placeholders.participants", "Alleen, gezin, ...")}
                caption={t("client.models.eat_moments.participants", "Wie nam deel aan het beweegmoment")}
                autoComplete="participants"
                autoFocus={false}
                backend
                greyBg
                value={newEatMoment.participants || ''}
                onChange={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    participants: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('activity')}>
              <TextInput
                id="activity"
                helperIcon={checkHelperIcon('activity')}
                placeholder={t("client.models.eat_moments.placeholders.activity", "Wandelen, fietsen, lopen, ...")}
                caption={t("client.models.eat_moments.activity", "Activiteit tijdens het beweegmoment")}
                autoComplete="activity"
                autoFocus={false}
                backend
                greyBg
                value={newEatMoment.activity || ''}
                onChange={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    activity: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('hunger')}>
              <Typography variant={"caption"} sx={{marginTop: 1}}>{t("client.models.eat_moments.hunger", "Hongergevoel")}</Typography>
              {!!checkHelperIcon('hunger') && <Tooltip title={checkHelperIcon('hunger')}><HelpIcon sx={{
                float: 'right',
                color: theme.palette.primary.main
              }} /></Tooltip>}
              <br />
              <Slider
                value={newEatMoment.hunger || -1}
                onChange={(ev, val, e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    hunger: typeof(val) === "number" ? val : val[0] || undefined
                  });
                }}
                step={1}
                max={10}
                sx={{marginLeft: '10%', marginTop: 0, width: '80%'}}
                marks={[
                  {
                    value: 0,
                    label: t("client.models.eat_moments.hunger_none", "Geen"),
                  },
                  {
                    value: 10,
                    label: t("client.models.eat_moments.hunger_much", "Veel"),
                  },
                ]}
                valueLabelDisplay="on"
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('complaints')}>
              <TextInput
                id="complaints"
                helperIcon={checkHelperIcon('complaints')}
                placeholder={t("client.models.eat_moments.placeholders.complaints", "Krampen, geen adem, ...")}
                caption={t("client.models.eat_moments.complaints", "Klachten omtrent het beweegmoment")}
                autoComplete="complaints"
                autoFocus={false}
                backend
                greyBg
                value={newEatMoment.complaints || ''}
                onChange={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    complaints: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('feeling')}>
              <Typography variant={"caption"} sx={{marginTop: 1}}>{t("client.models.eat_moments.feeling", "Gevoel na het beweegmoment")}</Typography>
              {!!checkHelperIcon('hunger') && <Tooltip title={checkHelperIcon('feeling')}><HelpIcon sx={{
                float: 'right',
                color: theme.palette.primary.main
              }} /></Tooltip>}
              <br />

              <FaceVeryUnhappy
                activeValue={parseInt(newEatMoment.feeling?.toString() || '-1', 10)}
                onClick={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    feeling: e
                  });
                }}
              />
              <FaceUnhappy
                activeValue={newEatMoment.feeling || -1}
                onClick={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    feeling: e || undefined
                  });
                }}
              />
              <FaceHappy
                activeValue={newEatMoment.feeling || -1}
                onClick={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    feeling: e || undefined
                  });
                }}
              />
              <FaceVeryHappy
                activeValue={newEatMoment.feeling || -1}
                onClick={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    feeling: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('comment')}>
              <TextInput
                id="comment"
                helperIcon={checkHelperIcon('comment')}
                placeholder={t("client.models.eat_moments.placeholders.comment", "")}
                caption={t("client.models.eat_moments.comment", "Extra opmerkingen over het beweegmoment")}
                autoComplete="comment"
                autoFocus={false}
                multiline
                backend
                greyBg
                value={newEatMoment.comment || ''}
                onChange={(e) => {
                  setNewEatMoment({
                    ...newEatMoment,
                    comment: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} hidden={!checkFieldValidity('food_entries')}>
              <Divider />
            </Grid>

            <Grid item xs={12} hidden={!checkFieldValidity('food_entries')}>
              <Typography variant="h5">{t("client.models.food_entries.title", "Gegevens over de voeding")}</Typography>
              {!!checkHelperIcon('hunger') && <Tooltip title={checkHelperIcon('food_entries')}><HelpIcon sx={{
                float: 'right',
                color: theme.palette.primary.main,
                marginTop: -3
              }} /></Tooltip>}
            </Grid>

            <Grid container spacing={1} item xs={12} hidden={!checkFieldValidity('food_entries')}>
              <Grid container spacing={1} item xs={12} md={4} sx={{
                borderBottom: {
                  md: 'none',
                  xs: '1px solid transparent'
                },
                borderRight: {
                  xs: 'none',
                  md: '1px solid transparent'
                },
                borderColor: {
                  xs: theme.palette.primary.main,
                  md: theme.palette.primary.main
                },
                paddingRight: {
                  xs: 0,
                  md: 1
                },
                paddingBottom: {
                  md: 0,
                  xs: 1
                }
              }}>
                <Grid item xs={12}>
                  <TextInput
                    id="entry_entry"
                    placeholder={t("client.models.food_entries.placeholders.entry", "1 boterham met kaas")}
                    caption={t("client.models.food_entries.entry", "Voedingsmiddel")}
                    backend
                    greyBg
                    value={newFoodEntry.entry || ''}
                    onChange={(e) => {
                      setNewFoodEntry({
                        ...newFoodEntry,
                        entry: e || ''
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="entry_comment"
                    placeholder={t("client.models.food_entries.placeholders.comment", "")}
                    caption={t("client.models.food_entries.comment", "Opmerkingen")}
                    backend
                    multiline
                    greyBg
                    value={newFoodEntry.comment || ''}
                    onChange={(e) => {
                      setNewFoodEntry({
                        ...newFoodEntry,
                        comment: e || ''
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileUpload
                    caption={t("client.models.food_entries.item", "Afbeelding")}
                    fileInputName='eatmoment_foodentry_item'
                    food
                    selected={newFoodEntry.item}
                    onChange={(f) => {
                      setNewFoodEntry({
                        ...newFoodEntry,
                        item: f as File
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="add_fe_btn"
                    contained
                    disabled={!newFoodEntry.entry}
                    onClick={() => {
                      if (!!newFoodEntry.entry) {
                        if (typeof(editing) !== 'undefined') {
                          let newFoodEntries = [...(newEatMoment.food_entries || [])];
                          newFoodEntries[editing] = newFoodEntry;
                          setNewEatMoment({
                            ...newEatMoment,
                            food_entries: newFoodEntries
                          });
                        } else {
                          setNewEatMoment({
                            ...newEatMoment,
                            food_entries: [
                              ...(newEatMoment.food_entries || []),
                              newFoodEntry
                            ]
                          });
                        }
                        setNewFoodEntry({entry: '', item: undefined});
                        setEditing(undefined);
                      }
                    }}
                    label={t((typeof(editing) !== 'undefined') ? "client.general.actions.edit" : "client.general.actions.add")}
                  />
                  <Button
                    id="empty_fe_btn"
                    text
                    onClick={() => {
                      setNewFoodEntry({entry: '', item: undefined});
                      setEditing(undefined);
                    }}
                    label={t("client.general.actions.cancel")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                {(newEatMoment.food_entries || []).length < 1 && <Typography sx={{fontStyle: 'italic', textAlign: 'center'}}>{t("client.models.eat_moments.food_entries_error", "Er is minstens 1 voedingslijn nodig")}</Typography>}
                {(newEatMoment.food_entries || []).length > 0 && <Box sx={{
                  maxHeight: '380px',
                  overflow: 'auto'
                }}><List>
                  {(newEatMoment.food_entries || []).map((entry, i) => <ListItem>
                    <ListItemIcon>
                      <Avatar src={!!entry.get_item ? entry.get_item : !!entry.item ? URL.createObjectURL(entry.item) : undefined}><BrunchDiningIcon /></Avatar>
                    </ListItemIcon>
                    <ListItemText primary={entry.entry} secondary={entry.comment} />
                    <ListItemSecondaryAction>
                      <Tooltip title={"Edit"}>
                        <IconButton onClick={(e) => {
                          e.preventDefault();
                          // @ts-ignore
                          e.currentTarget.focus();
                          setNewFoodEntry(entry);
                          setEditing(i);
                        }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Delete"}>
                        <IconButton onClick={(e) => {
                          e.preventDefault();
                          // @ts-ignore
                          e.currentTarget.focus();

                          let newFoodEntries = [...(newEatMoment.food_entries || [])];
                          newFoodEntries.splice(i, 1);
                          setNewEatMoment({
                            ...newEatMoment,
                            food_entries: newFoodEntries
                          });
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>)}
                </List></Box>}
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setNewEatMoment({feeling: -1, hunger: -1});
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onClick}
          color="primary"
        >{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
