import React, { ElementType, FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import {
  Button,
  Tooltip,
} from '@mui/material';

type Props = {
  label: string;
  value: number;
  activeValue: number;
  onClick: (value: number) => void;
  Icon: ElementType;
};

export const Base: FunctionComponent<Props> = ({label, value, activeValue, onClick, Icon}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  
  return (<>
    <Tooltip title={label}>
      <Button variant="outlined" onClick={(e) => {
        e.preventDefault();
        onClick(value);
      }} sx={{
        marginTop: 1,
        padding: 2,
        marginRight: 1,
        ...(activeValue === value ? {
          color: 'black',
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            color: 'black',
            backgroundColor: theme.palette.primary.main
          }
        } : {})
      }}>
        <Icon fontSize="large" />
      </Button>
    </Tooltip>
  </>);
}