import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import { useMutation } from '@tanstack/react-query';

// import { loginAtom } from '../../../lib/auth';
// import { fetch_one } from "../../../lib/v31lib";

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import {
  Avatar,
  CardContent,
  Grid,
  Rating,
} from '@mui/material';

import {
  // Dt,
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';

import { QuestionnaireResult } from '../../../models/QuestionnaireResult';

const { DateTime } = require("luxon");

type Props = {
  questionnaireResult: QuestionnaireResult;
  sx?: any;
};

export const CardReview: FunctionComponent<Props> = ({questionnaireResult, sx}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  // const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [comment, setComment] = useState<string>('');
  const [npsScore, setNpsScore] = useState<number>(-1);
  const [userName, setUserName] = useState<string>('');
  const [userItem, setUserItem] = useState<string|undefined>();








  useEffect(() => {
    if (!!questionnaireResult) {
      let nps_index = -1;
      let comment_index = -1;
      (questionnaireResult.extra_data?.questionnaire?.questions || []).forEach((question, i) => {
        if (question.type === "open_long") comment_index = i;
        if (question.type === "nps") nps_index = i;
      });

      if (comment_index > -1) setComment((questionnaireResult.extra_data?.answers || [])[comment_index]);
      if (nps_index > -1) setNpsScore(((questionnaireResult.extra_data?.answers || [])[nps_index] || 0) / ((((questionnaireResult.extra_data?.questionnaire?.questions || [])[nps_index] || {}).max || 10) / 5));
      setUserName(questionnaireResult.privacy_user?.name || "Bloem Kool");
      setUserItem(questionnaireResult.privacy_user?.item);
    }
  }, [questionnaireResult]);

  if (!comment && npsScore < 0) return <></>;

  return (<CardBase sx={sx}>
    <CardContent style={{marginBottom: 'auto'}}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          {!userItem && <Avatar sx={{
            width: 60,
            height: 60,
            backgroundColor: theme.palette.primary.light
          }}>
            <PersonOutlineIcon color="primary" sx={{fontSize: '3rem'}} />
          </Avatar>}
          {!!userItem && <Avatar sx={{
            width: 60,
            height: 60,
            backgroundColor: theme.palette.primary.light
          }} src={userItem} />}
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography sx={{marginLeft: 0.5}}>{userName}</Typography>
          <Typography sx={{marginLeft: 0.5, fontSize: '0.8rem', fontStyle: 'italic'}}>{DateTime.fromISO(questionnaireResult?.updated_at).toRelative({ locale: i18n.resolvedLanguage || 'nl' })}</Typography>
          {npsScore > -1 && <Typography sx={{marginTop: 1}}>
            <Rating name="half-rating-read" defaultValue={npsScore} precision={0.5} readOnly />
          </Typography>}
        </Grid>
        {!!comment && <Grid item xs={12} sx={{marginTop: 1}}>
          <Typography sx={{fontStyle: 'italic'}}>{comment}</Typography>
        </Grid>}
      </Grid>
    </CardContent>
  </CardBase>);
}