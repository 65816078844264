import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Grid
} from '@mui/material';

import {
  FileDrop,
  TextInput,
  Typography
} from '../styled';

type ImagePickerProps = {
  sx?: object;
  existing?: string;
  onSelect: (e:File|undefined, f:string|undefined) => void;
  item?: File;
  itemUrl?: string;
  backend?: boolean;
  deleteable?: boolean;
};

export const ImagePicker: FunctionComponent<ImagePickerProps> = ({existing, backend, sx, onSelect, item, itemUrl, deleteable}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const [itemm, setItem] = useState<File|undefined>();
  const [assetUrl, setAssetUrl] = useState<string>();
  const [itemmUrl, setItemUrl] = useState<string|undefined>();
  const [cleared, setCleared] = useState<boolean>(false);

  useEffect(() => {
    setItem(item);
  }, [item]);
  useEffect(() => {
    setItemUrl(itemUrl);
  }, [itemUrl]);
  useEffect(() => {
    if (item !== itemm || itemUrl !== itemmUrl) onSelect(itemm, assetUrl || itemmUrl || undefined);
  }, [itemm, assetUrl, itemmUrl]);

  return (<Grid container item xs={12} sx={{padding: 0.5, ...(!!backend ? {backgroundColor: 'white'} : {})}}>
    <Grid item xs={3} sx={{paddingBottom: 0.5}}>
      <FileDrop
        acceptedFiles={{
          'image/*': []
        }}
        onDrop={acceptedFiles => {setItem(acceptedFiles[0]); setCleared(false);}}
        multiple={false}
        maxFiles={1}
        maxSize={3 * 1000 * 1000}
        items={!!itemm ? [itemm] : []}
        dropText="Click/Drop"
        dropHint="All.: 1 img, < 3mb"
        noPreview
      />
    </Grid>
    <Grid container item xs={6} sx={{paddingLeft: 0.5, paddingRight: 0.5, alignContent: 'baseline'}}>
      <Grid item xs={12}>
        <TextInput
          caption="Image Source URL"
          value={itemmUrl || ''}
          id="web_url"
          onChange={(v) => {setItemUrl(v); setCleared(false);}}
        />
      </Grid>
      {!!deleteable && <Grid item xs={12}>
        <Typography sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: theme.palette.primary.main,
          fontStyle: 'italic',
          fontSize: '0.8rem'
        }} onClick={() => {
          // console.log('clear image');
          setItem(undefined);
          setItemUrl(undefined);
          setCleared(true);
        }}>{t("general.terms.clear_image", "Afbeelding verwijderen")}</Typography>
      </Grid>}
    </Grid>
    <Grid item xs={3} sx={{paddingBottom: 0.5}}>
      <Box sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        ...(!!item ? {backgroundImage: `url('${URL.createObjectURL(item)}')`} : !!assetUrl ? {backgroundImage: `url('${assetUrl}')`} : !!itemUrl ? {backgroundImage: `url('${itemUrl}')`} : !!existing && !cleared ? {backgroundImage: `url('${existing}')`} : {}),
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }}></Box>
    </Grid>
  </Grid>);
}