import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';

// import {
//   Avatar,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  IconButton,
  Page
} from '../../../components/v2/styled';

import { QuestionnaireResult } from '../../../models/QuestionnaireResult';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientQuestionnaires: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["client", "questionnaire_resuls", login?.id, {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire_result',
          fields: ['id', 'questionnaire', 'practice', 'completed_at', 'invited_at', 'is_locked', 'url'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login?.id,
  });









  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'questionnaire_name', headerName: t("coach.models.questionnaires.name"), width: 200 },
    { field: 'practice_name', headerName: t("coach.models.questionnaires.practice_name"), width: 200 },
    { field: 'invited_at', headerName: t("coach.models.questionnaire_results.invited_at", "Invited"), width: 250, renderCell: params => <>
      {!!params.row.invited_at && <Dt d={params.row.invited_at} f="DDD t" />}
    </> },
    { field: 'completed_at', headerName: t("coach.models.questionnaire_results.completed_at", "Completed"), width: 250, renderCell: params => <>
      {!!params.row.completed_at && <Dt d={params.row.completed_at} f="DDD t" />} {!!params.row.is_locked && <LockIcon sx={{marginBottom: -1, color: 'rgba(0, 0, 0, 0.4)'}} />}
    </> },
  ];

  return <Page
    title={t("client.menu.questionnaires_long", "Vragenlijsten")}
  >
    <DataGrid
      action_count={1}
      // hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" onClick={() => {
            window.location.href = params.row.url;
          }}>
            {!params.row.is_locked && <EditIcon />}
            {!!params.row.is_locked && <VisibilityIcon />}
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        questionnaire_name: u.questionnaire?.name,
        practice_name: u.practice?.name
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />
  </Page>;
}
