import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';

import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TabletIcon from '@mui/icons-material/Tablet';

import {
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';

import {
  Dt,
  IconButton as IconBtn,
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';

import { Appointment } from '../../../models/Appointment';

import HelpersPayments from '../../../actions/helpers/payments';
import HelpersVideoconferences from '../../../actions/helpers/videoconferences';

type Props = {
  appointment: Appointment;
  extensive?: boolean;
  actions?: boolean;
  onEdit?: (a:Appointment) => void;
  onDelete?: (a:Appointment) => void;
  isCoach?: boolean;
  deleteIsDestroy?: boolean;
};

export const CardAppointment: FunctionComponent<Props> = ({deleteIsDestroy, isCoach, appointment, extensive, actions, onEdit, onDelete}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [open, setOpen] = useState<boolean>(false);

  return (<CardBase>
    {!appointment.coach?.enabled && <CardHeader
      disableTypography
      sx={{backgroundColor: "orange"}}
      title={<Typography variant="subtitle1" sx={{
        textAlign: 'justify',
        color: theme.palette.secondary.main,
        marginBottom: 0
      }}>{t("client.appointments.error_coach_diabled_title")}</Typography>}
      subheader={<Typography sx={{
        fontStyle: "italic",
        fontSize: "0.8em"
      }}>{t("client.appointments.consent_coach_diabled_warning")}</Typography>}
    />}
    {!appointment.has_consent && <CardHeader
      disableTypography
      sx={{backgroundColor: "orange"}}
      title={<Typography variant="subtitle1" sx={{
        textAlign: 'justify',
        color: theme.palette.secondary.main,
        marginBottom: 0
      }}>{t("client.appointments.error_title")}</Typography>}
      subheader={<Typography sx={{
        fontStyle: "italic",
        fontSize: "0.8em"
      }}>{t("client.appointments.consent_warning")}</Typography>}
    />}

    {!appointment.location?.settings?.is_background_colour && appointment.location?.get_medium && <CardMedia
      sx={{
        height: 140,
        backgroundPositionY: '40%'
      }}
      image={appointment.location.get_medium}
      title={appointment.location.name}
    />}
    {(!!appointment.location?.settings?.is_background_colour || !appointment.location?.get_medium) && <CardMedia
      sx={{
        height: 140,
        backgroundPositionY: '40%',
        backgroundColor: (appointment.location?.settings?.background_colour || 'lightgray')
      }}
      title={appointment.location?.name}
    />}

    <CardContent style={{marginTop: '-100px', marginBottom: 'auto'}}>
      <Avatar sx={{width: 100, height: 100}} src={appointment.coach?.get_medium} />
      <Typography sx={{
        color: theme.palette.tertiary?.main,
        marginBottom: 2
      }}>{appointment.practice?.name}</Typography>

      <Divider sx={{marginTop: 1, marginBottom: 1}} />

      {HelpersPayments.can_test_payments(login?.id) && !!appointment.payment && !!appointment.payment.id && <>
        {appointment.payment.paid && <Tooltip title={t("client.payments.appointment.paid", "Deze afspraak werd reeds betaald")}>
          <EuroIcon sx={{
            marginTop: '-21px',
            float: 'right',
            backgroundColor: 'white',
            color: theme.palette.primary.main
          }} />
        </Tooltip>}
        {!appointment.payment.paid && <Tooltip title={t("client.payments.appointment.unpaid", "Deze afspraak werd nog niet betaald")}>
          <EuroIcon sx={{
            marginTop: '-21px',
            float: 'right',
            backgroundColor: 'white',
            color: 'lightgrey'
          }} />
        </Tooltip>}
      </>}
      <Typography variant="h2" sx={{textAlign: 'center', fontSize: '1.5rem', width: '80%', marginLeft: '10%'}}><Dt d={appointment.start_time} f="cccc" /></Typography>
      <Typography variant="h2" sx={{textAlign: 'center'}}><Dt d={appointment.start_time} f="DDD" /></Typography>
      <Typography variant="h2" sx={{textAlign: 'center'}}><Dt d={appointment.start_time} f="t" /></Typography>
      {/* {this.state.diff_tz && <Typography variant="subtitle1" component="h2" align="center" style={{fontSize: '0.8em'}}>{moment(appointment.attributes.start_time).tz(this.state.tz).format("LT")} ({this.state.tz})</Typography>} */}
    </CardContent>

    <CardActions>
      {actions && <>
        <Tooltip title={t("client.general.actions.cancel", "Annuleer")}>
          <IconBtn destroyBtn={!!deleteIsDestroy} aria-label="delete" onClick={(e) => {
            if (!!onDelete) onDelete(appointment);
          }}>
            <Delete />
          </IconBtn>
        </Tooltip>

        <Tooltip title={t("client.general.actions.edit", "Wijzig")}>
          <IconButton aria-label="edit" onClick={(e) => {
            if (!!onEdit) onEdit(appointment);
          }}>
            <Edit />
          </IconButton>
        </Tooltip>

        {!isCoach && HelpersVideoconferences.can_test_video(login?.id) && appointment.can_meeting && <Tooltip title={t("client.general.actions.meeting", "Videocall")}>
          <IconButton aria-label="meeting" onClick={(e) => {
            e.preventDefault();
            navigate("/meeting", {state: {id: appointment.id, appointment: appointment}});
          }}>
            <TabletIcon />
          </IconButton>
        </Tooltip>}

        {!isCoach && HelpersPayments.can_test_payments(login?.id) && appointment.payment && appointment.payment.id && !appointment.payment.paid && <Tooltip title={t("client.general.actions.pay_appointment", "Afspraak betalen")}>
          <IconButton aria-label="pay" onClick={(e) => {
            window.open(`https://nutripay.eu/${appointment.payment?.token}`, '_blank');
          }}>
            <EuroIcon />
          </IconButton>
        </Tooltip>}
      </>}

      {extensive && <IconButton onClick={(e) => {
        setOpen(!open);
      }} sx={{marginLeft: 'auto !important'}}>
        {open ? <ExpandLess /> : <ExpandMore />}
      </IconButton>}
    </CardActions>

    {extensive && <Collapse in={open} timeout="auto" unmountOnExit sx={{padding: 1}}>
      <Typography sx={{
        fontStyle: "italic",
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        textAlign: 'left'
      }}>{t("client.appointments.coach", "Coach")}</Typography>
      <Typography variant="body2">
        {appointment.coach?.full_name}
      </Typography>
      <Divider sx={{
        marginTop: 1,
        marginBottom: 1
      }}></Divider>

      <Typography sx={{
        fontStyle: "italic",
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        textAlign: 'left'
      }}>{t("client.appointments.type", "Afspraaktype")}</Typography>
      <Typography variant="body2">
        {appointment.appointment_type?.name}
      </Typography>
      <Divider sx={{
        marginTop: 1,
        marginBottom: 1
      }}></Divider>

      <Typography sx={{
        fontStyle: "italic",
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        textAlign: 'left'
      }}>{t("client.appointments.location", "Locatie")}</Typography>
      <Typography variant="body2">
        {appointment.location?.is_online && <>
          {t("client.appointment.online_location", "Online consultatie")}
        </>}
        {!appointment.location?.is_online && <>
          {appointment.location?.street} {appointment.location?.number}<br />
          {appointment.location?.zip} {appointment.location?.city}
        </>}
      </Typography>

      {appointment.comment && appointment.comment.trim().length > 0 && <>
        <Divider sx={{
          marginTop: 1,
          marginBottom: 1
        }}></Divider>
        <Typography sx={{
          fontStyle: "italic",
          fontSize: '0.8rem',
          color: theme.palette.primary.main,
          textAlign: 'left'
        }}>{t("client.appointments.note", "Opmerking")}</Typography>
        <Typography variant="body2">
          {appointment.comment}
        </Typography>
      </>}

      {isCoach && appointment.comment_coach && appointment.comment_coach.trim().length > 0 && <>
        <Divider sx={{
          marginTop: 1,
          marginBottom: 1
        }}></Divider>
        <Typography sx={{
          fontStyle: "italic",
          fontSize: '0.8rem',
          color: theme.palette.primary.main,
          textAlign: 'left'
        }}>{t("coach.models.appointments.note_coach", "Opmerking coach")}</Typography>
        <Typography variant="body2">
          {appointment.comment_coach}
        </Typography>
      </>}
    </Collapse>}
  </CardBase>);
}