import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { loginAtom } from '../../../lib/auth';
import { fetch_one } from "../../../lib/v31lib";

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';

import {
  Avatar,
  Button as Btn,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
} from '@mui/material';

import {
  Dt,
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';
import { WorkshopDetail } from '../dialogs/WorkshopDetail';

import { Workshop } from '../../../models/Workshop';

type Props = {
  workshop: Workshop;
  refetch: () => void;
  sx?: any;
};

export const CardWorkshopBetter: FunctionComponent<Props> = ({workshop, refetch, sx}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [open, setOpen] = useState<boolean>(false);









  const mutationChangeSubscription = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      refetch();
      // setConfirmOpen(false);
      // setOpen(false);
    },
  });









  return (<CardBase sx={sx}>
    {(workshop.get_subscriber_ids || []).indexOf(login?.id || -1) > -1 && <CardHeader
      sx={{
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center'
      }}
      title={t("client.workshops.subscribed")}
    />}
    {(workshop.get_backup_ids || []).indexOf(login?.id || -1) > -1 && <CardHeader
      sx={{
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center'
      }}
      title={t("client.workshops.subscribed_backup")}
    />}
    <CardMedia
      sx={{
        height: 100,
        backgroundPositionY: '40%',
        backgroundColor: (!!workshop.is_background_colour ? workshop.background_colour : 'transparent')
      }}
      image={!workshop.is_background_colour ? workshop.get_bg_medium : undefined}
      title={workshop.subject}
    />
    <CardContent style={{marginTop: '-100px', marginBottom: 'auto'}}>
      {!!workshop.get_medium && <Avatar sx={{width: 100, height: 100}} src={workshop.get_medium} />}

      <Typography sx={{marginTop: 1, fontWeight: 'bold'}}>{workshop.subject}</Typography>

      {!!workshop.teaser && <Typography sx={{marginTop: 1}}>{workshop.teaser}</Typography>}
      {!!workshop.teaser && <Divider sx={{marginTop: 1}} />}

      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem', marginTop: 2}}>{t("coach.models.workshops.start_time")}</Typography>
      <Typography><Dt d={workshop.start_time} f="ccc DD t" /></Typography>
      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem', marginTop: 1}}>{t("coach.models.workshops.end_time")}</Typography>
      <Typography><Dt d={workshop.end_time} f="ccc DD t" /></Typography>

      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem', marginTop: 2}}>{t("client.workshops.location")} </Typography>
      {!!workshop.location?.id && <>
        <Typography>{workshop.location.name}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location.street, workshop.location.number].join(' ')}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location.zip, workshop.location.city].join(' ')}</Typography>
      </>}
      {!workshop.location?.id &&<>
        <Typography>{workshop.location_name}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location_street, workshop.location_number].join(' ')}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location_zip, workshop.location_city].join(' ')}</Typography>
      </>}
      {!!workshop.location_lat && workshop.location_lng && <Btn
        size="small"
        startIcon={<MyLocationIcon />}
        onClick={() => {
          window.open(`https://www.google.com/maps/search/?api=1&query=${workshop.location_lat},${workshop.location_lng}`, "_BLANK");
        }}
        variant="outlined"
        sx={{
          paddingTop: 0.2,
          paddingBottom: 0.2
        }}
      >{t("general.open_in_gmaps")}</Btn>}

      {!!open && <WorkshopDetail
        open={open}
        setOpen={setOpen}
        workshop={workshop}
        refetch={refetch}
      />}
    </CardContent>
    <CardActions>
      {(workshop.get_subscriber_ids || []).indexOf(login?.id || -1) > -1 && <Btn
        onClick={() => {
          mutationChangeSubscription.mutate({
            formData: {
              object: 'workshop',
              class_action: 'custom_api_change_subscription',
              id: workshop.id,
              user_id: login?.id,
              sub: 0
            }
          });
        }}
        startIcon={<PersonRemoveAlt1Icon />}
      >{t("client.general.actions.workshop_unsubscribe")}</Btn>}
      {(workshop.get_subscriber_ids || []).indexOf(login?.id || -1) < 0 && <>
        {(workshop.get_backup_ids || []).indexOf(login?.id || -1) > -1 && <Btn
          onClick={() => {
            mutationChangeSubscription.mutate({
              formData: {
                object: 'workshop',
                class_action: 'custom_api_change_subscription',
                id: workshop.id,
                user_id: login?.id,
                sub: 0
              }
            });
          }}
          startIcon={<PersonRemoveAlt1Icon />}
        >{t("client.general.actions.workshop_unsubscribe")}</Btn>}
        {(workshop.get_backup_ids || []).indexOf(login?.id || -1) < 0 && <>
          {!!login?.id && (workshop.max_subscribers || 0) > (workshop.get_subscriber_ids || []).length && <Btn
            onClick={() => {
              mutationChangeSubscription.mutate({
                formData: {
                  object: 'workshop',
                  class_action: 'custom_api_change_subscription',
                  id: workshop.id,
                  user_id: login?.id,
                  sub: 1
                }
              });
            }}
            startIcon={<PersonAddAltIcon />}
          >{t("client.general.actions.workshop_subscribe")}</Btn>}
          {!!login?.id && (workshop.max_subscribers || 0) <= (workshop.get_subscriber_ids || []).length && (workshop.max_backups || 0) > (workshop.get_backup_ids || []).length && <Btn
            onClick={() => {
              mutationChangeSubscription.mutate({
                formData: {
                  object: 'workshop',
                  class_action: 'custom_api_change_subscription',
                  id: workshop.id,
                  user_id: login?.id,
                  sub: 1
                }
              });
            }}
            startIcon={<PersonAddAltIcon />}
          >{t("client.general.actions.workshop_subscribe")}</Btn>}
        </>}
      </>}

      <Btn
        onClick={() => {
          setOpen(true);
        }}
      >{t("coach.submenu.agenda.appointments.detail")}</Btn>
    </CardActions>
  </CardBase>);
}