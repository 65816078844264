import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';

import { fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import {
  Grid,
  MenuItem
} from '@mui/material';

import {
  Select,
  TextInput,
  Typography
} from './styled';

import { User } from '../../models/User';

type Props = {
  clientId?: number;
  setClientId: (n?:number) => void;
  fields?: string[];
  backend?: boolean;
  ignoreIds?: number[];
};

export const ClientPicker: FunctionComponent<Props> = ({clientId, setClientId, fields, backend, ignoreIds}) => {
  // const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);
  const [clientSearch, setClientSearch] = useState<string>('');

  const {
    data: clients,
    isLoading: clientsLoading,
    isError: clientsError,
    isSuccess: clientsSuccess,
    // refetch: clientsRefetch,
  } = useQuery({
    queryKey: ["clients", "with_email", currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'email', ...(fields || [])],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: clientSearch,
            advanced: {
              for_practice: (login?.practice_id || currentPractice?.id),
              // enabled: '1'
            }
          }
        },
        login
      ),
    select: (d) => {
      if (!!ignoreIds) {
        return d.filter(fff => (ignoreIds || []).indexOf(fff.id || 0) < 0);
      } else {
        return d;
      }
    },
    enabled: !!currentPractice?.id
  });









  return (<>
    <Grid item xs={12} md={6}>
      {/* <Typography sx={{
        textDecoration: 'underline'
      }}>{t("coach.agenda.appointment.client_exists")}</Typography> */}
      <TextInput
        id="client_search"
        caption={t("coach.models.appointments.client_search", "Zoek client")}
        value={clientSearch}
        backend={backend}
        onChange={(e) => {
          setClientSearch(e);
        }}
      />
      <Typography sx={{fontSize: '0.7rem', fontStyle: 'italic', marginBottom: 1}}>{(clients || []).length} {t("general.results_found")}</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      {/* <Typography sx={{
        // textDecoration: 'underline'
      }}>&nbsp;</Typography> */}
      <Select
        fullWidth
        caption={t("coach.models.appointments.client", "Client")}
        starred
        backend={backend}
        displayEmpty
        id="user_id"
        value={clientId || ''}
        setValue={(v) => {
          setClientId(!!v ? parseInt(v.toString(), 10) : undefined);
        }}
      >
        <MenuItem value="">--- ---</MenuItem>
        {(clients || []).map(client => <MenuItem value={client.id}>{client.full_name}&nbsp;&nbsp;&nbsp;<span style={{fontSize: '0.8rem'}}>({client.email})</span></MenuItem>)}
      </Select>
    </Grid>
  </>);
}
