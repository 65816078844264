import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  IconButton,
  Page,
  Typography,
} from '../../components/v2/styled';

// import { Import } from '../../models/Import';

const { DateTime } = require("luxon");

type Props = {}

export const AdminSmsDashboard: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  // const [savedOpen, setSavedOpen] = useState<boolean>(false);
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "sms_payments", {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<{id: number; extra_data: any; get_item: string; created_at: string;}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'sms_payment',
          fields: ['id', 'extra_data', 'get_item', 'created_at'],
          order: order,
          per_page: 20,
          page: page,
          // filter: {
          //   search: '',
          //   advanced: {
          //     practice_id: (login?.practice_id || currentPractice?.id)
          //   }
          // }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });
  const {
    data: smsStats,
    // isLoading: practicesLoading,
    // isError: practicesError,
    // isSuccess: practicesSuccess,
    // refetch: practicesRefetch,
  } = useQuery({
    queryKey: ["admin", 'sms_stats'],
    queryFn: () =>
      fetch_one<{custom_result: any}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'sms_payment',
          class_action: 'custom_api_stats'
        },
        login
      ),
    enabled: !!login,
  });








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
    },
  });







  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'created_at', headerName: t('admin.models.sms_payments.created_at', "Aangemaakt"), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'quarter', headerName: t('admin.models.sms_payments.quarter', "Kwartaal"), width: 200 },
  ];
  // @ts-ignore
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(active, payload, label);
      let pyl = payload[0];
      return (
        <Paper sx={{padding: 1}}>
          <Typography>{`${pyl.name} : ${pyl.value}`}</Typography>
          <Divider />
          {Object.values(pyl.payload.detail || {}).map((detail:any) => <Typography sx={{paddingLeft: 1}}>{`${detail.info} : ${detail.count}`}</Typography>)}
        </Paper>
      );
    }
  
    return null;
  };

  return <Page title={t("admin.menu.sms", "SMS")}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{textAlign: 'center'}}>{t('admin.models.sms_payments.last_quarter', "Laatste kwartaal")}</Typography>
        <Typography variant="h5" sx={{textAlign: 'center'}}>Up to {DateTime.now().endOf('quarter').toFormat("LLL y").toUpperCase()}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {!!smsStats?.custom_result?.last_payment?.extra_data?.detail && <Box sx={{width: '100%', height: '300px'}}>
          <ResponsiveContainer>
            <PieChart width={400} height={300}>
              <Pie
                data={Object.values(smsStats?.custom_result?.last_payment?.extra_data?.detail || {}).sort((a:any, b:any) => (a.count || 0) < (b.count || 0) ? -1 : 1)}
                dataKey="count"
                nameKey="info"
                cx="50%"
                cy="50%"
                outerRadius={90}
                fill="#8884d8"
                labelLine={false}
              >
                {/* {genderStats.custom_result.data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={genderColours[index % genderColours.length]} />
                ))} */}
              </Pie>
              {/* @ts-ignore */}
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </Box>}
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5">{t("admin.models.sms_payments.numbers", "Cijfers")}</Typography>
            <Divider />

            <Typography variant="caption" sx={{color: theme.palette.primary.main}}>{t("admin.models.sms_payments.totals_total", "Laatste kwartaal")}</Typography><br />
            <Typography>{Object.values(smsStats?.custom_result?.last_payment?.extra_data?.detail || {}).map((mm:any) => mm.count).reduce((total, count) => total + count, 0)}</Typography>
            <Divider />

            <Typography variant="caption" sx={{color: theme.palette.primary.main}}>{t("admin.models.sms_payments.totals_open", "Huidig kwartaal")}</Typography><br />
            <Typography>{smsStats?.custom_result?.open}</Typography>
            <Divider />

            <Typography variant="caption" sx={{color: theme.palette.primary.main}}>{t("admin.models.sms_payments.totals_practices", "Laatste kwartaal - praktijken")}</Typography><br />
            <Typography>{Object.values(smsStats?.custom_result?.last_payment?.extra_data?.detail || {}).length}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>


    <DataGrid
      action_count={1}
      actions={(params) => {
        return (<>
          {!!params.row.get_item && <IconButton title="Download" color="default" onClick={() => {
            window.open(params.row.get_item, "_BLANK");
          }}>
            <CloudDownloadIcon />
          </IconButton>}
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        quarter: `up to ${DateTime.fromISO(u.created_at).endOf('quarter').minus({quarter: 1}).toFormat("LLL y").toUpperCase()}`
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />
  </Page>;
}
