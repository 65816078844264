import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useParams, useNavigate } from 'react-router-dom';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup
} from '@mui/material';

import {
  Button,
  Page,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { LanguagePicker } from '../../../components/v2/LanguagePicker';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { QuestionnaireResult } from '../../../models/QuestionnaireResult';

const { DateTime } = require("luxon");

type Props = {};

export const ClientQuestionnaireEdit: FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();

  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<QuestionnaireResult>();

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ['client', "questionnaire_result", objectId],
    queryFn: () =>
      fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'questionnaire_result',
          id: objectId,
          fields: [
            'id', 'extra_data', 'practice', 'questionnaire', 'completed_at', 'is_locked'
          ],
          sub_fields: {
            practice: ['id', 'name'],
            questionnaire: ['id', 'name', 'extra_data', 'content_type']
          },
        }
      ),
    enabled: !!objectId,
  });








  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/save`,
        pars.formData
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
    },
  });







  

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);

  return (<Page>
    <Paper sx={{
      padding: 2
    }}>
      <Grid container spacing={1}>
        {!savedOpen && <>
          <Grid item xs={12} sx={{position: 'relative'}}>
            <Typography variant="h1">{mainObject?.questionnaire?.name}</Typography>
            <Typography variant="h5">{mainObject?.practice?.name}</Typography>
            <Divider />

            <LanguagePicker sx={{position: 'absolute', top: '0', right: '0'}} />
          </Grid>
          {!!((mainObject?.extra_data?.questionnaire?.intro || {})[i18n.resolvedLanguage || 'nl'] || (mainObject?.extra_data?.questionnaire?.intro || {})['nl']) && <Grid item xs={12}>
            <Typography>{(mainObject?.extra_data?.questionnaire?.intro || {})[i18n.resolvedLanguage || 'nl'] || (mainObject?.extra_data?.questionnaire?.intro || {})['nl']}</Typography>
          </Grid>}

          {!!mainObject?.questionnaire && <>
            {mainObject?.questionnaire?.content_type === 'review' && <>
              <Grid item xs={12} sx={{
                marginTop: 3
              }}>
                <Box sx={{
                  marginTop: 2,
                  padding: 2,
                  border: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  backgroundImage: `linear-gradient(to right, transparent, ${theme.palette.primary.main})`,
                  borderRadius: 2
                }}>
                  <Typography variant="h2">{t("client.questionnaire_result.review.privacy_title")}</Typography>
                  <Typography variant="caption">{t("client.questionnaire_result.review.privacy_description")}</Typography>
                  <Divider />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{
                marginTop: 3,
                paddingLeft: "32px !important"
              }}>
                <Typography variant="h4">{t("client.questionnaire_result.review.setting.is_anon")}</Typography>
                <Typography variant="caption">{t("client.questionnaire_result.review.setting.is_anon_description")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{
                paddingLeft: "32px !important"
              }}>
                <FormControl>
                  <RadioGroup
                    name={`q_priv_0`}
                    value={mainObject?.extra_data?.privacy?.is_anon || '1'}
                    onChange={(e) => {
                      setMainObject({
                        ...mainObject,
                        extra_data: {
                          ...(mainObject.extra_data || {}),
                          privacy: {
                            ...(mainObject.extra_data?.privacy || {}),
                            is_anon: e.target.value
                          }
                        }
                      });
                    }}
                  >
                    <FormControlLabel value="0" control={<Radio />} label={t("client.questionnaire_result.review.setting.is_anon_option.be_known")} />
                    <FormControlLabel value="1" control={<Radio />} label={t("client.questionnaire_result.review.setting.is_anon_option.be_anon")} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>}


            {(mainObject?.extra_data?.questionnaire?.questions || []).map((question, i) => <>
              {!!question.section && <>
                <Grid item xs={12} sx={{
                  marginTop: 3
                }}>
                  <Box sx={{
                    marginTop: 2,
                    padding: 2,
                    border: '1px solid transparent',
                    borderColor: theme.palette.primary.main,
                    backgroundImage: `linear-gradient(to right, transparent, ${theme.palette.primary.main})`,
                    borderRadius: 2
                  }}>
                    {!!((question.section_title || {})[i18n.resolvedLanguage || 'nl'] || (question.section_title || {})['nl']) && <Typography variant="h2">{(question.section_title || {})[i18n.resolvedLanguage || 'nl'] || (question.section_title || {})['nl']}</Typography>}
                    {!!((question.section_content || {})[i18n.resolvedLanguage || 'nl'] || (question.section_content || {})['nl']) && <Typography variant="caption">{(question.section_content || {})[i18n.resolvedLanguage || 'nl'] || (question.section_content || {})['nl']}</Typography>}
                    <Divider />
                  </Box>
                </Grid>
              </>}
              {!question.section && <>
                <Grid item xs={12} sx={{
                  marginTop: 3,
                  paddingLeft: "32px !important"
                }}>
                  <Typography variant="h4">{(question.question || {})[i18n.resolvedLanguage || 'nl'] || (question.question || {})['nl']} {!!question.required ? "*" : ""}</Typography>
                  {!!((question.help || {})[i18n.resolvedLanguage || 'nl'] || (question.help || {})['nl']) && <Typography variant="caption">{(question.help || {})[i18n.resolvedLanguage || 'nl'] || (question.help || {})['nl']}</Typography>}
                  <Divider />
                </Grid>
                {!mainObject?.is_locked && <>
                  {question.type === 'existing' && <>
                    {[
                      'profile|first_name', 'profile|last_name', 'profile|email', 'profile|phone_mobile', 'profile|phone_fixed',
                      'intake|practician'
                    ].indexOf(question.existing_question_id || '') > -1 && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <TextInput
                        id={`q${i}`}
                        noCaption
                        multiline={[
                          'intake|practician'
                        ].indexOf(question.existing_question_id || '') > -1}
                        endIcon={((!!question.min && ((mainObject?.extra_data?.answers || [])[i] || '').length < question.min) || !!question.max && ((mainObject?.extra_data?.answers || [])[i] || '').length > question.max) ? <PriorityHighIcon color="secondary" /> : undefined}
                        value={(mainObject?.extra_data?.answers || [])[i] || ''}
                        onChange={(e) => {
                          let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                          newAnswers[i] = e;

                          setMainObject({
                            ...mainObject,
                            extra_data: {
                              ...(mainObject.extra_data || {}),
                              answers: newAnswers
                            }
                          });
                        }}
                        sx={{
                          marginTop: 0
                        }}
                      />
                    </Grid>}
                  </>}
                  {question.type !== 'existing' && <>
                    {question.type === 'open' && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <TextInput
                        id={`q${i}`}
                        noCaption
                        endIcon={((!!question.min && ((mainObject?.extra_data?.answers || [])[i] || '').length < question.min) || !!question.max && ((mainObject?.extra_data?.answers || [])[i] || '').length > question.max) ? <PriorityHighIcon color="secondary" /> : undefined}
                        value={(mainObject?.extra_data?.answers || [])[i] || ''}
                        onChange={(e) => {
                          let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                          newAnswers[i] = e;

                          setMainObject({
                            ...mainObject,
                            extra_data: {
                              ...(mainObject.extra_data || {}),
                              answers: newAnswers
                            }
                          });
                        }}
                        sx={{
                          marginTop: 0
                        }}
                      />
                      {!!question.max && <Typography>{question.max - ((mainObject?.extra_data?.answers || [])[i] || '').length} {t("general.terms.characters_left")}</Typography>}
                    </Grid>}
                    {question.type === 'open_long' && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <TextInput
                        id={`q${i}`}
                        noCaption
                        endIcon={((!!question.min && ((mainObject?.extra_data?.answers || [])[i] || '').length < question.min) || !!question.max && ((mainObject?.extra_data?.answers || [])[i] || '').length > question.max) ? <PriorityHighIcon color="secondary" /> : undefined}
                        multiline
                        value={(mainObject?.extra_data?.answers || [])[i] || ''}
                        onChange={(e) => {
                          let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                          newAnswers[i] = e;

                          setMainObject({
                            ...mainObject,
                            extra_data: {
                              ...(mainObject.extra_data || {}),
                              answers: newAnswers
                            }
                          });
                        }}
                        sx={{
                          marginTop: 0
                        }}
                      />
                      {!!question.max && <Typography>{question.max - ((mainObject?.extra_data?.answers || [])[i] || '').length} {t("general.terms.characters_left")}</Typography>}
                    </Grid>}
                    {question.type === 'single_radio' && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <FormControl>
                        <RadioGroup
                          name={`q${i}`}
                          value={(mainObject?.extra_data?.answers || [])[i] || ''}
                          onChange={(e) => {
                            let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                            newAnswers[i] = e.target.value;
      
                            setMainObject({
                              ...mainObject,
                              extra_data: {
                                ...(mainObject.extra_data || {}),
                                answers: newAnswers
                              }
                            });
                          }}
                        >
                          {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option => <FormControlLabel value={option} control={<Radio />} label={option} />)}
                        </RadioGroup>
                      </FormControl>
                    </Grid>}
                    {question.type === 'single_select' && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <Select
                        fullWidth
                        noCaption
                        displayEmpty
                        id="coach"
                        value={(mainObject?.extra_data?.answers || [])[i] || ''}
                        setValue={(v) => {
                          let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                          newAnswers[i] = v;

                          setMainObject({
                            ...mainObject,
                            extra_data: {
                              ...(mainObject.extra_data || {}),
                              answers: newAnswers
                            }
                          });
                        }}
                      >
                        <MenuItem value="">--- ---</MenuItem>
                        {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option =>  <MenuItem value={option}>{option}</MenuItem>)}
                      </Select>
                    </Grid>}
                    {question.type === 'multi' && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <FormGroup>
                        {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option => <FormControlLabel control={<Checkbox
                          checked={((mainObject?.extra_data?.answers || [])[i] || []).indexOf(option) > -1}
                          value={option}
                          onChange={(e) => {
                            let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                            if (typeof(newAnswers[i]) === 'undefined') newAnswers[i] = [];
                            if (e.target.checked) {
                              newAnswers[i] = [
                                ...newAnswers[i],
                                option
                              ];
                            } else {
                              // @ts-ignore
                              newAnswers[i] = newAnswers[i].filter(fff => fff !== option);
                            }

                            setMainObject({
                              ...mainObject,
                              extra_data: {
                                ...(mainObject.extra_data || {}),
                                answers: newAnswers
                              }
                            });
                          }}
                        />} label={option} />)}
                      </FormGroup>
                    </Grid>}
                    {question.type === 'nps' && <Grid item xs={12} sx={{
                      paddingLeft: "32px !important"
                    }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}>
                        {Array((question?.max || 10) + 1 - (question?.min || 1)).fill(0).map((_, idx) => (question?.min || 1) + idx).map(v => <>
                          <Box sx={{
                            border: '1px solid transparent',
                            borderColor: theme.palette.primary.main,
                            padding: 2,
                            fontSize: '1.1rem',
                            display: 'inline-block',
                            cursor: 'pointer',
                            margin: '-0.5px',
                            // borderRadius: 4,
                            width: `${100 / ((question?.max || 10) + 1 - (question?.min || 1))}%`,
                            textAlign: 'center',
                            "&:hover": {
                              // backgroundColor: 'lightgrey',
                              backgroundColor: theme.palette.primary.light,
                              borderColor: theme.palette.primary.dark
                            },
                            ...((mainObject?.extra_data?.answers || [])[i] === v ? {
                              // backgroundColor: 'lightgrey',
                              backgroundColor: theme.palette.primary.light,
                              borderColor: theme.palette.primary.dark
                            } : {})
                          }} onClick={() => {
                            let newAnswers:any[] = [...(mainObject.extra_data?.answers || [])];
                            newAnswers[i] = v;

                            setMainObject({
                              ...mainObject,
                              extra_data: {
                                ...(mainObject.extra_data || {}),
                                answers: newAnswers
                              }
                            });
                          }}>{v}</Box>
                        </>)}
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}>
                        <Box sx={{
                          display: 'inline-block',
                          margin: '-0.5px',
                          width: '50%',
                          textAlign: 'left',
                          marginTop: 2,
                          fontStyle: 'italic'
                        }}><Typography sx={{
                          fontSize: '0.9rem',
                          color: 'grey',
                        }}>{t("question.nps.unlikely_term")}</Typography></Box>
                        <Box sx={{
                          fontSize: '1.1rem',
                          display: 'inline-block',
                          margin: '-0.5px',
                          width: '50%',
                          textAlign: 'right',
                          color: 'grey',
                          marginTop: 2,
                          fontStyle: 'italic'
                        }}><Typography sx={{
                          fontSize: '0.9rem',
                          color: 'grey',
                        }}>{t("question.nps.likely_term")}</Typography></Box>
                      </Box>
                    </Grid>}
                  </>}
                </>}
                {!!mainObject?.is_locked && <>
                  {question.type === 'existing' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    <Typography sx={{marginTop: 1, paddingLeft: "32px !important", borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(mainObject?.extra_data?.answers || [])[i] || ''}</Typography>  
                  </Grid>}
                  {question.type === 'open' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    <Typography sx={{marginTop: 1, paddingLeft: "32px !important", borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(mainObject?.extra_data?.answers || [])[i] || ''}</Typography>  
                  </Grid>}
                  {question.type === 'open_long' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    <Typography sx={{marginTop: 1, paddingLeft: "32px !important", borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(mainObject?.extra_data?.answers || [])[i] || ''}</Typography>  
                  </Grid>}
                  {question.type === 'single_radio' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option => <Typography sx={{opacity: ((mainObject?.extra_data?.answers || [])[i] === option ? '1' : '0.3')}}>{option}</Typography>)}
                  </Grid>}
                  {question.type === 'single_select' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    <Typography sx={{marginTop: 1, paddingLeft: "32px !important", borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(mainObject?.extra_data?.answers || [])[i] || ''}</Typography>  
                  </Grid>}
                  {question.type === 'multi' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    {((question.options || {})[i18n.resolvedLanguage || 'nl'] || (question.options || {})['nl']).split(/\r?\n/).map(option => <Typography sx={{opacity: (((mainObject?.extra_data?.answers || [])[i] || []).indexOf(option) > -1 ? '1' : '0.3')}}>{option}</Typography>)}
                  </Grid>}
                  {question.type === 'nps' && <Grid item xs={12} sx={{
                    paddingLeft: "32px !important"
                  }}>
                    <Typography sx={{marginTop: 1, paddingLeft: "32px !important", borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{(mainObject?.extra_data?.answers || [])[i] || ''}/{question.max || 10}</Typography>  
                  </Grid>}
                </>}
              </>}
            </>)}
          </>}






          <Grid item xs={12} sx={{
            alignContent: 'baseline',
            textAlign: 'center',
            position: 'sticky',
            bottom: '-24px',
            backgroundColor: 'white',
            zIndex: 1201
          }}>
            <Divider sx={{marginTop: 1, marginBottom: 0}} />

            {(mainObject?.extra_data?.questionnaire?.questions || []).filter((fff, i) => fff.required && !(mainObject?.extra_data?.answers || [])[i]).length > 0 && <Typography sx={{fontStyle: 'italic', marginTop: 2, marginBottom: 2, color: theme.palette.secondary.main}}>{t("client.questionnaires.description_button_disabled_req_questions")}</Typography>}

            {!!mutationSave.isPending && <CircularProgress />}
            {!mutationSave.isPending && !mainObject?.is_locked && <Button
              label={t("shared.buttons.save")}
              id='save'
              disabled={(mainObject?.extra_data?.questionnaire?.questions || []).filter((fff, i) => fff.required && !(mainObject?.extra_data?.answers || [])[i]).length > 0}
              contained
              onClick={(v) => {
                mutationSave.mutate({
                  formData: {
                    object: 'questionnaire_result',
                    id: objectId,
                    ob: {
                      id: objectId,
                      extra_data: {
                        ...mainObject?.extra_data
                      },
                      completed_at: DateTime.now().toFormat("y-LL-dd t")
                    }
                  }
                });
              }}
              sx={{marginBottom: 1, marginTop: 1}}
            />}
            {!mutationSave.isPending && <Button
              label={t("shared.buttons.cancel")}
              id='cancel'
              text
              // contained
              onClick={(v) => {
                navigate("/");
              }}
              sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
            />}
          </Grid>
        </>}

        {!!savedOpen && <>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h3">{t("questionnaire.message.success")}</Typography>
            <Button
              label={t("goto_root")}
              id='goto'
              contained
              onClick={(v) => {
                navigate(!!login ? "/questionnaires" : "/");
              }}
              sx={{marginTop: 1}}
            />
          </Grid>
        </>}
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={() => {}} />
  </Page>);
}
