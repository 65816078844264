import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Alert,
  Button as Btn,
  Chip,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Dt,
  IconButton,
  Page,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { MergeRequest } from '../../../models/MergeRequest';
import { Base } from '../../../components/v2/dialogs/Base';

// const { DateTime } = require("luxon");

type Props = {}

export const AdminMergeRequests: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<number[]>([0, 1]);

  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [activeRequest, setActiveRequest] = useState<MergeRequest>();
  const [adminComment, setAdminComment] = useState<string>("");

  const fields = ['id', 'full_name', 'current_sign_in_at', 'consent_status', 'appointments|before_now|count', 'appointments|after_now|count', 'email', 'street', 'number', 'zip', 'city', 'custom_api_get_intake_progress', 'workshops|before_now|count', 'workshops|after_now|count']

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "merge_requests", {page: page, order: order, search: search, filterStatus: filterStatus}],
    queryFn: () =>
      fetch_all_with_count<MergeRequest>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'merge_request',
          fields: ['id', 'original', 'copy', 'comment', 'practice', 'coach', 'comment_admin', 'created_at', 'current_status'],
          sub_fields: {
            'practice': ['id', 'name'],
            'coach': ['id', 'full_name'],
            'original': fields,
            'copy': fields
          },
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              admin_only: 1,
              current_status: filterStatus.join(",")
            }
          },
          skip_api_restrictions: 1
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<MergeRequest>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      setOpen(false);
      setActiveRequest(undefined);
      objectsRefetch();
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'practice_name', headerName: t('admin.models.merge_requests.practice', "Practice"), width: 250, sortable: false },
    { field: 'coach_name', headerName: t('admin.models.merge_requests.coach', "Coach"), width: 250, sortable: false },
    { field: 'original_name', headerName: t('admin.models.merge_requests.original', "Original"), width: 250, sortable: false },
    { field: 'copy_name', headerName: t('admin.models.merge_requests.copy', "Copy"), width: 250, sortable: false },
    { field: 'created_at', headerName: t("admin.models.merge_requests.created_at"), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'current_status', headerName: t("admin.models.merge_requests.current_status"), width: 100, renderCell: params => <>
      {params.row.current_status === 0 && <Chip label="open" color="warning" />}
    </> },
  ];

  return <Page title={t("admin.menu.merge_requests")}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
      multiTags={[
        {id: 0, name: 'open'},
        {id: 1, name: 'busy'},
        {id: 2, name: 'accepted'},
        {id: 3, name: 'closed'}
      ]}
      activeTags={filterStatus}
      setActiveTags={setFilterStatus}
    />
    <DataGrid
      action_count={1}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="Edit" color="default" onClick={() => {
            setActiveRequest(params.row);
            setOpen(true);
          }}>
            {params.row.current_status < 2 && <EditIcon />}
            {params.row.current_status > 1 && <VisibilityIcon />}
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u,
        practice_name: u.practice?.name,
        coach_name: u.coach?.full_name,
        original_name: u.original?.full_name,
        copy_name: u.copy?.full_name
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Base
      name="merge_request"
      fullWidth
      maxWidth="xl"
      content={<>
        {!!activeRequest && <Grid container sx={{
          marginTop: -1
        }} spacing={1}>
          {(activeRequest.current_status || 0) > 1 && <Grid item xs={12}>
            <Alert severity='error'>
              <Typography>Deze dubbel is reeds behandeld!</Typography>
            </Alert>
          </Grid>}
          <Grid item xs={12}>
            <Alert severity='info'>
              <Typography sx={{fontWeight: 'bold'}}>Gerapporteerd in:</Typography>
              <Typography sx={{paddingLeft: 2}}>{activeRequest.practice?.name}</Typography>
              <Typography sx={{fontWeight: 'bold'}}>Gerapporteerd door:</Typography>
              <Typography sx={{paddingLeft: 2}}>{activeRequest.coach?.full_name}</Typography>
            </Alert>
          </Grid>
          {!!activeRequest.comment && <Grid item xs={12}>
            <Alert severity='info'>
              <Typography sx={{fontWeight: 'bold'}}>Coach:</Typography>
              <Typography sx={{paddingLeft: 2}}>{activeRequest.comment}</Typography>
            </Alert>
          </Grid>}
          {!!activeRequest.comment_admin && <Grid item xs={12}>
            <Alert severity='success'>
              <Typography sx={{fontWeight: 'bold'}}>Admin:</Typography>
              <Typography sx={{paddingLeft: 2}}>{activeRequest.comment_admin}</Typography>
            </Alert>
          </Grid>}

          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5}}>
            <Typography sx={{textDecoration: 'underline'}}>ORIGINAL</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important"}}>
            <Typography sx={{textDecoration: 'underline'}}>COPY</Typography>
          </Grid>

          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5}}>
            <Typography sx={{fontWeight: 'bold'}}>{activeRequest.original?.full_name}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important"}}>
            <Typography sx={{fontWeight: 'bold'}}>{activeRequest.copy?.full_name}</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.email}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.email}</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.street} {activeRequest.original?.number}</Typography>
            <Typography>{activeRequest.original?.zip} {activeRequest.original?.city}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.street} {activeRequest.copy?.number}</Typography>
            <Typography>{activeRequest.copy?.zip} {activeRequest.copy?.city}</Typography>
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">Intake profiel</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.custom_api_get_intake_progress || 0}%</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.custom_api_get_intake_progress || 0}%</Typography>
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">Meest recente login</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            {!!activeRequest.original?.current_sign_in_at && <Typography><Dt d={activeRequest.original?.current_sign_in_at} f="dd/LL/y t" /></Typography>}
            {!activeRequest.original?.current_sign_in_at && <Typography>-</Typography>}
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            {!!activeRequest.copy?.current_sign_in_at && <Typography><Dt d={activeRequest.copy?.current_sign_in_at} f="dd/LL/y t" /></Typography>}
            {!activeRequest.copy?.current_sign_in_at && <Typography>-</Typography>}
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">Komende afspraken</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.appointments_after_now_count || 0}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.appointments_after_now_count || 0}</Typography>
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">Verleden afspraken</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.appointments_before_now_count || 0}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.appointments_before_now_count || 0}</Typography>
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">Komende workshops</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.workshops_after_now_count || 0}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.workshops_after_now_count || 0}</Typography>
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">Verleden workshops</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.workshops_before_now_count || 0}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.workshops_before_now_count || 0}</Typography>
          </Grid>

          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="caption">NutriID</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
            <Typography>{activeRequest.original?.consent_status?.nutri_id}</Typography>
          </Grid>
          <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
            <Typography>{activeRequest.copy?.consent_status?.nutri_id}</Typography>
          </Grid>

          {(activeRequest.current_status || 0) < 2 && <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="comment_admin"
                caption="Extra comment"
                multiline
                value={adminComment}
                onChange={(e) => {
                  setAdminComment(e);
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{textAlign: 'right', paddingRight: 0.5, marginTop: -1}}>
              <Button
                label="Kies origineel & handel af"
                id='pick_orig'
                contained
                onClick={(v) => {
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'merge_request',
                      class_action: 'custom_api_update_status',
                      id: activeRequest.id,
                      pick: 'orig',
                      comment: adminComment
                    }
                  });
                }}
                sx={{marginTop: 1}}
              />
            </Grid>
            <Grid item xs={6} sx={{paddingLeft: "12px !important", marginTop: -1}}>
              <Button
                label="Kies kopie & handel af"
                id='pick_copy'
                contained
                onClick={(v) => {
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'merge_request',
                      class_action: 'custom_api_update_status',
                      id: activeRequest.id,
                      pick: 'copy',
                      comment: adminComment
                    }
                  });
                }}
                sx={{marginTop: 1}}
              />
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                label="Kies geen van beiden & sluit af"
                id='pick_none'
                text
                onClick={(v) => {
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'merge_request',
                      class_action: 'custom_api_update_status',
                      id: activeRequest.id,
                      pick: 'discard',
                      comment: adminComment
                    }
                  });
                }}
                sx={{marginTop: 1}}
              />
            </Grid>
          </>}

        </Grid>}
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("shared.nutri_mail.close", "Sluiten")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </Page>;
}
