import React, { FunctionComponent, ReactElement } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';

import PanToolAltIcon from '@mui/icons-material/PanToolAlt';

import { AppointmentType } from '../../../models/AppointmentType';

type Props = {
  type: AppointmentType;
  onClick?: () => void;
  divider?: boolean;
  extended?: boolean;
};

export const ListItemAppointmentType: FunctionComponent<Props> = ({divider, extended, type, onClick}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<ListItem
    divider={!!divider}
    sx={!!onClick ? {
      cursor: 'pointer'
    } : {}}
    onClick={(e) => {
      e.preventDefault();
      if (!!onClick) onClick();
    }}
  >
    <ListItemAvatar>
      <Avatar sx={{backgroundColor: type.colour}} alt={type.name} src="nonexisting.link"></Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={type.name}
      secondary={!!extended ? `${t("client.appointment.type_duration", "Duurtijd")}: ${type.public_duration_nice || type.duration_nice} | ${t("client.appointment.type_cost", "Kost")}: ${type.cost} €` : [type.public_duration_nice || type.duration_nice, [type.cost, "€"].join(" ")].join(" | ")}
    />
    {!!onClick && <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="settings" onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick();
      }}>
        <PanToolAltIcon />
      </IconButton>
    </ListItemSecondaryAction>}
  </ListItem>);
}