import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all_with_count, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Fab,
  IconButton,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { CoachAppointmentTypeEdit } from '../../../components/v2/dialogs/CoachAppointmentTypeEdit';
import { Premium } from '../../../components/v2/dialogs/Premium';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { AppointmentType } from '../../../models/AppointmentType';
import { Practice } from '../../../models/Practice';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  premiumLocked?: boolean;
}

export const CoachGeneralSettingsAppointments: FunctionComponent<Props> = ({premiumLocked}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("enabled DESC, sort_order ASC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const [extendedPractice, setExtendedPractice] = useState<Practice>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [premiumOpen, setPremiumOpen] = useState<boolean>(false);

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [activeType, setActiveType] = useState<AppointmentType>();
  const [sortOrders, setSortOrders] = useState<{[i:number]: number}>({});

  const fields = [
    'id', 'group_move', 'group_eat', 'name', 'extra_data', 'support_email', 'language_id', 'full_intro', 'full_my_practice', 'full_description',
    'is_allow_guest_accounts', 'cancel_late_time', 'is_flexy_flex', 'flexy_flex_time'
  ];

  const {
    data: extendedPracticeTemp,
    isLoading: extendedPracticeLoading,
    isError: extendedPracticeError,
    isSuccess: extendedPracticeSuccess,
    refetch: aextendedPracticeRefetch,
  } = useQuery({
    queryKey: ["general_settings", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: fields,
          id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!currentPractice?.id,
  });
  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name', 'sort_order'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: 1,
              is_secretary: 0
            }
          }
        },
        login
      ),
    enabled: !!(login?.practice_id || currentPractice?.id)
  });
  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "appointment_types", (login?.practice_id || currentPractice?.id), {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<AppointmentType>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment_type',
          fields: ['id', 'name', 'extra_data', 'enabled', 'sort_order', 'coaches', 'is_meeting', 'is_hidden', 'cost', 'duration', 'public_duration_nice', 'public_duration', 'duration_nice', 'questionnaires'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<AppointmentType>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });
  const mutationSaveAppType = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<AppointmentType>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      setEditOpen(false);
      setSavedOpen(true);
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedPracticeRefetch();
      setSavedOpen(true);
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t('coach.models.appointment_types.name'), width: 250, sortable: true },
    { field: 'sort_order', headerName: t('coach.models.appointment_types.sort_order'), width: 100, sortable: true },
    { field: 'duration_nice', headerName: t('coach.models.appointment_types.length'), width: 100, sortable: false },
    { field: 'public_duration_nice', headerName: t('coach.models.appointment_types.public_length'), width: 100, sortable: false },
    { field: 'cost', headerName: t('coach.models.appointment_types.cost'), width: 100, sortable: false },
    { field: 'mollie', headerName: t("coach.models.appointment_types.mollie_enabled", "Betaling mogelijk"), width: 100, sortable: false, renderCell: params => <>
      {!!params.row.extra_data?.mollie_settings?.enabled && <CheckIcon />}
    </> },
  ];

  const saveUser = () => {
    if (!!extendedPractice && !premiumLocked) {
      mutationSave.mutate({
        formData: {
          object: 'practice',
          fields: fields,
          id: extendedPractice.id,
          ob: {
            id: extendedPractice.id,
            extra_data: extendedPractice.extra_data,
            is_allow_guest_accounts: extendedPractice.is_allow_guest_accounts,
            cancel_late_time: extendedPractice.cancel_late_time,
            is_flexy_flex: extendedPractice.is_flexy_flex,
            flexy_flex_time: extendedPractice.flexy_flex_time
          },
          sort_orders: sortOrders
        }
      });
    }
  }

  useEffect(() => {
    if (!!extendedPracticeTemp) {
      setExtendedPractice(extendedPracticeTemp);
    }
  }, [extendedPracticeTemp]);
  useEffect(() => {
    if (!!coaches) {
      let so = {};
      (coaches || []).forEach(coach => {
        // @ts-ignore
        so[coach.id || 0] = (coach.sort_order || 100);
      });
      setSortOrders(so);
    }
  }, [coachesSuccess]);

  return <Grid container spacing={2}>
    {!!premiumLocked && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1201,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      textAlign: 'center',
      cursor: 'pointer'
    }} onClick={() => {
      setPremiumOpen(true);
    }}>
      <StarBorderPurple500 sx={{marginTop: '20%', color: theme.palette.primary.main}} />
    </Box>}
    
    {!!extendedPracticeLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedPractice && <>
      <Grid item xs={12} md={login?.is_super ? 6 : 4} container spacing={2} sx={{alignContent: 'baseline'}}>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.appointments.guest_settings", "Publieke agenda")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Switcher
                  id="is_allow_guest_accounts"
                  caption={t("coach.models.practices.is_allow_guest_accounts")}
                  value={!!extendedPractice?.is_allow_guest_accounts}
                  onChange={(c) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      is_allow_guest_accounts: c
                    });
                  }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {!!extendedPractice.extra_data?.is_sms_enabled && <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.appointments.reminder_sms_settings", "SMS herinneringsinstellingen")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="reminder_stage1"
                  caption={t("coach.models.practices.reminder_sms", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.reminder_sms", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.extra_data?.reminder_sms?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        reminder_sms: !!e ? parseInt(e, 10) : undefined
                      }
                    });
                  }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>}

      </Grid>

      <Grid container item xs={12} md={login?.is_super ? 6 : 4} sx={{alignContent: 'baseline'}}>
        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.appointments.reminder_settings", "Herinneringsinstellingen")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="reminder_stage1"
                  caption={t("coach.models.practices.reminder_stage1", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.reminder_stage1", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.extra_data?.reminder_stage1?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        reminder_stage1: !!e ? parseInt(e, 10) : undefined
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Switcher
                  id="another_reminder1"
                  caption={t("coach.models.practices.another_reminder")}
                  value={(extendedPractice.extra_data?.reminder_stage2 || 0) > 0}
                  onChange={(c) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        reminder_stage2: !!c ? (extendedPractice.extra_data?.reminder_stage1 || 1) * 2 : 0,
                        ...(!c ? {
                          reminder_stage3: 0
                        } : {})
                      }
                    });
                  }}
                />
              </Grid>
              {(extendedPractice.extra_data?.reminder_stage2 || 0) > 0 && <Grid item xs={12}>
                <TextInput
                  id="reminder_stage2"
                  caption={t("coach.models.practices.reminder_stage2", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.reminder_stage2", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.extra_data?.reminder_stage2?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        reminder_stage2: !!e ? parseInt(e, 10) : undefined
                      }
                    });
                  }}
                />
              </Grid>}
              {(extendedPractice.extra_data?.reminder_stage2 || 0) > 0 && <Grid item xs={12}>
                <Switcher
                  id="another_reminder1"
                  caption={t("coach.models.practices.another_reminder")}
                  value={(extendedPractice.extra_data?.reminder_stage3 || 0) > 0}
                  onChange={(c) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        reminder_stage3: !!c ? (extendedPractice.extra_data?.reminder_stage2 || 1) * 2 : 0
                      }
                    });
                  }}
                />
              </Grid>}
              {(extendedPractice.extra_data?.reminder_stage3 || 0) > 0 && <Grid item xs={12}>
                <TextInput
                  id="reminder_stage2"
                  caption={t("coach.models.practices.reminder_stage3", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.reminder_stage3", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.extra_data?.reminder_stage3?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        reminder_stage3: !!e ? parseInt(e, 10) : undefined
                      }
                    });
                  }}
                />
              </Grid>}
              
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>

      <Grid container item xs={12} md={login?.is_super ? 6 : 4} sx={{alignContent: 'baseline'}}>
        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.appointments.settings", "Afspraakinstellingen")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="cancel_late_time"
                  caption={t("coach.models.practices.cancel_late_time", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.cancel_late_time", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.cancel_late_time?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      cancel_late_time: !!e ? parseInt(e, 10) : undefined
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Switcher
                  id="is_flexy_flex"
                  caption={t("coach.models.practices.is_flexy_flex", "Afspraken moeten zoveel mogelijk plakken aan elkaar")}
                  value={!extendedPractice.is_flexy_flex}
                  onChange={(c) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      is_flexy_flex: !c
                    });
                  }}
                />
              </Grid>
              {!!extendedPractice.is_flexy_flex && <Grid item xs={12}>
                <TextInput
                  id="flexy_flex_time"
                  caption={t("coach.models.practices.flexy_flex_time", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.flexy_flex_time", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.flexy_flex_time?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      flexy_flex_time: !!e ? parseInt(e, 10) : undefined
                    });
                  }}
                />
              </Grid>}
              <Grid item xs={12}>
                <TextInput
                  id="yellow_duration"
                  caption={t("coach.models.practices.yellow_duration", "Hoeveel dagen op voorhand wordt een herinnering gestuurd?")}
                  placeholder={t("coach.models.practices.placeholders.yellow_duration", "Herinnering op voorhand, in dagen")}
                  value={extendedPractice.extra_data?.yellow_duration?.toString() || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      extra_data: {
                        ...(extendedPractice.extra_data || {}),
                        yellow_duration: !!e ? parseInt(e, 10) : undefined
                      }
                    });
                  }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {!!login?.is_super && (coaches || []).length > 0 && <Grid container item xs={12} md={6} sx={{alignContent: 'baseline'}}>
        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.appointments.coach_order_settings")}</Typography>
                <Divider />
              </Grid>
              {(coaches || []).map(coach => <Grid item xs={12}>
                <TextInput
                  id={`sort_order_${coach.id}`}
                  caption={coach.full_name}
                  value={!!sortOrders[coach.id || 0] ? sortOrders[coach.id || 0].toString() : '100'}
                  onChange={(e) => {
                    console.log(sortOrders, e);
                    setSortOrders({
                      ...sortOrders,
                      [coach.id || 0]: !!e ? parseInt(e, 10) : 100
                    });
                  }}
                />
              </Grid>)}
              
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>}

      <Grid item xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <DataGrid
            action_count={2}
            hideCount
            actions={(params) => {
              return (<>
                <IconButton title="Edit" color="default" onClick={() => {
                  setActiveType({
                    ...params.row,
                    extra_data: {
                      ...(params.row.extra_data || {}),
                      mollie_settings: {
                        ...(params.row?.extra_data?.mollie_settings || {}),
                        enabled: !!currentPractice?.settings?.mollie_api_key && !!(params.row?.extra_data?.mollie_settings || {}).enabled
                      }
                    }
                  });
                  setEditOpen(true);
                }}>
                  <EditIcon />
                </IconButton>
                <IconButton title="Remove" destroyBtn color="default" onClick={() => {
                  if (!premiumLocked) mutationDestroy.mutate({
                    formData: {
                      object: 'appointment_type',
                      id: params.row.id,
                      ob: {
                        id: params.row.id,
                      }
                    }
                  });
                }}>
                  <DeleteIcon />
                </IconButton>
              </>);
            }}
            objectCount={count}
            getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
            objects={(objects || []).map(u => ({
              ...u
            }))}
            columns={columns}
            loader={objectsLoading}
            onSortModelChange={(a, b) => {
              let a_0_field:string = a[0].field;
              if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
              setOrder([a_0_field, a[0].sort].join(' '));
            }}
            page={page}
            setPage={(p) => {setPage(p);}}
          />
        </Paper>
      </Grid>
    </>}

    {!!activeType && <CoachAppointmentTypeEdit
      onSave={() => {
        mutationSaveAppType.mutate({
          formData: {
            object: "appointment_type",
            id: activeType?.id,
            ob: {
              id: activeType.id,
              practice_id: (login?.practice_id || currentPractice?.id),
              name: activeType.name,
              extra_data: {
                ...activeType.extra_data,
                length: activeType.duration,
                public_length: activeType.public_duration
              },
              is_hidden: activeType.is_hidden,
              coaches: activeType.coaches,
              questionnaires: activeType.questionnaires,
              is_meeting: activeType.is_meeting,
              sort_order: activeType.sort_order,
              enabled: activeType.enabled
            },
            handler_id: login?.id
          }
        });
      }}
      open={editOpen}
      setOpen={(b) => {
        setActiveType(undefined);
        setEditOpen(b);
      }}
      appointmentType={activeType}
      setAppointmentType={setActiveType}
      isLoading={mutationSave.isPending}
    />}

    <Premium
      open={premiumOpen}
      setOpen={(b) => {
        setPremiumOpen(b);
      }}
      mode="nutri_appointment"
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Fab
      onClick={() => {
        setActiveType({
          name: '',
          duration: '30',
          duration_nice: '0:30',
          extra_data: {
            cost: undefined,
            mollie_settings: {
              enabled: !!currentPractice?.settings?.mollie_api_key
            }
          },
          sort_order: (((objects || []).map(mm => mm.sort_order || 0).sort().reverse()[0] || 0) + 1)
        });
        setEditOpen(true);
      }}
    />
  </Grid>;
}

